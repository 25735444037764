import React, { createContext, useContext, useEffect } from "react"

import PropTypes from "prop-types"
import { WebsocketContext } from "../socketProvider"

//default value passed to create context :- ready, value, send
export const UserSocketContext = createContext(false, null, () => {})

// The component tree than any consumers.
const UserSocketProvider = ({ children }) => {
  const [ready, val, send] = useContext(WebsocketContext)

  useEffect(() => {
    if (val?.event_type === "user_password_reset" && val?.data?.email === localStorage.getItem("username")) {
      localStorage.clear()
      localStorage.setItem("username", val?.data?.email)
      window.location.href = "/app/login"
    }
  }, [val])

  return <UserSocketContext.Provider value={[ready, val, send]}>{children}</UserSocketContext.Provider>
}

UserSocketProvider.propTypes = {
  // Children node need to pass to provider component
  children: PropTypes.node.isRequired,
}

export default UserSocketProvider
