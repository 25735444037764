// This file contains the action types and action creators for the AssessmentMuralReducer.

// Action Types
export const SET_SHOW_MURAL_CANVAS = "SET_SHOW_MURAL_CANVAS"
export const SET_DRAGGABLE_HASH = "SET_DRAGGABLE_HASH"
export const SET_SCREEN_SIZE_BUTTONS = "SET_SCREEN_SIZE_BUTTONS"
export const SET_CANVAS_LINK = "SET_CANVAS_LINK"
export const SET_WINDOW_STATE = "SET_WINDOW_STATE"
export const SET_SHOW_MURAL_AUTH_WARNING = "SET_SHOW_MURAL_AUTH_WARNING"
export const SET_SHOW_LOADER = "SET_SHOW_LOADER"
export const SET_MURAL_ASSESSMENT_ID = "SET_MURAL_ASSESSMENT_ID"
export const SET_PARTICIPANT_ID = "SET_PARTICIPANT_ID"
export const SET_MURAL_ASSESSMENT = "SET_MURAL_ASSESSMENT"
export const SET_MURAL_CHAT_WINDOW_EXPAND = "SET_MURAL_CHAT_WINDOW_EXPAND"
export const SET_NEW_MESSAGE_FLAG = "SET_NEW_MESSAGE_FLAG"
export const SET_PARTICIPANT_DETAILS_MODAL = "SET_PARTICIPANT_DETAILS_MODAL"
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"
// Action Creators
export const setShowMuralCanvas = (show) => ({
  type: SET_SHOW_MURAL_CANVAS,
  payload: show,
})

export const setMuralAssessment = (muralAssessment) => ({
  type: SET_MURAL_ASSESSMENT,
  payload: muralAssessment,
})

export const setDraggableHash = (draggableHash) => ({
  type: SET_DRAGGABLE_HASH,
  payload: draggableHash,
})

export const setScreenSizeButtons = (screenSizeButtons) => ({
  type: SET_SCREEN_SIZE_BUTTONS,
  payload: screenSizeButtons,
})

export const setCanvasLink = (canvasLink) => ({
  type: SET_CANVAS_LINK,
  payload: canvasLink,
})

export const setWindowState = (windowState) => ({
  type: SET_WINDOW_STATE,
  payload: windowState,
})

export const setShowMuralAuthWarning = (show) => ({
  type: SET_SHOW_MURAL_AUTH_WARNING,
  payload: show,
})

export const setShowLoader = (show) => ({
  type: SET_SHOW_LOADER,
  payload: show,
})

export const setPartipantId = (id) => ({
  type: SET_PARTICIPANT_ID,
  payload: id,
})

export const setMuralAssessmentId = (id) => ({
  type: SET_MURAL_ASSESSMENT_ID,
  payload: id,
})
export const setMuralChatWindowExpanded = (show) => ({
  type: SET_MURAL_CHAT_WINDOW_EXPAND,
  payload: show,
})
export const setNewMessageFlag = (flags) => ({
  type: SET_NEW_MESSAGE_FLAG,
  payload: flags,
})
export const setShowParticipantDetailsModal = (show) => ({
  type: SET_PARTICIPANT_DETAILS_MODAL,
  payload: show,
})
export const setActiveFilter = (show) => ({
  type: SET_ACTIVE_FILTER,
  payload: show,
})
