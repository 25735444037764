import React, { createContext, useEffect, useReducer, useContext } from "react"
import QuestionReducer from "./QuestionReducer"
import { TrainingSocketContext } from "../socket/training_socket/trainingSocketProvider"
import * as wsFunctions from "../../components/question-answer/ws-events"

const initalState = {
  show_question_modal: null,
  question_data: null,
  question_next_data: null,
  reply_data: null,
  reply_next_data: null,
  draft_reply: null,
  all_topics: null,
  all_sessions: null,
  reply_resources: null,
  reply_next_resources: null,
}

const QuestionStore = ({ children }) => {
  const [state, dispatch] = useReducer(QuestionReducer, initalState)

  const [ready, val, send] = useContext(TrainingSocketContext)

  useEffect(() => {
    if (val && val.event_type) {
      try {
        if (val.event_type === "question_created") {
          wsFunctions.question_created(val, state, dispatch)
        } else if (val.event_type === "question_deleted") {
          wsFunctions.question_deleted(val, state, dispatch)
        } else if (val.event_type === "question_vote_changed") {
          wsFunctions.question_vote_changed(val, state, dispatch)
        } else if (val.event_type === "question_reply_created") {
          wsFunctions.question_reply_created(val, state, dispatch)
        } else if (val.event_type === "question_reply_deleted") {
          wsFunctions.question_reply_deleted(val, state, dispatch)
        } else if (val.event_type === "question_reply_changed") {
          wsFunctions.question_reply_changed(val, state, dispatch)
        } else if (val.event_type === "reply_vote_changed") {
          wsFunctions.reply_vote_changed(val, state, dispatch)
        } else if (val.event_type === "question_topic_updated") {
          wsFunctions.question_topic_updated(val, state, dispatch)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [val])

  return <QuestionContext.Provider value={[state, dispatch]}>{children}</QuestionContext.Provider>
}

export const QuestionContext = createContext()
export default QuestionStore
