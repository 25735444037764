import React, { createContext, useReducer } from "react"
import SidebarReducer from "./classroomSidebarReducer"

const initalState = {
  currentNavTab: "",
  currentSelectedResourcesType: "",
  openUploadModal: false,
  refetchResources: false,
  allResources: {},
  draggedItem: null,
  showPollModal: false,
  showPopquizModal: false,
  dataToCreateResource: null,
  disabledResources: [],
  companyDetails: {},
}

const ClassroomSidebarStore = ({ children }) => {
  const [state, dispatch] = useReducer(SidebarReducer, initalState)

  return <ClassroomSidebarContext.Provider value={[state, dispatch]}>{children}</ClassroomSidebarContext.Provider>
}

export const ClassroomSidebarContext = createContext()
export default ClassroomSidebarStore
