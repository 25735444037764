import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import React, { useState } from "react"
import Style from "./style.module.scss"
import ProfileUpdateProgress from "../../ProfileProgressBar"
import PropTypes from "prop-types"

const CustomDropdown = ({ options, defaultOption, onSelect, logoFlag }) => {
  const updatedOptions = options.filter((option) => {
    if (option !== null) {
      return option
    }
  })

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const handleSelectOption = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
    onSelect(option)
  }
  const handleDropdown = () => {
    if (options.length > 1) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(false)
    }
  }
  return (
    <div className={`w-97 position-relative mb-41 ml-1 fs-14px`}>
      <div
        className={`${Style.custom_dropdown_toggle} d-flex flex-row justify-content-between align-items-center p-10px bg-303C54 br-6px pointer w-100 `}
        onClick={handleDropdown}
      >
        {logoFlag && (
          <ProfileUpdateProgress
            percentage={selectedOption?.progress_percentage}
            image={selectedOption?.logo}
            footerImgFlag={false}
            mainDivStyle={Style.progressBarMainDiv}
            footerImgStyle={{}}
            imgName={`${selectedOption?.label} logo`}
            strokeWidth={4}
          />
        )}
        <span>{selectedOption?.label}</span>

        {options.length > 1 ? (
          isOpen ? (
            <FontAwesomeIcon icon={faChevronDown} rotation={180} style={{ color: "#ffffff" }} size="1x" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} style={{ color: "#ffffff" }} size="1x" />
          )
        ) : (
          ""
        )}
      </div>
      {isOpen && (
        <div className={`position-absolute top-100 left-0 z-index-1 w-100 border border-1 border-secondary bg-303C54`}>
          {updatedOptions?.map((option) => (
            <div
              key={option?.value}
              className={`${Style.custom_dropdown_option}  d-flex align-items-center justify-content-start pointer ${
                option?.value === selectedOption?.value ? "turient-header-text-color bg-586886" : ""
              }`}
              onClick={() => handleSelectOption(option)}
            >
              <div className="d-flex align-items-center justify-content-center position-relative">
                <ProfileUpdateProgress
                  percentage={option?.progress_percentage}
                  image={option?.logo}
                  footerImgFlag={false}
                  strokeWidth={3}
                  mainDivStyle={Style.progressBarMainDiv}
                  imgName={`${option?.label} logo`}
                />
              </div>
              <span className={`text-left px-2 poppins_font fs-14px`}>{option?.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

CustomDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired, //(required): the value of the option.
      label: PropTypes.string.isRequired, //(required): the label of the option.
      logo: PropTypes.string.isRequired, //(required): the logo of the school or entity associated with the option.
      owner_email: PropTypes.string.isRequired, //(required): the email address of the owner of the school or entity associated with the option.
      progress_percentage: PropTypes.number, //(optional): the progress percentage of the school or entity associated with the option.
    })
  ), //an array of objects representing the options available in the dropdown
  defaultOption: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    owner_email: PropTypes.string.isRequired,
    progress_percentage: PropTypes.number,
  }), //an object representing the default option to be displayed. This object has the same properties as the options objects.
  onSelect: PropTypes.func.isRequired, //a callback function that will be called when an option is selected. It receives the selected option as an argument.
  logoFlag: PropTypes.bool, //a boolean indicating whether to display the logo and progress bar of the selected option in the dropdown toggle.
}

export default CustomDropdown
