import React, { useContext, useEffect } from "react"
import { Modal } from "react-bootstrap"

import { NetworkContext } from "../../stores/network/networkProvider"

import Styles from "./techincal-error-module.scss"

const TechnicalNetworkError = (props) => {
  const [isOffline, setIsOffline] = useContext(NetworkContext)

  useEffect(() => {
    if (props.connectionLost) {
      setIsOffline(true)
    }
  }, [props.connectionLost])

  const hideModal = (e) => {
    // On hide functionality
  }

  return (
    <Modal
      show={isOffline}
      onHide={(e) => {
        hideModal(e)
      }}
      centered
      contentClassName="Styles.technical_error_modal_content"
      className={`${Styles.techincal_error_modal} techincal-error-modal`}
    >
      <Modal.Body className={`text-center py-4`}>
        <p className={`mb-0 px-5 text-white`}>{props?.message ? props.message : "Some Network/Technical issue was there, we are trying to reconnect you."}</p>
      </Modal.Body>
    </Modal>
  )
}

export default TechnicalNetworkError
