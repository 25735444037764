import React, { useEffect, useState, useCallback, useRef, useContext } from "react"
import debounce from "lodash/debounce"

import Col from "react-bootstrap/Col"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Dropdown from "react-bootstrap/Dropdown"
import Modal from "react-bootstrap/Modal"
import Spinner from "react-bootstrap/Spinner"
import { getRequest, postRequest, deleteRequest, putRequest } from "../../services/rest_service"
import Image from "../custom/Image"

import DeleteIcon from "../../images/svgs/delete.svg"
import DragHandleIcon from "../../images/svgs/drag-handle-white.svg"
import CheckIcon from "../../images/svgs/check-white.svg"
import ChevronCircleUp from "../../images/svgs/arrow-dropup-white-circle.svg"
import ChevronCircleDown from "../../images/svgs/arrow-dropdown-white-circle.svg"
import User from "../../images/svgs/user.svg"
import Clock from "../../images/svgs/clock.svg"

import NotificationModal from "../../components/notificationModal"
import DeleteModal from "../custom/DeleteModal"

import Styles from "../../modularscss/styles.module.scss"
import StylesA from "../quizzes/quiz.module.scss"
import StylesB from "../courses/course.module.scss"
import StylesC from "../case-study/casestudy.module.scss"

import CheckSquare from "../../images/svgs/check-square.svg"
import Square from "../../images/svgs/square.svg"
import ExclamationTriangleYellow from "../../images/svgs/exclamation-triangle-yellow.svg"
import Close from "../../images/svgs/cancel-white-icon.svg"

import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_DATA } from "../../stores/global_store/GlobalActions"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_REFETCH_RESOURCES, SET_SELECTED_RESOURCE_TYPE } from "../../stores/sidebar_store/SidebarActions"

var FromTrainingOrClassCreation = {}

const ClassRoomPoll = (props, location) => {
  const [quiz, setQuiz] = useState({})
  const [usingDropdown, setUsingDropdown] = useState(false)

  const [questions, setQuestions] = useState({})
  const [questionsNext, setQuestionsNext] = useState(null)
  const [questionsFetched, setQuestionsFetched] = useState(false)

  const [questionOptions, setQuestionOptions] = useState({})
  const [toggleQuestion, setToggleQuestion] = useState(false)

  const [currentQuestionId, setCurrentQuestionId] = useState("")
  const [questionsCount, setQuestionsCount] = useState(false)
  const [deleteResourceData, setDeleteResourceData] = useState({})
  const [questionCreated, setQuestionCreated] = useState(true)
  const [optionCreated, setOptionCreated] = useState(true)
  const [draggedData, setDraggedData] = useState(false)

  const [published_poll_data, setPublishedPollData] = useState({})
  const [published_poll_details, setPublishedPollDetails] = useState({})
  const [published_poll_fetched, setPublishedPollFetched] = useState(false)

  const [savedContent, setSavedContent] = useState(true)
  const [clickedPublish, setClickedPublish] = useState(false)
  const [showPublishedMsg, setShowPublishedMsg] = useState(false)
  const [questionToggle, setQuestionToggle] = useState({})
  const [currentPoll, setCurrentPoll] = useState("")

  const [topicId, setTopicId] = useState(null)
  const [addToResLib, setAddToResLib] = useState(true)
  const [dispAddToResLib, setDispAddToResLib] = useState(false)

  const pollTime = useRef(null)
  const [minLeft, setMinLeft] = useState("00")
  const [secLeft, setSecLeft] = useState("00")

  const [optionsSelectedParticipants, setOptionsSelectedParticipants] = useState({})
  const [fetchedOptionsSelectedParticipants, setFetchedOptionsSelectedParticipants] = useState(false)

  const [showPublishResultsAgainBtn, setShowPublishResultsAgainBtn] = useState(true)
  const [sidebarState, sidebarDispatch] = useContext(SidebarContext)
  const [globalState, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (window && window.addEventListener) {
      window.addEventListener("my-poll-data", handleData)
      return () => {
        window.removeEventListener("my-poll-data", handleData)
      }
    }
  }, [window])

  function handleData(evt) {
    window.FromTrainingOrClassCreation = FromTrainingOrClassCreation = {
      ...evt.detail,
    }
  }
  useEffect(() => {
    setDispAddToResLib(props.showAddResToLib)
    setAddToResLib(true)
  }, [props.showAddResToLib])

  function sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id) {
    if (type === "quiz") {
      handleQuizName(name, value, item_id)
    } else if (type === "question") {
      updateQuestion(name, value, item_id, questionsData, optionsData, index)
    } else {
      updateOption(name, value, item_id, questionsData, optionsData, index, question_id)
    }
  }

  const delayedQuery = useCallback(
    debounce(
      (name, value, item_id, type, questionsData, optionsData, index, question_id) =>
        sendQuery(name, value, item_id, type, questionsData, optionsData, index, question_id),
      1000
    ),
    []
  )

  useEffect(() => {
    setCurrentPoll(props.poll)
    if (props.poll != "" && props.showPollResult) {
      fetchPublishedPollData()
    } else if (props.poll != "" && !props.showPollResult) {
      fetchQuiz(props.resource.version_id)
    }
  }, [props.poll])

  useEffect(() => {
    if (props.create_new_poll && props.showPollResult === false) {
      resetState()
      handleSubmit()
      props.reset_create_poll()
      focusOnData("QuizName")
    }
  }, [props.create_new_poll])

  useEffect(() => {
    if (window && props.topic != null) {
      setTopicId(props.topic)
    }
  }, [props.topic])

  function resetState() {
    setCurrentPoll("")
    setQuiz({})
    setPublishedPollFetched(false)
    setPublishedPollData({})
    setPublishedPollDetails({})
    setQuestionsFetched(false)
    setQuestions({})
  }

  useEffect(() => {
    if (currentPoll && !props.create_new_poll && props.resource) {
      resetState()
      fetchQuiz()
    }
  }, [currentPoll])

  function handleQuestionIsValid(question_id, options_data, questions_data, updateQuestion = 1, filtered_data = -1, value = -1) {
    if (filtered_data < 0) {
      filtered_data = questions_data.findIndex((ques) => ques.id === question_id)
    }
    let isValid = false
    if (value !== -1) {
      isValid = value !== "" && value !== "sample question console" && value !== "0"
    } else {
      let data = options_data["question_" + question_id]
      if (!data || filtered_data < 0) return -1

      let question = questions_data[filtered_data]
      let optionIsNotEmpty = data.filter((option) => option.name !== "" && option.name !== "sample option console")
      if (
        options_data["question_" + question_id].length >= 2 &&
        optionIsNotEmpty.length >= 2 &&
        question.title &&
        question.title !== "" &&
        question.title !== "sample question console"
      ) {
        isValid = true
      }
    }

    if (updateQuestion === 1) {
      let dummy = [...questions_data]
      dummy[filtered_data].is_valid_question = isValid
      setQuestions(dummy)
    }
    return isValid
  }

  async function handleSubmit() {
    let formData = new FormData()
    formData.append("name", "sample quiz console")
    formData.append("data_type", "1")
    formData.append("is_added_to_library", addToResLib)
    let res = await postRequest("/quiz/", formData)
    if (res.success) {
      setCurrentPoll(res.data.id)
      setQuiz(res.data)
      fetchQuizQuestions(false, res.data.id)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  useEffect(() => {
    if (props.trainingWsConnection) {
      connectSessionSocket()
    }
  }, [props.trainingWsConnection])

  function connectSessionSocket() {
    if (props.trainingWsConnection) {
      props.trainingWsConnection.onmessage = function (e) {
        let data = JSON.parse(e.data)
        if (data.value === "echo") return
        let responseData = JSON.parse(data.value)
        if (responseData.action && responseData.action === "fetch_poll" && responseData.source === "individual_poll") {
          fetchTriggeredPollDetail(responseData.resource_id, false)
        } else if (props.setWsMessage) {
          props.setWsMessage(responseData)
        }
      }
    }
  }

  async function fetchPublishedPollData() {
    let res = await getRequest(`/resources-publish-data/?id=${props.poll}`)
    if (res.success) {
      fetchTriggeredPollDetail(res.data.results[0].id, true)
      setPublishedPollData(res.data.results[0])
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      setPublishedPollDetails({})
    }
  }
  async function fetchTriggeredPollDetail(poll_id, set_poll_fetch) {
    let res = await getRequest(`/quiz-report-card/${poll_id}/poll_trainer_results/?training=${props?.training_id}`)
    if (res.success) {
      setPublishedPollDetails(res.data)
      if (set_poll_fetch) {
        setPublishedPollFetched(true)
      }
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      setPublishedPollData({})
    }
  }

  async function fetchQuiz(quiz_id = "") {
    let current_quiz = quiz_id ? quiz_id : props.resource.version_id
    let res = await getRequest(`/quiz/${current_quiz}/quiz_version_data/?resource=${props.resource.id}`)
    if (res.success) {
      setQuiz(res.data)
      focusOnData("QuizName")
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchQuizQuestions(refetch = false, quiz_id = "") {
    if (!refetch) {
      setQuestionsFetched(false)
    }
    let current_quiz = quiz_id ? quiz_id : props.resource.version_id
    let resource_data = props.resource ? props.resource.id : undefined

    let res = await getRequest(`/quiz-question/?quiz=${current_quiz}&resource=${resource_data}`)
    if (res.success) {
      setQuestionsCount(res.data.count)
      setQuestions(res.data.results)
      setQuestionsNext(res.data.next)
      setQuestionsFetched(true)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function fetchNextQuizQuestions(next_url) {
    let res = await getRequest(next_url)
    if (res.success) {
      let all_questions = [...questions]
      all_questions.concat(res.data.results)
      setQuestions(all_questions)
      setQuestionsNext(res.data.next)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  const getErrorMessage = (question) => {
    let isQuestionBlank = question.title === "sample question console" || !question.title?.trim()
    let isOptionBlank = questionOptions[`question_${question.id}`]?.some((option) => option.name === "sample option console" || !option.name?.trim())
    return isQuestionBlank ? "Question cannot be blank" : isOptionBlank ? "Options cannot be blank" : "Above question is invalid"
  }

  async function fetchOptions(id, fetch = false, question_resource_id = "") {
    if (questionOptions["question_" + id] === undefined || fetch) {
      let res = await getRequest(`/question-option/?quiz_question=${id}`)
      if (res.success) {
        let all_options = { ...questionOptions }
        all_options["question_" + id] = res.data.results
        setQuestionOptions(all_options)
        let objDiv = document.getElementById("poll-questions-div")
        objDiv.scrollTop = objDiv.scrollHeight
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      if (currentQuestionId != id) {
        setCurrentQuestionId(id)
      } else {
        setCurrentQuestionId("")
      }
      setToggleQuestion(!toggleQuestion)
    }
  }

  function handleUpdateOption(index, name, value, option_id, question_id) {
    if (name === "name") {
      delayedQuery(name, value, option_id, "option", questions, questionOptions, index, question_id)
    }
  }

  async function updateOption(name, value, option_id, questionsData, optionsData, index = null, question_id = null) {
    if (name === "name") {
      value = value.trim()
      if (value === "") value = "sample option console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (option_id !== undefined && value !== undefined && value !== "") {
      let res = await putRequest("/question-option/" + option_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (optionsData && questionsData && index >= 0 && question_id) {
          setQuestionOptions((prev) => {
            let options_data = { ...prev },
              question_index = "question_" + question_id
            if (!options_data[question_index]) {
              options_data[question_index] = []
            }
            let option = options_data[question_index][index]
            option[name] = value
            if (name === "name") {
              let questions_data = [...questionsData]
              handleQuestionIsValid(question_id, options_data, questions_data)
            }
            return options_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  async function createQuestion(value) {
    let formData = new FormData()
    formData.append("title", "sample question console")
    formData.append("quiz", currentPoll)
    formData.append("data_type", "1")
    formData.append("question_type", value)
    let res = await postRequest("/quiz-question/", formData)
    if (res.success) {
      setQuestions((prev) => {
        let all_questions = [...prev]
        let dummy = { ...res.data }
        dummy.is_valid_question = false
        all_questions = all_questions.concat(dummy)
        return all_questions
      })
      setQuestionCreated(true)
      //fetchOptions(res.data.id)
      handleQuestionToggle(res.data.id)
      let input = document.getElementById(res.data.id + `-question-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function deleteResource(item) {
    if (item) {
      let url = item.quiz_question ? "question-option" : "quiz-question"
      let res = await deleteRequest(`/${url}/${item.id}/`)
      if (res.success) {
        if (url === "quiz-question") {
          let questions_data = [...questions]
          let deleted_question_index = questions_data.findIndex((element) => element.id === item.id)
          questions_data.splice(deleted_question_index, 1)
          setQuestions(questions_data)
          setQuestionsCount(questionsCount - 1)
        } else {
          let questions_data = [...questions]
          let options_data = { ...questionOptions }
          let deleted_option_index = options_data["question_" + item.quiz_question].findIndex((element) => element.id === item.id)
          options_data["question_" + item.quiz_question].splice(deleted_option_index, 1)
          setQuestionOptions(options_data)
          handleQuestionIsValid(item.quiz_question, options_data, questions_data)
        }
        setDeleteResourceData({})
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  async function createOption(question_data) {
    let question_id = question_data.id
    let option_name = `sample option console`
    let formData = new FormData()
    formData.append("quiz_question", question_id)
    formData.append("name", option_name)
    let res = await postRequest("/question-option/", formData)
    if (res.success) {
      setQuestionOptions((prev) => {
        let options_data = { ...prev },
          index = "question_" + question_id
        if (!options_data[index]) {
          options_data[index] = []
        }
        options_data[index] = [...options_data[index], res.data]
        return options_data
      })
      setOptionCreated(true)
      let input = document.getElementById(res.data.id + `-question-option-input-id`)
      input && input.focus()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  async function updateQuestion(name = null, value = null, question_id, questionsData, optionsData, index = null) {
    if (name === "title") {
      value = value.trim()
      if (value === "") value = "sample question console"
    }

    let formData = new FormData()
    formData.append(name, value)
    if (question_id !== undefined && value) {
      let res = await putRequest("/quiz-question/" + question_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
        if (questionsData && index >= 0) {
          setQuestions((prev) => {
            let questions_data = [...prev],
              isValid = res.data.is_valid_question,
              question = questions_data[index]
            question[name] = value
            if (["title"].includes(name)) {
              if (optionsData === null) {
                optionsData = { ...questionOptions }
              }
              if (optionsData["question_" + question_id] !== undefined) {
                value = -1
              }
              isValid = handleQuestionIsValid(question_id, optionsData, questions_data, 0, index, value)
              if (isValid === -1) {
                return questions_data
              }
            }
            questions_data[index]["is_valid_question"] = isValid
            return questions_data
          })
        }
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  function handleUpdateQuestion(index, name = null, value = null, question_id) {
    delayedQuery(name, value, question_id, "question", questions, questionOptions, index)
  }

  function showOptionButtons(data, question_data, question_resource_id, question_index) {
    let question_id = question_data.id
    if (data) {
      return (
        <React.Fragment>
          <div className={`col-12 bg-transparent pt-2`}>
            {data.map((datum, index) => (
              <Col lg="12" md="12" sm="12" xs="12" key={datum.id + `-option-key-` + index + `-${question_id}`} className="px-0 d-flex mb-2 tab-ignore">
                <div className={`${StylesA.option_div} border_on_hover`}>
                  <div className={`${StylesA.drag_handle_div} px-2 py-1`}>
                    <img src={DragHandleIcon} alt={`option-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                  </div>
                  <input
                    type="text"
                    name="name"
                    id={datum.id + `-question-option-input-id`}
                    defaultValue={datum.name === "sample option console" ? `` : datum.name}
                    onChange={(e) => {
                      e.preventDefault()
                      setSavedContent(false)
                      handleUpdateOption(index, e.target.name, e.target.value, datum.id, question_id)
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      if (datum.name !== e.target.value) {
                        updateOption(e.target.name, e.target.value, datum.id, questions, questionOptions, index, question_id)
                      }
                    }}
                    placeholder={"Enter Option text"}
                    className={`${StylesA.question_title_input} form-control px-3`}
                    required
                  />
                </div>
                <div
                  className={`${StylesA.question_delete_div} pointer ml-3`}
                  onClick={(e) => {
                    e.preventDefault()
                    setDraggedData(!draggedData)
                    setDeleteResourceData(datum)
                  }}
                >
                  <img style={{ width: "15px", height: "15px" }} alt={`delete_option_${datum.id.toString()}`} src={DeleteIcon} className="mb-0" />
                </div>
              </Col>
            ))}
            <div className={`py-2 d-flex bg-transparent`}>
              <div className={`d-inline-flex w-100 align-items-center text-center`} style={{}}>
                <button
                  disabled={!optionCreated}
                  onClick={(e) => {
                    e.preventDefault()
                    setOptionCreated(false)
                    createOption(question_data)
                  }}
                  className={`${StylesB.add_button} border_on_hover`}
                >
                  {optionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                  Add Option
                </button>
                <div
                  className={`d-inline-flex align-items-center ml-auto mr-5 pointer white-text`}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    updateQuestion(
                      "question_type",
                      question_data.question_type === "0" ? "1" : "0",
                      question_data.id,
                      questions,
                      questionOptions,
                      question_index
                    )
                  }}
                >
                  {question_data.question_type === "0" ? (
                    <Image src={Square} className="w-18px white-text" />
                  ) : (
                    <Image src={CheckSquare} className="w-18px white-text" />
                  )}
                  <p className={`mb-0 pl-2 fs-14px bold-500`}>Allow users to select multiple options</p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  function handleClose() {
    setDeleteResourceData({})
  }

  async function deletePoll(id) {
    let res = await deleteRequest(`/quizzes/` + id + "/")
    if (res.success) {
      props.closeModal()
      resetState()
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function handleQuestionToggle(question_id, type, fetch_options = true) {
    if (fetch_options) {
      fetchOptions(question_id)
    }
    let toggle_data = { ...questionToggle }
    toggle_data[question_id] = !toggle_data[question_id]
    setQuestionToggle(toggle_data)
  }

  function showQuizQuestions() {
    if (questionsFetched && questions.length > 0) {
      return (
        <div className={`col-12 px-0`}>
          {questions.map((datum, index) => {
            let question_error_class = datum?.is_valid_question
            let toggle_class = questionToggle[datum.id]
            return (
              <React.Fragment key={datum.id + `-quiz-rc-question-key-` + index}>
                <div
                  key={datum.id + `-quiz-question-key-` + index}
                  className={`br-8px ${StylesA.question_div} ${question_error_class == true ? `border_on_hover` : `border_1px_904744`} mb-2`}
                >
                  <div className={`${StylesA.question_name_div} w-100 py-1 ${toggle_class ? `border-bottom-0` : ``}`}>
                    <div
                      className={`${StylesA.drag_handle_div} px-2 py-1 align-items-center`}
                      onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                    >
                      <img src={DragHandleIcon} alt={`question-drag-handle_${datum.id.toString()}`} className={`my-auto`} />
                      <p className={`mb-0 pl-2 fs-20px white-text`}>{index + 1}</p>
                    </div>
                    <input
                      type="text"
                      name="title"
                      id={datum.id + `-question-input-id`}
                      defaultValue={datum ? (datum.title === "sample question console" ? `` : datum.title) : ""}
                      placeholder={"Enter Question Title"}
                      onChange={(e) => {
                        setSavedContent(false)
                        handleUpdateQuestion(index, e.target.name, e.target.value, datum.id)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        if (datum.title !== e.target.value) {
                          updateQuestion(e.target.name, e.target.value, datum.id, questions, questionOptions, index)
                        }
                      }}
                      className={`${StylesA.question_title_input} form-control px-3`}
                      required
                    />
                    <div className="d-flex align-items-center">
                      <img
                        onClick={(e) => handleQuestionToggle(datum.id, datum.question_type)}
                        src={toggle_class ? ChevronCircleUp : ChevronCircleDown}
                        alt={"arrow for collapse and expand"}
                        className={`${StylesA.arrow_circle} mr-2`}
                      />
                      <img
                        src={DeleteIcon}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          datum.index = index
                          setDeleteResourceData(datum)
                        }}
                        alt={`question delete icon`}
                        className={`my-auto mr-3 pointer object-fit-contain h-18px`}
                      />
                    </div>
                  </div>
                  {toggle_class ? (
                    <div className={`${StylesA.options_section} py-3 pl-4 pr-3`}>
                      {showOptionButtons(questionOptions["question_" + datum.id], datum, datum.id, index)}
                    </div>
                  ) : (
                    ``
                  )}
                </div>
                {!question_error_class && clickedPublish ? (
                  <Col xs="12" sm="12" md="12" lg="12" className={`mt-2 mb-2 px-0 `}>
                    <div className="d-inline-flex px-3 py-0 br-23px">
                      <Image className="mt-1 mr-1 h-14px" src={ExclamationTriangleYellow} />
                      <p className="mb-0 fs-14px color-F4F411">{getErrorMessage(datum)}</p>
                    </div>
                  </Col>
                ) : null}
              </React.Fragment>
            )
          })}
        </div>
      )
    } else {
      return <p className="fs-13px m-0 white-text mb-1 text-center">No questions created yet!</p>
    }
  }

  async function handleQuizName(name, value, quiz_id) {
    let formData = new FormData()
    formData.append(name, value)
    formData.append("is_added_to_library", addToResLib)
    if (quiz_id !== undefined && value !== "") {
      let res = await putRequest("/quiz/" + quiz_id + "/", formData)
      if (res.success) {
        setSavedContent(true)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      setSavedContent(true)
    }
  }

  function handleChangeQuiz(e, name = null, value = null, type = "") {
    let current_quiz = { ...quiz }
    if (name != null) {
      value = value
      current_quiz[name] = value
    }
    setQuiz(current_quiz)
    delayedQuery(name, value, currentPoll, "quiz")
  }

  async function handleSubmitQuiz(event) {
    event.preventDefault()
    let check_error = questions.filter((ques) => ques.is_valid_question === false)
    if (currentPoll != undefined && check_error.length == 0 && quiz.name.trim() != "" && quiz.name !== "sample quiz console") {
      let quiz_id = currentPoll
      let formData = new FormData()
      formData.append("is_added_to_library", addToResLib)
      if (window && window.FromTrainingOrClassCreation && window.FromTrainingOrClassCreation.sequence) {
        for (let p in window.FromTrainingOrClassCreation) {
          formData.append(p, window.FromTrainingOrClassCreation[p])
        }
      }

      // Append details for creating group common resource using the poll.
      if (props.dataToCreateResource) {
        for (let key in props.dataToCreateResource) {
          formData.append(key, props.dataToCreateResource[key])
        }
      }

      sidebarDispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Polls" })
      let res = await postRequest("/quiz/" + quiz_id + "/publish_quiz/", {})
      if (res.success) {
        if (res.data.error) {
          dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.data.error } })
        }
        if (res.data.success) {
          setUsingDropdown(!usingDropdown)
          //Delete the rows with empty text boxes
          let questions_options = { ...questionOptions },
            non_empty_options
          for (const key in questions_options) {
            non_empty_options = questions_options[key].filter((op) => op.name.trim() !== "" && op.name !== "sample option console")
            questions_options[key] = non_empty_options
          }
          setQuestionOptions(questions_options)
          sidebarDispatch({ type: SET_REFETCH_RESOURCES, payload: true })

          setShowPublishedMsg(true)
          setTimeout(() => {
            closePollModal()
          }, 2100)

          if (props.path !== "dashboard" && res.data.id && res.data.id > 0) {
            publishResource(res.data) //only needed in class room
          }
          if (props.live_classroom || props.path) {
            setTimeout(() => {
              props.fetchAllResources(true)
            }, 1000)
          }
        }
        setUsingDropdown(!usingDropdown)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    } else {
      if (quiz.name.trim() == "" || quiz.name === "sample quiz console") {
        let error_messages = "Please provide valid poll name"
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
      } else {
        let error_messages = "Your questions are not valid"
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: error_messages } })
      }
    }
  }

  async function publishResource(resource) {
    let formData = new FormData()
    formData.append("sequence", 0.0)
    formData.append("resource_type", "quiz")
    formData.append("resource_id", resource.id)
    formData.append("add_to_template", props.template_id)
    let res = await postRequest("/resources/create/", formData)
    if (res.success) {
      props.fetchPublishedResources(true)
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }

  function clearTimer() {
    if (pollTime.current !== null) {
      clearInterval(pollTime.current)
      pollTime.current = null
    }
  }

  function pollTimer() {
    clearTimer()
    let now = new Date()
    let today = now.getTime()
    let start_date = new Date(published_poll_data.triggered_time)
    let total_time_in_sec = start_date.getTime()
    if (today > total_time_in_sec) {
      pollTime.current = setInterval(() => {
        let tod = new Date()
        let remainingTime = tod.getTime() - total_time_in_sec
        if (remainingTime > 0 && !published_poll_data?.end_activity) {
          let sec = Math.floor(remainingTime / 1000)
          let min = Math.floor(sec / 60)
          sec %= 60
          setMinLeft(min < 10 ? "0" + min : min)
          setSecLeft(sec < 10 ? "0" + sec : sec)
        } else {
          clearTimer()
        }
      }, 1000)
    } else {
      clearTimer()
      setMinLeft("00")
      setSecLeft("00")
    }
  }

  useEffect(() => {
    if (!published_poll_data?.end_activity) {
      pollTimer()
    } else {
      clearTimer()
    }
    return () => clearInterval()
  }, [published_poll_data])

  const showPublishedPolls = () => {
    if (published_poll_fetched) {
      return (
        <Modal.Body
          style={{
            borderRadius: "8px",
            backgroundColor: "#212C42",
            padding: "0",
            border: published_poll_data.end_activity ? "1px solid #929BBD" : "2px solid #1CAAAA",
            boxShadow: "0px 3px 12px #00000099",
            overflowY: "auto",
          }}
        >
          <div>
            <div className="d-inline-flex w-100 align-items-center justify-content-between p-12px border-bottom-929bbd">
              <div className="flex-grow-1 line-height-1 m-0">
                {published_poll_data.end_activity ? null : (
                  <div className={`mr-2 w-15px h-15px rounded-circle ${props.published_poll_data.end_activity ? "bg-transparent" : "bg-1CAAAA"}`}></div>
                )}
                Poll {published_poll_data.end_activity ? "Result" : "Live"}
                <span className="pl-2 fs-18px bold-500">({published_poll_data?.resource_data?.name})</span>
              </div>
              <div className="d-inline-flex flex-shrink-0 ml-auto align-items-center">
                {published_poll_data.end_activity ? null : (
                  <div className={`d-inline-flex mr-3 align-items-center`}>
                    <Image src={Clock} alt={`Clock`} className={`mb-0 mr-1 h-18px object-fit-contain`} />
                    <p className="mb-0">
                      {minLeft}:{secLeft} mins
                    </p>
                  </div>
                )}
                <div className="mr-3">
                  <div className="d-inline-flex w-100 align-items-center">
                    <Image src={User} alt={`user`} className={`mb-0 mr-1 object-fit-contain h-15px`} />
                    <div>
                      <p className="mb-0 fs-14px">
                        {published_poll_details?.attending_students}/{published_poll_details?.total_participants} students
                      </p>
                    </div>
                  </div>
                  <div className="h-5px bg-FFFFFF30 br-21px">
                    <div
                      className="br-21px bg-white h-100"
                      style={{
                        width: `${parseInt((published_poll_details?.attending_students * 100) / published_poll_details?.total_participants)}%`,
                      }}
                    ></div>
                  </div>
                </div>
                <Image
                  src={Close}
                  className="pointer ml-auto w-15px h-15px"
                  onClick={() => {
                    closePollModal()
                    clearTimer()
                  }}
                />
              </div>
            </div>
            <div className={`w-100`}>
              <Col
                sm="12"
                md="12"
                lg="12"
                className="px-0"
                style={{
                  background: "#212C42",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                {showPublishedPollName()}
              </Col>
              <Col
                sm="12"
                md="12"
                lg="12"
                className={`pointer ${StylesA.end_poll_section} d-flex justify-content-around px-0`}
                style={{
                  borderTop:
                    published_poll_data.end_activity === false ? "2px solid #1CAAAA" : published_poll_data.show_results === false ? "2px solid #929BBD" : " ",
                  backgroundColor: "#586886",
                }}
              >
                {published_poll_data.end_activity === false ? (
                  <div
                    className={`w-100 py-1 text-center`}
                    onClick={(e) => {
                      e.preventDefault()
                      fetchTriggeredPollDetail(published_poll_data.id)
                      handleSave(false, true)
                      triggerResource()
                    }}
                  >
                    End Poll
                  </div>
                ) : null}
                {published_poll_data.end_activity === true && published_poll_data.show_results === false ? (
                  <div
                    className={`w-100 py-1 text-center`}
                    onClick={(e) => {
                      e.preventDefault()
                      fetchTriggeredPollDetail(published_poll_data.id)
                      handleSave(true, true)
                      triggerResource()
                      setShowPublishResultsAgainBtn(false)
                    }}
                  >
                    Publish Results
                  </div>
                ) : null}
                {published_poll_data.end_activity === true && published_poll_data.show_results === true && showPublishResultsAgainBtn ? (
                  <div
                    className={`w-100 py-1 text-center`}
                    onClick={(e) => {
                      e.preventDefault()
                      triggerResource()
                      setShowPublishResultsAgainBtn(false)
                    }}
                  >
                    Publish Results Again
                  </div>
                ) : null}
              </Col>
            </div>
          </div>
        </Modal.Body>
      )
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Spinner animation="border" variant="secondary" size="sm" />
        </Col>
      )
    }
  }
  const showPublishedPollName = () => {
    return (
      <div className={`w-100`} style={{ padding: "12px" }}>
        {showPublishedPollQuestionDetails()}
      </div>
    )
  }
  const showPublishedPollQuestionDetails = () => {
    if (published_poll_details.questions.length > 0) {
      return published_poll_details.questions.map((poll_data, poll_index) => {
        return (
          <div className={`d-inline-flex w-100`} key={`question_${poll_data.id}`}>
            <p className={`mb-0 mr-2`} style={{ fontSize: "28px", fontWeight: "700" }}>
              {poll_index + 1}
            </p>
            <div
              key={`${poll_data.title}-${poll_index}`}
              style={{ backgroundColor: "#303C54", borderRadius: "9px" }}
              className={`inner-element-box-shadow w-100 py-2 mb-2`}
            >
              <div className="d-inline-flex  w-100 align-items-center">
                <div className="flex-grow flex-fill w-100 pl-2" style={{ borderBottom: "1px solid #E0E2E530" }}>
                  <p className={`mb-2 pl-1`}>{poll_data.title}</p>
                </div>
              </div>
              <div style={{}} className={`px-3 py-2`}>
                {showPublishedPollReport(poll_data.options)}
              </div>
              <div className="d-inline-flex px-3  w-100 align-items-center">
                <div>
                  <div className="d-inline-flex w-100 align-items-center">
                    <img src={User} alt={`user`} className={`mb-0 mr-1`} style={{ objectFit: `contain`, height: `15px` }} />
                    <div>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {poll_data?.answered_student_count}/{published_poll_details?.total_participants} students
                      </p>
                    </div>
                  </div>
                  <div style={{ height: "5px", backgroundColor: "#FFFFFF30", borderRadius: "21px" }}>
                    <div
                      style={{
                        width: `${parseInt((poll_data?.answered_student_count * 100) / published_poll_details?.total_participants)}%`,
                        height: "100%",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "21px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    } else {
      return null
    }
  }

  async function fetchOptionsSelectedParticipants(option_id) {
    if (option_id && published_poll_data?.id) {
      let res = await getRequest(`/quiz-option-snapshot/${option_id}/answered_students/?resource=${published_poll_data?.id}`)
      if (res.success) {
        let dummy = { ...optionsSelectedParticipants }
        dummy[`option_${option_id}`] = res.data
        setOptionsSelectedParticipants(dummy)
        setFetchedOptionsSelectedParticipants(true)
      } else {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }
  }

  const showPublishedPollReport = (poll_report) => {
    return poll_report.map((report, index) => {
      let background = ""
      if (report.answer_percentage.percentage > 0) {
        if (parseInt(report.answer_percentage.percentage) < 50) {
          background = `linear-gradient(to left, #42506C ${`${100 - parseInt(report.answer_percentage.percentage)}%`}, #5D6D8D ${`${
            100 - parseInt(report.answer_percentage.percentage)
          }%`})`
        } else {
          background = `linear-gradient(to right, #5D6D8D ${`${parseInt(report.answer_percentage.percentage)}%`}, #42506C ${`${parseInt(
            report.answer_percentage.percentage
          )}%`})`
        }
      } else {
        background = "#42506C"
      }
      return (
        <div key={`${index}-${report.name.toLowerCase().split(" ").join("-")}`} className="w-100" style={{ fontSize: "14px" }}>
          <div
            key={`allquestins${index}`}
            className={`w-100 d-inline-flex mb-2 border_on_hover`}
            style={{
              borderRadius: "3px",
              background: `${background}`,
            }}
          >
            <div
              className={`mb-0 pl-3 flex-grow-1 py-2 poppins_font `}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                left: "0px",
                top: "20%",
              }}
            >
              {report.name}
            </div>
            <div
              className={`px-2 d-flex`}
              style={{
                minWidth: "120px",
                borderRadius: "3px",
              }}
            >
              {report.answer_percentage.count > 0 ? (
                <span className={`underline pointer my-auto`}>
                  <Dropdown
                    onClick={(e) => {
                      e.preventDefault()
                      setFetchedOptionsSelectedParticipants(false)
                      fetchOptionsSelectedParticipants(report.id)
                    }}
                    className={`breakout-groups mr-0`}
                  >
                    <Dropdown.Toggle
                      className="border-none d-inline-flex align-items-center p-0 shadow-none"
                      style={{ backgroundColor: "transparent" }}
                      id="dropdown-basic"
                    >
                      <u className="mb-0 pointer" style={{ fontSize: "16px", fontWeight: "500" }}>{`${parseInt(report.answer_percentage.count)} ${
                        report.answer_percentage.count === 1 ? "Participant" : `Participants`
                      }`}</u>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className={`br-9px breakout-groups text-center ${Styles.min_fit_width}`}
                      style={{ fontSize: "14px", color: "#E0E2E5", width: "180px" }}
                    >
                      {fetchedOptionsSelectedParticipants ? (
                        optionsSelectedParticipants[`option_${report.id}`]?.length > 0 ? (
                          optionsSelectedParticipants[`option_${report.id}`]?.map((student, index) => {
                            return (
                              <Dropdown.Item className={`py-1`} style={{ color: "#E0E2E5" }}>
                                {student.owner__first_name}
                              </Dropdown.Item>
                            )
                          })
                        ) : (
                          <Dropdown.Item className={`py-1`} style={{ color: "#E0E2E5" }}>
                            0 Students
                          </Dropdown.Item>
                        )
                      ) : (
                        <Dropdown.Item className={`py-1`} style={{ color: "#E0E2E5" }}>
                          <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              ) : (
                <span className={`my-auto`} style={{ fontSize: "16px", fontWeight: "500" }}>{`${parseInt(report.answer_percentage.count)} Participant`}</span>
              )}
            </div>
            {/*<p className={`mb-0 pr-3 pointer poppins_font position-absolute`} style={{fontSize:'16px',fontWeight:'500',right:'0px',top:'25%'}}><u>View Details</u></p>*/}
          </div>
        </div>
      )
    })
  }

  function handleSave(show_results, end_activity = false) {
    let formData = new FormData()
    formData.append("resource_id", published_poll_data.resource_data.id)
    formData.append("resource_type", published_poll_data.resource_type)
    formData.append("poll_ended", end_activity)
    formData.append("end_activity", end_activity)
    formData.append("show_results", show_results)
    formData.append("template_id", props.template_id)
    if (show_results) {
      formData.append("created", new Date().toISOString())
    }
    formData.append("is_triggered", true)
    let res = putRequest(`/resources/${currentPoll}/`, formData)
    if (res.success) {
      if (!show_results && props?.setUpdatedResource) {
        props.setUpdatedResource(res.data)
      }
      if (!show_results && props?.fetchPublishedResources) {
        props.fetchPublishedResources(true)
      }
      let poll_detail = { ...published_poll_data }
      poll_detail["is_triggered"] = true
      poll_detail["show_results"] = show_results
      poll_detail["end_activity"] = end_activity
      setPublishedPollData(poll_detail)
      if (end_activity) {
        props.setActiveActivityData({})
      }
    } else {
      dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
    }
  }
  async function triggerResource() {
    setTimeout(async function () {
      let topic_data = ""
      if (topicId != null) {
        topic_data = "topic=" + topicId + "&resource=" + published_poll_data.id
      }
      let res = await getRequest(`/basic-resources-list/?paginate_by=1&is_triggered=true&is_poll=false&template_id=${props.template_id}&${topic_data}`)
      if (!res.success) {
        dispatch({ type: SET_NOTIFICATION_DATA, payload: { type: "error", title: res.msg } })
      }
    }, 300)
  }
  const data_for_menu = [
    { id: 0, name: "Poll Information", selected: true },
    { id: 1, name: "Add Questions", selected: false },
    { id: 2, name: "Save & Publish", selected: false },
  ]

  function closePollModal() {
    const event = new CustomEvent("close-poll-modal", {
      bubbles: true,
      detail: { id: quiz.id, ...FromTrainingOrClassCreation },
    })
    document.dispatchEvent(event)

    const evt = new CustomEvent("my-refresh-resources", {
      bubbles: true,
      detail: "poll",
    })
    document.dispatchEvent(evt)

    if (quiz.name === "sample quiz console") {
      deletePoll(quiz.id)
    } else {
      props.closeModal(published_poll_data?.end_activity)
    }
  }

  const focusOnData = (id) => {
    let data = document.getElementById(id)
    if (data) {
      data.focus()
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.closeModal()
        resetState()
      }}
      centered={true}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="example-custom-modal-styling-title"
      className="classroom-poll-modal"
      style={{ border: "none", color: "#E0E2E5" }}
    >
      {props.showPollResult ? (
        showPublishedPolls()
      ) : (
        <React.Fragment>
          {showPublishedMsg ? <NotificationModal msg={`Your poll got published Sucessfully`} /> : null}
          <Modal.Body
            style={{
              borderRadius: "8px",
              backgroundColor: "#212C42",
              padding: "0",
              border: "1px solid #929BBD",
              boxShadow: "0px 3px 12px #00000099",
              overflowY: "auto",
            }}
          >
            <div id={`scrollable-div`}>
              <div id="error-div-for-option" className="position-absolute text-white" style={{ top: "0px", right: "0px", zIndex: "2" }}></div>
              {/* {deleteQuestionModal()} */}
              <DeleteModal
                show={Object.keys(deleteResourceData).length > 0}
                onHide={handleClose}
                itemName={deleteResourceData.quiz_question ? "option" : "question"}
                action={() => {
                  deleteResource(deleteResourceData)
                }}
              />
              <div className="d-flex justify-content-between p-12px border_1px_s_929BBD">
                <p className="line-height-1 m-0">Create Poll</p>
                <Image src={Close} className="pointer w-15px h-15px" onClick={() => closePollModal()} />
              </div>
              <div
                id="poll-questions-div"
                style={{
                  margin: "12px",
                  overflowY: "auto",
                  maxHeight: "400px",
                  scrollBehavior: "smooth",
                }}
              >
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  className="border_on_hover pl-0"
                  style={{
                    backgroundColor: "#303C54",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "9px",
                    border: "1px solid #303C54",
                    marginBottom: "12px",
                  }}
                >
                  <InputGroup
                    className="py-1"
                    style={{
                      borderRadius: "9px",
                      border: "1px solid transparent",
                    }}
                  >
                    <InputGroup.Prepend
                      className="border-0"
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "9px 0px 0px 9px",
                      }}
                    >
                      <InputGroup.Text
                        className="border-0 d-flex justify-content-center"
                        style={{
                          backgroundColor: "#303C54",
                          borderRadius: "9px 0px 0px 9px",
                          color: "#E0E2E5",
                          opacity: "41%",
                          fontSize: "16px",
                        }}
                        id="basic-addon1"
                        onClick={(e) => focusOnData("QuizName")}
                      >
                        Poll Name:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className={`border-0 d-flex justify-content-center shadow-none ${StylesC.cs_create} text-white`}
                      style={{
                        backgroundColor: "#303C54",
                        borderRadius: "0px",
                        fontSize: "16px",
                      }}
                      type="text"
                      name="name"
                      defaultValue={quiz.name === "sample quiz console" ? `` : quiz.name}
                      onChange={(e) => {
                        setSavedContent(false)
                        handleChangeQuiz(e, e.target.name, e.target.value)
                      }}
                      onBlur={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleQuizName(e.target.name, e.target.value, currentPoll)
                      }}
                      id="QuizName"
                      placeholder={"Enter Poll Title"}
                      required
                    />
                  </InputGroup>
                </Col>
                {showQuizQuestions()}
                {questionsFetched && questionsNext !== null ? (
                  <Col md="auto" className={`d-inline-flex w-100 my-2`}>
                    <Button
                      variant="none"
                      onClick={(e) => {
                        e.preventDefault()
                        fetchNextQuizQuestions(questionsNext)
                      }}
                      className={`${Styles.font_12} text-center px-3 py-2 mx-auto bg-4b5a9b color-c6cff4 br-14px`}
                    >
                      See More
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
                <div className={`justify-content-center pt-2 pb-4 d-flex bg-transparent br-9px`}>
                  <div className={` text-center d-inline-flex align-items-center`}>
                    {/* <p className={`${Styles.font_14} m-0`} style={{ color: `#D4DCF7` }}>Drag and drop questions here <span className={`font-weight-bold mx-3`} style={{ fontSize: `16px` }} >or</span> </p> */}
                    <button
                      disabled={!questionCreated}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setQuestionCreated(false)
                        createQuestion("0")
                      }}
                      className={`${StylesB.add_button} border_on_hover fs-14px`}
                    >
                      {questionCreated ? "" : <Spinner animation="border" variant="secondary" size="sm" className="mr-2" />}
                      Add question
                    </button>
                  </div>
                </div>
              </div>
              <Col
                className="px-0 pl-3 py-2"
                style={{
                  borderTop: "1px solid #63666f",
                  backgroundColor: "#212C42",
                  borderRadius: "0px 0px 9px 9px",
                  boxShadow: "0px -3px 12px #00000099",
                }}
              >
                <div className="d-inline justify-content-start">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setClickedPublish(true)
                      handleSubmitQuiz(e)
                    }}
                    className="border_on_hover fs-14px"
                    style={{
                      backgroundColor: "#586886",
                      border: "1px solid #586886",
                      height: "28px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "23px",
                      color: "#E0E2E5",
                    }}
                  >
                    Publish
                  </button>
                  <span className="mb-0 ml-2 fs-14px">
                    {!savedContent ? (
                      <span className={`bg-transparent border-0`} id={`basic-addon`}>
                        <Spinner animation="border" variant="secondary" size="sm" className="mr-1" />
                      </span>
                    ) : (
                      <Image src={CheckIcon} alt={`changes_saved_check`} className={`ml-auto mb-0 mr-1 object-fit-contain h-12px`} />
                    )}
                    All changes are saved
                  </span>
                </div>
                {dispAddToResLib ? (
                  <div className="pr-2 d-inline-block float-right">
                    <Form.Check
                      className="fs-14px"
                      type={"checkbox"}
                      id={`default-checkbox`}
                      label={`Add this quiz to the resource library`}
                      checked={addToResLib}
                      onChange={() => setAddToResLib((p) => !p)}
                    />
                  </div>
                ) : null}
              </Col>
            </div>
          </Modal.Body>
        </React.Fragment>
      )}
    </Modal>
  )
}

export default ClassRoomPoll
