// This file contains the store configuration for the AssessmentMuralReducer.

import React, { createContext, useContext, useReducer } from "react"
import AssessmentMuralReducer from "./AssessmentMuralReducer"

// Create the AssessmentMuralStore component
const AssessmentMuralStore = ({ children }) => {
  const [state, dispatch] = useReducer(AssessmentMuralReducer, {
    showMuralCanvas: false,
    draggableHash: {
      x: 0,
      y: 0,
      width: "92vw",
      height: "98vh",
    },
    screenSizeButtons: {
      fullscreen: false,
      fitToScreen: true,
      minimizeScreen: true,
    },
    canvasLink: null,
    windowState: null,
    showMuralAuthWarning: false,
    showLoader: true,
    activeAssessmentData: {
      participant_id: null,
      mural_assessment_id: null,
    },
    muralAssessment: null,
    muralChatWindowExpand: false,
    newMessageFlag: {},
    showParticipantDetailsModal: false,
    activeFilter: "profile",
  })

  return <AssessmentMuralContext.Provider value={[state, dispatch]}>{children}</AssessmentMuralContext.Provider>
}
// Create the context for the AssessmentMuralStore
export const AssessmentMuralContext = createContext()

// Create the custom hook to use the AssessmentMuralStore
export const useAssessmentMural = () => useContext(AssessmentMuralContext)

export default AssessmentMuralStore
