import React, { useEffect, useState } from "react"

export default function AddGtmScript(props) {
  useEffect(() => {
    GoogleTagManager("GTM-PXRDLPW4")
  }, [])

  return null
}

function createScript(id, scriptText, src, async) {
  var script = document.createElement("script")

  if (id) {
    script.setAttribute("id", id)
  }

  if (src) {
    script.setAttribute("src", src)
  }

  if (async) {
    script.async = true
  }

  if (scriptText) {
    script.appendChild(document.createTextNode(scriptText))
  }

  try {
    document.head.appendChild(script)
  } catch (e) {
    script.text = scriptText
    document.head.appendChild(script)
  }
}

function noScriptForGTM(id, url) {
  var script = document.createElement("noscript")
  script.setAttribute("id", id)
  var x = document.createElement("iframe")
  x.setAttribute("src", url)
  x.setAttribute("width", "0")
  x.setAttribute("height", "0")
  x.setAttribute("style", "display:none;visibility:hidden")
  script.appendChild(x)

  var body = document.getElementsByTagName("body")[0]
  body.insertBefore(script, body.firstChild)
}

function GoogleTagManager(value) {
  if (!value) {
    return false
  }

  const id = "google-tag-manager"
  const scriptText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${value}');`
  const url = `https://www.googletagmanager.com/ns.html?id=${value}`
  noScriptForGTM(id, url)
  createScript(id, scriptText, null, false)

  return false
}
