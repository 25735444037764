import React, { useContext } from "react"

// import Search from "./search"
// import Notifications from "./notifications"
import UserProfile from "./dashboard/dashboard-header/user-profile"
import NotificatioBellAndModal from "./live_class/header/notificationBellAndModal.js"

import { Auth } from "../services/auth"

import Styles from "./dashboard/dashboard-header/style.module.scss"

// !definition of component
/**
 *
 * @description --> Provides a fixed header for the all pages
 * @returns LayoutHeader component for Layout
 */
// ! component

const LayoutHeader = () => {
  const auth = new Auth()

  return (
    <div className={`px-0 mb-0 ${Styles.options_holder}`}>
      <div className={`my-0 mx-auto align-items-right p-2 ${Styles.options} ${auth.isAuthenticated() ? `` : `d-none`}`}>
        <div className="d-flex align-items-center text-right">
          {/* <Search
            onEnter={(searchValue) => {
              alert(searchValue)
            }}
          /> */}
          {/* <Notifications /> */}
          <NotificatioBellAndModal circle={true} notficationType="0" trainingId={null} />
          <UserProfile />
        </div>
      </div>
    </div>
  )
}

export default LayoutHeader
