export const SET_CURRENT_NAV_TAB = "SET_CURRENT_NAV_TAB"
export const SET_SELECTED_RESOURCE_TYPE = "SET_SELECTED_RESOURCE_TYPE"
export const SET_OPEN_UPLOAD_MODAL = "SET_OPEN_UPLOAD_MODAL"
export const SET_REFETCH_RESOURCES = "SET_REFETCH_RESOURCES"
export const SET_ALL_RESOURCES = "SET_ALL_RESOURCES"
export const SET_DRAGGED_ITEM = "SET_DRAGGED_ITEM"
export const SET_NAV_AND_RESOURCE_TYPE = "SET_NAV_AND_RESOURCE_TYPE"
export const SET_SHOW_POLL_MODAL = "SET_SHOW_POLL_MODAL"
export const SET_SHOW_POPQUIZ_MODAL = "SET_SHOW_POPQUIZ_MODAL"
export const SET_DATA_TO_CREATE_RESOURCE = "SET_DATA_TO_CREATE_RESOURCE"
export const SET_DISABLED_RESOURCES = "SET_DISABLED_RESOURCES"
