export const getResourceDisplayName = (resource) => {
  let resource_name = ""
  // A resource created from a left nav item will have it's details in resource_data field.
  let resource_data = resource.resource_data ? resource.resource_data : resource
  // Resources with display name are video, images and documents.
  resource_name = resource_data.display_name ? resource_data.display_name : resource_data.name

  //For Poll & Pop-Quiz, if name is the default name, then display "untitled"
  if (resource_data?.data_type && ["1", "2"].includes(resource_data?.data_type) && resource_name === "sample quiz console") {
    resource_name = "untitled"
  }
  return resource_name
}
