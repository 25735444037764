import React, { useContext } from "react"
import Btn from "../../custom/Button"
import { LazyLoadImage } from "react-lazy-load-image-component"

import Style from "./style.module.scss"
import muralIcon from "../../../images/svgs/mural-icon.svg"
import zoomIcon from "../../../images/zoom_icon.svg"
import successTick from "../../../images/svgs/sucesstick.svg"
import PlusLogo from "../../../images/svgs/plus.svg"
import refreshToken from "../../mural/refreshToken"
import Loader from "../../custom/Loader"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { SidebarContext } from "../../../stores/sidebar_store/SidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../../stores/sidebar_store/SidebarActions"
import { SET_APP_DATA } from "../../../stores/global_store/GlobalActions"
import Image from "../../custom/Image"
// import AppContext from "../../../context/appContext"
import validateZoomAuth from "../../zoom/validateZoomAuth"
import { navigate } from "gatsby"

// !definition of component
/**
 * @returns App Integration Sidebar
 */
// ! component

const AppIntegration = () => {
  const [globalStore, dispatchGlobalStore] = useContext(GlobalContext)
  const [sidebarState, dispatch] = useContext(SidebarContext)

  const handleMuralButtonClick = (openModal) => {
    if (openModal) {
      dispatchGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    } else {
      refreshToken()
    }
  }

  const handleZoomClick = () => {
    // validateZoomAuth()
    navigate("/app/zoom/authentication/?redirect=https://app.thepreceptors.io")
    /* if (openModal) {
      dispatchGlobalStore({ type: SET_APP_DATA, payload: { showModal: true } })
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    } else {
      if (buttonType === "mural") {
        refreshToken()
      } else {
        validateZoomAuth()
      }
    } */
  }
  return (
    <div className={`d-flex flex-column ${Style.card}`}>
      <div className={`${Style.cardTitle}`}>App Integration</div>
      {globalStore.appData.loader ? (
        <div className={`py-2 w-100 text-center`}>
          <Loader />
        </div>
      ) : (
        <div className="bg-transparent my-2">
          <div className={`d-flex flex-column justify-content-center br-9px bg-3C4963 mx-2 ${globalStore.appData?.data?.mural ? " pb-2" : "pb-0"} mb-2`}>
            <div className={`d-flex justify-content-between px-3 py-2 h-3rem align-items-center`}>
              <div className={`d-flex align-items-center`}>
                <LazyLoadImage src={muralIcon} className={`mb-0 h-20px`} />
                <div className={`${Style.appName}`}>Mural</div>
              </div>
              {!globalStore.appData?.data?.mural ? (
                <Btn
                  onClick={() => {
                    handleMuralButtonClick(globalStore.appData?.data?.mural)
                  }}
                  className={`d-flex flex-row align-items-center justify-content-center br-9px fs-12px h-2rem border_on_hover w-4rem`}
                >
                  <Image src={PlusLogo} alt="Add Icon" className="h-12px mr-2 " />
                  Add
                </Btn>
              ) : (
                <p
                  className="color-6ACFD6 underline pointer mt-3"
                  onClick={() => {
                    handleMuralButtonClick(globalStore.appData?.data?.mural)
                  }}
                >
                  {globalStore.appData?.data?.mural.length} active account
                </p>
              )}
            </div>
            <div className={`d-flex justify-content-between px-3 py-2 h-3rem align-items-center`}>
              <div className={`d-flex align-items-center`}>
                <LazyLoadImage src={muralIcon} className={`mb-0 h-20px`} />
                <div className={`${Style.appName}`}>Zoom</div>
              </div>
              {!globalStore.appData?.data?.mural ? (
                <Btn
                  onClick={() => {
                    handleZoomClick()
                  }}
                  className={`d-flex flex-row align-items-center justify-content-center br-9px fs-12px h-2rem border_on_hover w-4rem`}
                >
                  <Image src={PlusLogo} alt="Add Icon" className="h-12px mr-2 " />
                  Add
                </Btn>
              ) : (
                <p
                  className="color-6ACFD6 underline pointer mt-3"
                  onClick={() => {
                    // handleMuralButtonClick(globalStore.appData?.data?.mural)
                  }}
                >
                  {globalStore.appData?.data?.mural.length} active account
                </p>
              )}
            </div>
            {globalStore.appData?.data?.mural && (
              <Btn
                onClick={() => {
                  handleMuralButtonClick(globalStore.appData?.data?.mural)
                }}
                className={`d-flex flex-row align-items-center justify-content-center mx-3 br-9px fs-12px h-2rem border_on_hover`}
              >
                <Image src={PlusLogo} alt="Add Icon" className="h-12px mr-2 " />
                Add Account
              </Btn>
            )}
          </div>
        </div>
      )}
      <div className={`${Style.footer}`}>More Apps coming soon!</div>
    </div>
  )
}

export default AppIntegration
