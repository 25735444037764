import React from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import Style from "./style.module.scss"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Image from "../custom/Image"

const ProfileUpdateProgress = ({
  percentage,
  image,
  footerImgFlag,
  footerImg,
  mainDivStyle,
  imgStyle,
  handleClick,
  footerImgStyle,
  imgName,
  strokeWidth,
  footerImgName,
}) => {
  return (
    <div className={mainDivStyle} style={{ position: "relative" }} onClick={handleClick}>
      <CircularProgressbarWithChildren
        value={percentage}
        className={Style.progressBar}
        styles={buildStyles({
          textColor: "#000",
          pathColor: "#E0E2E5",
          trailColor: `rgba(88, 104, 134, 1)`,
          border: "2px solid",
        })}
        strokeWidth={strokeWidth}
        counterClockwise={true}
      >
        <LazyLoadImage src={image} alt={imgName} className={imgStyle} />
      </CircularProgressbarWithChildren>
      {footerImgFlag && <Image src={footerImg} alt={footerImgName} className={`${footerImgStyle} position-absolute rounded-circle`} />}
    </div>
  )
}
ProfileUpdateProgress.defaultProps = {
  percentage: 0,
  imgStyle: Style.imgStyle,
  strokeWidth: 3,
  mainDivStyle: Style.mainDivStyle,
  footerImgFlag: false,
}

ProfileUpdateProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  footerImg: PropTypes.string,
  footerImgFlag: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  mainDivStyle: PropTypes.oneOfType([
    PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  imgStyle: PropTypes.oneOfType([
    PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  footerImgStyle: PropTypes.oneOfType([
    PropTypes.shape({
      bottom: PropTypes.string,
      right: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      background: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  strokeWidth: PropTypes.number.isRequired,
  imgName: PropTypes.string.isRequired,
  footerImgName: PropTypes.string,
}

export default ProfileUpdateProgress
