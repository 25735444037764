import React, { createContext, useContext } from "react"

import PropTypes from "prop-types"
import { WebsocketContext } from "../socketProvider"

//default value passed to create context :- ready, value, send
export const TrainingSocketContext = createContext(false, null, () => {})

// The component tree than any consumers.
const TrainingSocketProvider = ({ children }) => {
  const [ready, val, send] = useContext(WebsocketContext)

  return <TrainingSocketContext.Provider value={[ready, val, send]}>{children}</TrainingSocketContext.Provider>
}

TrainingSocketProvider.propTypes = {
  // Children node need to pass to provider component
  children: PropTypes.node.isRequired,
}

export default TrainingSocketProvider
