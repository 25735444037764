import { getRequest, postRequest } from "../../services/rest_service"

// API's for Notification

const apiReadNotifications = async (training_id, notficationType) =>
  await getRequest(
    `/turient-notifications/list_read_notification/?limit=12${
      training_id ? `&training=${training_id}&` : `&`
    }notification_type=${notficationType}&ordering=-created_at`
  )

const apiUnReadNotifications = async (training_id, notficationType) =>
  await getRequest(
    `/turient-notifications/list_unread_notification/?limit=12${
      training_id ? `&training=${training_id}&` : `&`
    }notification_type=${notficationType}&ordering=-created_at`
  )

const apiMarkNotificationAsRead = async (notifcationId) => await postRequest(`/turient-notifications/${notifcationId}/read_notification/`)

const apiMarkAllNotificationsAsRead = async (training_id, notficationType) =>
  await postRequest(`/turient-notifications/mark_all_as_read/?${training_id ? `training=${training_id}&` : ``}notification_type=${notficationType}`)

const apiMarkAllNotificationsAsSeen = async (training_id, notficationType) =>
  await postRequest(`/turient-notifications/mark_all_as_seen/?${training_id ? `training=${training_id}&` : ``}notification_type=${notficationType}`)

export { apiReadNotifications, apiUnReadNotifications, apiMarkNotificationAsRead, apiMarkAllNotificationsAsRead, apiMarkAllNotificationsAsSeen }
