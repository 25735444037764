/*
    In this file only Local Storage constatnt value is expected.
*/

export const PERMISSION = "PERMISSION"
export const AUTH_TOKEN = "student_authenticity_token"
export const STUDENT_ID = "student_id"
export const USER_NAME = "username"
export const USER_FIRST_NAME = "student_first_name"
export const ZOOM_TIMESTAMP = "zoom_timestamp"
export const COMPANY = "company_id"
