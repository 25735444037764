const React = require("react")
const AssessmentMuralStore = require("./src/stores/assessment_mural_store/AssessmentMuralStore").default
require("@fontsource/poppins")
const Layout = require("./src/components/layout").default
const ClassroomStore = require("./src/stores/classroom_store/ClassroomStore").default
const ClassroomSidebarStore = require("./src/stores/classroom_store/classroom_sidebar/classroomSidebarStore").default
const GlobalStore = require("./src/stores/global_store/GlobalStore").default
const LogsProvider = require("./src/stores/log_store/logsProvider").default
const NetworkProvider = require("./src/stores/network/networkProvider").default
const SidebarStore = require("./src/stores/sidebar_store/SidebarStore").default

// Wraps every page in a component.
// Add same content to gatsby ssr file also to avoid production build error for global state.
exports.wrapPageElement = ({ element, props }) => {
  return (
    <GlobalStore>
      <SidebarStore>
        <ClassroomStore>
          <ClassroomSidebarStore>
            <AssessmentMuralStore>
              <NetworkProvider>
                <LogsProvider>
                  <Layout {...props}>{element}</Layout>
                </LogsProvider>
              </NetworkProvider>
            </AssessmentMuralStore>
          </ClassroomSidebarStore>
        </ClassroomStore>
      </SidebarStore>
    </GlobalStore>
  )
}
