let BASE_URL = process.env.GATSBY_REACT_APP_WS_HOST

const format_url = (URL) => {
  return `${BASE_URL}${URL}`
}

export const WsConnect = (URL) => {
  let connection = new WebSocket(format_url(URL))
  return connection
}
