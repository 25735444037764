export const SET_SHOW_QUESTION_MODAL = "SET_SHOW_QUESTION_MODAL"
export const SET_QUESTION_DATA = "SET_QUESTION_DATA"
export const SET_QUESTION_NEXT_DATA = "SET_QUESTION_NEXT_DATA"
export const SET_REPLY_DATA = "SET_REPLY_DATA"
export const SET_REPLY_NEXT_DATA = "SET_REPLY_NEXT_DATA"
export const SET_DRAFT_REPLY = "SET_DRAFT_REPLY"
export const SET_ALL_TOPICS = "SET_ALL_TOPICS"
export const SET_ALL_SESSIONS = "SET_ALL_SESSIONS"
export const SET_REPLY_RESOURCES = "SET_REPLY_RESOURCES"
export const SET_REPLY_NEXT_RESOURCES = "SET_REPLY_NEXT_RESOURCES"
