export const TOGGLE_TEST = "TOGGLE_TEST"
export const SET_TRAINING_ID = "SET_TRAINING_ID"
export const SET_TEMPLATE_ID = "SET_TEMPLATE_ID"
export const SET_ACTIVE_SESSION = "SET_ACTIVE_SESSION"
export const SET_ACTIVE_ACTIVITY_DATA = "SET_ACTIVE_ACTIVITY_DATA"
export const SHOW_ACTIVE_ACTIVITY = "SHOW_ACTIVE_ACTIVITY"
export const SHOW_TRIGGERED_RESOURCE = "SHOW_TRIGGERED_RESOURCE"
export const SET_OPENED_OVERLAY_SECTION = "SET_OPENED_OVERLAY_SECTION"
export const VIEW_MODERATION_RESOURCE = "VIEW_MODERATION_RESOURCE"
export const SET_COMMON_RESOURCE_DRAG_ENDED = " SET_COMMON_RESOURCE_DRAG_ENDED"
export const SET_TRAINING_BREAK = "SET_TRAINING_BREAK"
export const SET_ACTIVE_SECTION = "SET_ACTIVE_SECTION"
export const SET_DISCUSSION_BOARD = "SET_DISCUSSION_BOARD"
export const SET_ACTIVE_BREAKOUT_SESSION = "SET_ACTIVE_BREAKOUT_SESSION"
export const SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL = "SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL"
export const SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL = "SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL"
export const SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION = "SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION"
export const SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION = "SET_SEEK_REPLY_MODAL_FROM_NOTIFICATION"
export const SET_BREAKOUT_GROUP_IDS = "SET_BREAKOUT_GROUP_IDS"
export const SET_SHARED_RESOURCE_DRAG_ENDED = " SET_SHARED_RESOURCE_DRAG_ENDED"
export const SET_BREAKOUT_RESOURCE_DRAG_ENDED = " SET_BREAKOUT_RESOURCE_DRAG_ENDED"
export const SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL = "SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL"
