import DisplayDate from "../../services/format_date"

class TimeDifference {
  getTimeDifference(start_date1, end_date1) {
    let start_date = new Date(start_date1)
    let end_date = new Date(end_date1)
    let diff = end_date.getTime() - start_date.getTime()
    let msec = diff
    let hours = Math.floor(msec / 1000 / 60 / 60)
    msec -= hours * 1000 * 60 * 60
    let minute = Math.floor(msec / 1000 / 60)
    msec -= minute * 1000 * 60
    let time_diff = `(` + hours + (hours > 1 ? `hrs` : `hr`) + ` ` + minute + `min)`
    let time = DisplayDate.timeFormat(start_date) + " - " + DisplayDate.timeFormat(end_date) + ` ` + time_diff
    return time
  }

  getHowMuchTimeAgo(time) {
    let currentDate = new Date()
    let notificationTime = new Date(time)
    let diffInMilliSec = currentDate - notificationTime

    let minutes = Math.floor(diffInMilliSec / 60000)
    let hours = Math.floor(minutes / 60)
    let days = Math.floor(hours / 24)
    let months = Math.floor(days / 30)
    let years = Math.floor(months / 12)

    // Build the result string
    let result = ""
    if (years > 0) {
      result += years + " year" + (years > 1 ? "s" : "")
    } else if (months > 0) {
      result += months + " month" + (months > 1 ? "s" : "")
    } else if (days > 0) {
      result += days + " day" + (days > 1 ? "s" : "")
    } else if (hours > 0) {
      result += hours + " hour" + (hours > 1 ? "s" : "")
    } else {
      result += minutes > 1 ? `${minutes} mins` : "few sec"
    }

    return result
  }
}
const timediff = new TimeDifference()

export default timediff
