import React, { useState, useEffect, useContext } from "react"
import { getRequest } from "../../services/rest_service"
import NotificationListItem from "./listItem"
import Loader from "../custom/Loader"
import { GlobalContext } from "../../stores/global_store/GlobalStore"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import {
  SET_NOTIFICATION_UN_READ_MSGS,
  SET_NOTIFICATION_READ_MSGS,
  SET_NOTIFICATION_UN_READ_COUNT,
  SET_NOTIFICATION_UN_SEEN_COUNT,
  SET_NEXT_UNREAD_NOTIFICATIONS_URL,
  SET_NEXT_READ_NOTIFICATIONS_URL,
  SET_FETCHED_UNREAD_NOTIFICATIONS_DATA,
  SET_FETCHED_READ_NOTIFICATIONS_DATA,
} from "../../stores/global_store/GlobalActions"

import { apiReadNotifications, apiUnReadNotifications, apiMarkAllNotificationsAsRead, apiMarkAllNotificationsAsSeen } from "./_notification_apis"

import Style from "./styles.module.scss"

const NotificationList = (props) => {
  const [globalState, setGlobalStore] = useContext(GlobalContext)
  const [classRoomState, setClassRoomStore] = useContext(ClassroomContext)
  const [showUnReadLoader, setShowUnReadLoader] = useState(false)
  const [showReadLoader, setShowReadLoader] = useState(false)

  const fetchedReadNotifications = globalState.notifications.fetchedReadNotificationsData
  const setFetchedReadNotifications = (value) => {
    setGlobalStore({ type: SET_FETCHED_READ_NOTIFICATIONS_DATA, payload: value })
  }
  const fetchedUnReadNotifications = globalState.notifications.fetchedUnReadNotificationsData
  const setFetchedUnReadNotifications = (value) => {
    setGlobalStore({ type: SET_FETCHED_UNREAD_NOTIFICATIONS_DATA, payload: value })
  }
  const nextUnReadNotificationsUrl = globalState.notifications.nextUnReadNotificationsUrl
  const setNextUnReadNotificationsUrl = (value) => {
    setGlobalStore({ type: SET_NEXT_UNREAD_NOTIFICATIONS_URL, payload: value })
  }
  const nextReadNotificationsUrl = globalState.notifications.nextReadNotificationsUrl
  const setNextReadNotificationsUrl = (value) => {
    setGlobalStore({ type: SET_NEXT_READ_NOTIFICATIONS_URL, payload: value })
  }

  const [disabelMarkAllAsRead, setDisableMArkAllAsRead] = useState(false)

  let unReadNotifications = globalState.notifications.unReadMessages
  let readNotifications = globalState.notifications.readMessages
  const training_id = classRoomState.training_id

  useEffect(() => {
    if (fetchedUnReadNotifications && globalState?.notifications?.unSeencount > 0) {
      markAllAsSeen()
    }
  }, [fetchedUnReadNotifications, globalState?.notifications?.unSeencount])

  useEffect(() => {
    if (!fetchedUnReadNotifications) {
      getUnReadNotifications()
    }
  }, [fetchedUnReadNotifications])

  useEffect(() => {
    if (!fetchedReadNotifications) {
      getReadNotifications()
    }
  }, [fetchedReadNotifications])

  const getUnReadNotifications = async () => {
    let res = await apiUnReadNotifications(training_id, props.notficationType)
    if (res.success) {
      setFetchedUnReadNotifications(true)
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_MSGS, payload: res.data.results })
      setNextUnReadNotificationsUrl(res.data.next)
    }
  }

  const getReadNotifications = async () => {
    let res = await apiReadNotifications(training_id, props.notficationType)
    if (res.success) {
      setFetchedReadNotifications(true)
      setGlobalStore({ type: SET_NOTIFICATION_READ_MSGS, payload: res.data.results })
      setNextReadNotificationsUrl(res.data.next)
    }
  }

  const fetchNextNotificationsData = async (url, type) => {
    if (type === "read") {
      setShowReadLoader(true)
    } else {
      setShowUnReadLoader(true)
    }
    const res = await getRequest(url)
    if (res.success) {
      if (type === "read") {
        setNextReadNotificationsUrl(res.data.next)
        let copy_read = [...readNotifications, ...res.data.results]
        setGlobalStore({ type: SET_NOTIFICATION_READ_MSGS, payload: copy_read })
        setShowReadLoader(false)
      } else {
        setNextUnReadNotificationsUrl(res.data.next)
        let copy_unread = [...unReadNotifications, ...res.data.results]
        setGlobalStore({ type: SET_NOTIFICATION_UN_READ_MSGS, payload: copy_unread })
        setShowUnReadLoader(false)
      }
    } else {
      // TODO: add global error handling
    }
  }

  const markAllAsRead = async () => {
    setDisableMArkAllAsRead(true)
    let res = await apiMarkAllNotificationsAsRead(training_id, props.notficationType)
    if (res.success) {
      setDisableMArkAllAsRead(false)
      let copy_read = [...unReadNotifications, ...readNotifications]
      setGlobalStore({ type: SET_NOTIFICATION_READ_MSGS, payload: copy_read })
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_MSGS, payload: [] })
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_COUNT, payload: 0 })
    }
  }

  const markAllAsSeen = async () => {
    let res = await apiMarkAllNotificationsAsSeen(training_id, props.notficationType)
    if (res.success) {
      setGlobalStore({ type: SET_NOTIFICATION_UN_SEEN_COUNT, payload: 0 })
    }
  }

  return (
    <>
      <div className={`mb-2 d-flex justify-content-between turient-header-text-color bold-500 fs-16px`}>
        <div>Notifications</div>
        {unReadNotifications.length > 0 && (
          <div
            className={`underline pointer`}
            onClick={() => {
              if (!disabelMarkAllAsRead) {
                markAllAsRead()
              }
            }}
          >
            Mark as all read
          </div>
        )}
      </div>
      <div className={`d-flex flex-column overflow-y-scroll ${Style.notifications_max_height}`}>
        {!fetchedUnReadNotifications ? (
          <div className="d-flex w-100 justify-center text-center text-white">
            <Loader class={`text-white`} />
          </div>
        ) : (
          (unReadNotifications.length > 0 || nextUnReadNotificationsUrl) && (
            <div id="un-read" className="d-flex flex-column">
              {unReadNotifications.map((notification, index) => {
                return <NotificationListItem key={`unread_${index}`} type={`unread`} data={notification} index={index} />
              })}
              {nextUnReadNotificationsUrl && (
                <div
                  className={`d-flex justify-content-center underline pointer text-white fs-12px pb-2`}
                  onClick={() => {
                    if (!showUnReadLoader) {
                      fetchNextNotificationsData(nextUnReadNotificationsUrl, "unread")
                    }
                  }}
                >
                  {showUnReadLoader ? <Loader margin={`0`} /> : `Show more`}
                </div>
              )}
            </div>
          )
        )}

        {!fetchedReadNotifications ? (
          <div className="d-flex w-100 justify-center text-center text-white mt-2">
            <Loader class={`text-white`} />
          </div>
        ) : (
          (readNotifications.length > 0 || nextReadNotificationsUrl) && (
            <div id="read" className="d-flex flex-column">
              <p className={`fs-14px mb-1 opacity-60 turient-header-text-color`}>Older Notifications</p>
              {readNotifications.map((notification, index) => {
                return <NotificationListItem key={`read_${index}`} type={`read`} data={notification} index={index} />
              })}
              {nextReadNotificationsUrl && (
                <div
                  className={`d-flex justify-content-center underline pointer text-white fs-12px pb-2`}
                  onClick={() => {
                    if (!showReadLoader) {
                      fetchNextNotificationsData(nextReadNotificationsUrl, "read")
                    }
                  }}
                >
                  {showReadLoader ? <Loader margin={`0`} /> : `Show more`}
                </div>
              )}
            </div>
          )
        )}
        {fetchedUnReadNotifications && readNotifications.length + unReadNotifications.length === 0 && (
          <div className="d-flex flex-column turient-header-text-color fs-14px bold-500 w-100 ">
            <p className="mx-auto">No notifications till now</p>
          </div>
        )}
      </div>
    </>
  )
}

export default NotificationList
