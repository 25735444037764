import React from "react"

import Dropdown from "react-bootstrap/Dropdown"
import Button from "react-bootstrap/Button"

import authClient, { Auth } from "../../../../services/auth"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"

import user from "./../../../../images/svgs/user.svg"

import Styles from "./style.module.scss"

// !definition of component
/**
 *
 * @description --> Displays user profile and logout option
 * @returns User Profile Button for dashboard
 */
// ! component

const UserProfile = () => {
  const auth = new Auth()

  function showSignout() {
    if (auth.isAuthenticated()) {
      return (
        <Button variant={"none"} onClick={() => authClient.signOut()} className={`p-0 w-100`}>
          Logout
        </Button>
      )
    } else {
      return ""
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="none" id="dropdown-basic" className={`shadow-none py-0 caret-none ${Styles.user_profile_button}`}>
        <img className={`ml-0 pointer mb-0 border_on_hover circle bg_light_navy ${Styles.user_profile_button_image}`} alt="user details" src={user} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`bg-card ${Styles.user_profile_menu}`}>
        <Dropdown.Item className={`px-0 text-center ${Styles.user_profile_non_clickable_item}`}>
          <FontAwesomeIcon icon={faUser} size="lg" />
        </Dropdown.Item>
        <Dropdown.Item className={`px-0 text-center ${Styles.user_profile_non_clickable_item}`}>&nbsp;&nbsp;{auth.GetEmail()}&nbsp;&nbsp;</Dropdown.Item>
        <Dropdown.Item className={`d-flex px-0 ${Styles.user_profile_clickable_item}`}>&nbsp;&nbsp;{showSignout()}&nbsp;&nbsp;</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserProfile
