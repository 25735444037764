import React, { createContext, useEffect, useReducer } from "react"
import { getRequest } from "../../services/rest_service"
import { SET_APP_DATA } from "./GlobalActions"
import { setMuralData, clearMuralData } from "../../components/mural/config"
import authClient from "../../services/auth"
import GlobalReducer from "./GlobalReducer"

const initalState = {
  notificationData: null,
  appData: { data: null, loader: false, showModal: false },
  notifications: {
    unReadcount: 0,
    unSeencount: 0,
    unReadMessages: [],
    readMessages: [],
    openNotificationModalAgain: false,
    nextUnReadNotificationsUrl: null,
    nextReadNotificationsUrl: null,
    fetchedUnReadNotificationsData: false,
    fetchedReadNotificationsData: false,
  },
  showNotificationModal: false,
  activeNotificationReplyingData: {},
}

const GlobalStore = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initalState)

  useEffect(() => {
    fetchAppData()
  }, [])

  async function fetchAppData() {
    try {
      dispatch({ type: SET_APP_DATA, payload: { loader: true } })
      let company_id = localStorage.getItem("company_id")
      if (authClient.isAuthenticated() && company_id) {
        let response = await getRequest(`/company/integrations/${company_id}/all_apps/`)
        if (response.success) {
          dispatch({ type: SET_APP_DATA, payload: { data: response.data, loader: false } })
        } else {
          // Recursive call if api fails, in case company_id is not present (happens when user logins)
          fetchAppData()
        }
      } else {
        throw "Not fetching apps as user is not logged in"
      }
    } catch (error) {
      dispatch({ type: SET_APP_DATA, payload: { data: {}, loader: false } })
      console.log(error)
    }
  }

  return <GlobalContext.Provider value={[state, dispatch]}>{children}</GlobalContext.Provider>
}

export const GlobalContext = createContext()
export default GlobalStore
