import React, { useContext } from "react"
import PropTypes from "prop-types"
import But from "../custom/Button"
import Image from "../custom/Image"
import Style from "./styles.module.scss"
import { notificationType } from "./constants"
import notification_message from "../../images/svgs/notification_message.svg"

import { apiMarkNotificationAsRead } from "./_notification_apis"
import TimeDifference from "../utilities/timeDifference"

import { GlobalContext } from "../../stores/global_store/GlobalStore"
import {
  SET_NOTIFICATION_MODAL,
  SET_NOTIFICATION_UN_READ_MSGS,
  SET_NOTIFICATION_UN_READ_COUNT,
  SET_NOTIFICATION_READ_MSGS,
  SET_ACTIVE_NOTIFICATION_REPLY_DATA,
  SET_OPEN_NOTIFICATION_MODAL_AGAIN,
} from "../../stores/global_store/GlobalActions"
import { ClassroomContext } from "../../stores/classroom_store/ClassroomStore"
import { SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION, SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION } from "../../stores/classroom_store/ClassroomActions"
import { QuestionContext } from "../../stores/question_store/QuestionStore"
import { SET_SHOW_QUESTION_MODAL } from "../../stores/question_store/QuestionActions"
import { useAssessmentMural } from "../../stores/assessment_mural_store/AssessmentMuralStore"
import { setNewMessageFlag } from "../../stores/assessment_mural_store/AssessmentMuralActions"

const NotificationListItem = (props) => {
  const itemData = props.data
  const [globalState, setGlobalStore] = useContext(GlobalContext)
  const [classState, setClassStore] = useContext(ClassroomContext)
  const [questionStore, questionDispatch] = useContext(QuestionContext)
  const [muralState, assessmentMuralDispatch] = useAssessmentMural()
  const training_id = classState.training_id || itemData?.training

  let unReadNotifications = globalState.notifications.unReadMessages
  let readNotifications = globalState.notifications.readMessages

  const markNotificationAsRead = async (notificationId) => {
    let res = await apiMarkNotificationAsRead(notificationId)
    if (res.success) {
      //move unread to read manually
      let index = unReadNotifications.findIndex((notification) => notification.id === notificationId)
      let notificationData = unReadNotifications[index]
      let unreadCopy = [...unReadNotifications]
      unreadCopy.splice(index, 1)
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_MSGS, payload: unreadCopy })
      let readCopy = [...readNotifications]
      readCopy.unshift(notificationData)
      setGlobalStore({ type: SET_NOTIFICATION_READ_MSGS, payload: readCopy })
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_COUNT, payload: globalState?.notifications?.unReadcount - 1 })
    } else {
      console.error("error", res.error)
    }
  }

  function onClickFunctionalityOfNotification(type) {
    let url = ""
    switch (type) {
      case "br_extend_time_request":
        setGlobalStore({ type: SET_ACTIVE_NOTIFICATION_REPLY_DATA, payload: itemData })
        setClassStore({ type: SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION, payload: true })
        markNotificationAsRead(itemData.id)
        break
      case "br_seek_support_reply":
        setGlobalStore({ type: SET_ACTIVE_NOTIFICATION_REPLY_DATA, payload: itemData })
        setClassStore({ type: SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION, payload: true })
        markNotificationAsRead(itemData.id)
        break
      case "reply_to_question":
        setGlobalStore({ type: SET_OPEN_NOTIFICATION_MODAL_AGAIN, payload: true })
        setGlobalStore({ type: SET_NOTIFICATION_MODAL, payload: false })
        let modalData = { type: "question", title: "All Questions", data: itemData.notification_data }
        questionDispatch({ type: SET_SHOW_QUESTION_MODAL, payload: modalData })
        props.type !== "read" && markNotificationAsRead(itemData.id)
        break
      case "view_suggested_topic":
        url = `/app/trainings/training-content/${training_id}?suggested_topics=true`
        window.open(url, "_blank")
        markNotificationAsRead(itemData.id)
        break
      case "open_mural_assessment":
        setGlobalStore({ type: SET_ACTIVE_NOTIFICATION_REPLY_DATA, payload: itemData })
        url = `/main/class-dashboard/${itemData?.notification_data?.default_course_template_id}/${itemData.training}?open_mural_assessment=true&student_id=${itemData.notification_data.sent_by.id}&mural_assessment_id=${itemData.notification_data.student_assessment_mural}`
        window.open(url, "_blank")
        markNotificationAsRead(itemData.id)
        const updatedFlags = { ...muralState.newMessageFlag }
        delete updatedFlags[itemData.notification_data.student_assessment_mural]
        assessmentMuralDispatch(setNewMessageFlag(updatedFlags))
        break
      case "mural_assessment_status_changed":
        setGlobalStore({ type: SET_ACTIVE_NOTIFICATION_REPLY_DATA, payload: itemData })
        url = `/main/class-dashboard/${itemData?.notification_data?.default_course_template_id}/${itemData.training}?open_mural_assessment=true&student_id=${itemData.notification_data.student_id}&mural_assessment_id=${itemData.notification_data.id}`
        window.open(url, "_blank")
        markNotificationAsRead(itemData.id)
        break
      case 3:
        break
    }
  }

  const enableClickFunctionalityForReadNotifications =
    props.type === "read" &&
    ["view_suggested_topic", "reply_to_question", "mural_assessment_status_changed", "open_mural_assessment"].includes(itemData?.notification_data?.actions[0])

  return (
    <div
      key={props.index}
      className={`px-2 py-1 br-9px mb-2 bg-2 turient-header-text-color ${
        props.type === "read" ? (enableClickFunctionalityForReadNotifications ? "opacity-60 pointer" : "opacity-60") : ""
      }`}
      onClick={() => {
        enableClickFunctionalityForReadNotifications &&
          onClickFunctionalityOfNotification(notificationType[itemData?.notification_data?.actions[0]]?.onclickType)
      }}
    >
      <div className={`my-1`}>
        <div className={`d-flex`}>
          <Image
            src={
              notificationType[itemData?.notification_data?.actions[0]]?.icon
                ? notificationType[itemData?.notification_data?.actions[0]]?.icon
                : notification_message
            }
            className={`mt-1 ${Style.notify_img} mr-2`}
          />
          <div className="d-flex flex-column">
            <p className="turient-header-text-color mb-0 fs-14px bold-500">{itemData.message}</p>
            {itemData?.label?.length > 0 && (
              <p
                className="turient-header-text-color mb-1 mt-1 fs-13px opacity-60"
                dangerouslySetInnerHTML={{
                  __html: `<span>${itemData.label}</span>`,
                }}
              />
            )}
          </div>
          <div className={`fs-12px opacity-60 ml-auto`}>{TimeDifference.getHowMuchTimeAgo(itemData.created_at)}</div>
        </div>
        {props.type === "unread" && (
          <div className="ml-4 mt-1 d-flex justify-content-between">
            {notificationType[itemData?.notification_data?.actions[0]]?.btn_name.length > 0 && (
              <But
                className={`${Style.notification_btn} px-3`}
                onClick={() => {
                  onClickFunctionalityOfNotification(notificationType[itemData?.notification_data?.actions[0]]?.onclickType)
                }}
              >
                {notificationType[itemData?.notification_data?.actions[0]]?.btn_name}
              </But>
            )}
            <But
              className={`${Style.notification_btn} px-3 ml-auto`}
              onClick={() => {
                markNotificationAsRead(itemData.id)
                if (props.priority) {
                  props.ignorePriorityNotification(itemData.id)
                }
              }}
            >
              Ignore
            </But>
          </div>
        )}
      </div>
    </div>
  )
}

NotificationListItem.propTypes = {
  data: PropTypes.object.isRequired, //Data of Notification item to be rendered
  index: PropTypes.number.isRequired, //Index of item
  type: PropTypes.string.isRequired, //If it is unread or read
}

export default NotificationListItem
