class DisplayDate {
  displayDate = (date) => {
    let result_date = formatDate.utcDateTime(date, "date")
    if (result_date < 10) {
      result_date = `0${result_date}`
    }
    return result_date
  }

  displayMonth = (month) => {
    let month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let result_month = formatDate.utcDateTime(month, "month")
    result_month = month_names[result_month]
    return result_month
  }

  displayFullYear(year) {
    let result_year = formatDate.utcDateTime(year, "year")
    return result_year
  }

  utcDateTime = (date_time, type) => {
    let result_date_time = new Date(date_time)
    let utc = result_date_time.getTime() + result_date_time.getTimezoneOffset() * 60000
    result_date_time = new Date(utc + 3600000 * +5.5)
    if (type === "hour") {
      result_date_time = result_date_time.getHours()
    } else if (type === "time") {
      let result_hour = result_date_time.getHours()
      let result_minute = result_date_time.getMinutes()
      let time_type = "AM"
      if (result_hour >= 12) {
        time_type = "PM"
      }
      if (result_hour > 12) {
        result_hour = result_hour - 12
      }
      if (result_hour < 10) {
        result_hour = `0${result_hour}`
      }
      if (result_minute < 10) {
        result_minute = `0${result_minute}`
      }
      result_date_time = `${result_hour}:${result_minute} ${time_type}`
    } else if (type === "date") {
      result_date_time = result_date_time.getDate()
    } else if (type === "day") {
      result_date_time = result_date_time.getDay()
    } else if (type === "month") {
      result_date_time = result_date_time.getMonth()
    } else if (type === "year") {
      result_date_time = result_date_time.getFullYear()
    }
    return result_date_time
  }

  displayTrainingDate = (training, type) => {
    let training_start_date_full_year = formatDate.displayFullYear(training.start_date)
    let training_end_date_full_year = formatDate.displayFullYear(training.end_date)
    let training_start_date = `${formatDate.displayDate(training.start_date)} ${formatDate.displayMonth(training.start_date)}`
    let training_date_string = `${formatDate.displayDate(training.start_date)} ${formatDate.displayMonth(training.start_date)}`
    let training_end_date = `${formatDate.displayDate(training.end_date)} ${formatDate.displayMonth(training.end_date)}`
    if (training_start_date !== training_end_date) {
      training_date_string = training_date_string + " - " + training_end_date
    }
    let training_date = ""
    if (type === "fixed_training") {
      if (training_start_date_full_year === training_end_date_full_year) {
        training_date = `${formatDate.displayDate(training.start_date)} ${formatDate.displayMonth(training.start_date)} - ${formatDate.displayDate(
          training.end_date
        )} ${formatDate.displayMonth(training.end_date)}, ${training_start_date_full_year}`
      } else {
        training_date = `${formatDate.displayDate(training.start_date)} ${formatDate.displayMonth(
          training.start_date
        )}, ${training_start_date_full_year} - ${formatDate.displayDate(training.end_date)} ${formatDate.displayMonth(
          training.end_date
        )}, ${training_end_date_full_year}`
      }
    } else {
      if (training_start_date_full_year === training_end_date_full_year) {
        training_date = `${training_date_string}, ${training_start_date_full_year}`
      } else {
        training_date = `${formatDate.displayDate(training.start_date)} ${formatDate.displayMonth(
          training.start_date
        )}, ${training_start_date_full_year} - ${formatDate.displayDate(training.end_date)} ${formatDate.displayMonth(
          training.end_date
        )}, ${training_end_date_full_year}`
      }
    }
    return training_date
  }

  displayTimeDetail = (training) => {
    let training_time = `${formatDate.utcDateTime(training.start_date, "time")} - ${formatDate.utcDateTime(training.end_date, "time")} (IST)`
    return training_time
  }

  timeFormat = (date) => {
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours
    minutes = minutes < 10 ? "0" + minutes : minutes
    var strTime = hours + ":" + minutes + " " + ampm
    return strTime
  }

  toLocalDateFormat = (date_time) => {
    const date = new Date(date_time)
    return date.toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    })
  }

  toLocalDateFormatDateAndTime = (date_time) => {
    const date = new Date(date_time)
    return date.toLocaleString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
  }
}

const formatDate = new DisplayDate()

export default formatDate
