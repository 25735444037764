import { SET_COMPANY_DETAILS } from "../../sidebar_store/SidebarActions"
import {
  SET_CURRENT_NAV_TAB,
  SET_SELECTED_RESOURCE_TYPE,
  SET_OPEN_UPLOAD_MODAL,
  SET_REFETCH_RESOURCES,
  SET_ALL_RESOURCES,
  SET_DRAGGED_ITEM,
  SET_NAV_AND_RESOURCE_TYPE,
  SET_SHOW_POLL_MODAL,
  SET_SHOW_POPQUIZ_MODAL,
  SET_DATA_TO_CREATE_RESOURCE,
  SET_DISABLED_RESOURCES,
} from "./classroomSidebarActions"

const ClassroomSidebarReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_NAV_TAB:
      return {
        ...state,
        currentNavTab: action.payload,
      }
    case SET_SELECTED_RESOURCE_TYPE:
      return {
        ...state,
        currentSelectedResourcesType: action.payload,
      }
    case SET_NAV_AND_RESOURCE_TYPE:
      return {
        ...state,
        currentNavTab: action.payload.navTab,
        currentSelectedResourcesType: action.payload.resourceType,
      }
    case SET_OPEN_UPLOAD_MODAL:
      return {
        ...state,
        openUploadModal: action.payload,
      }
    case SET_REFETCH_RESOURCES:
      return {
        ...state,
        refetchResources: action.payload,
      }
    case SET_ALL_RESOURCES:
      return {
        ...state,
        allResources: action.payload,
      }
    case SET_DRAGGED_ITEM:
      return {
        ...state,
        draggedItem: action.payload,
      }
    case SET_SHOW_POLL_MODAL:
      return { ...state, showPollModal: action.payload }
    case SET_SHOW_POPQUIZ_MODAL:
      return { ...state, showPopquizModal: action.payload }
    case SET_DATA_TO_CREATE_RESOURCE:
      return { ...state, dataToCreateResource: action.payload }
    case SET_DISABLED_RESOURCES:
      return { ...state, disabledResources: action.payload }
    case SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload,
      }
    default:
      return state
  }
}

export default ClassroomSidebarReducer
