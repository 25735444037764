export const SET_NOTIFICATION_DATA = "SET_NOTIFICATION_DATA"
export const SET_APP_DATA = "SET_APP_DATA"
export const SET_NOTIFICATION_UN_READ_COUNT = "SET_NOTIFICATION_UN_READ_COUNT"
export const SET_NOTIFICATION_UN_SEEN_COUNT = "SET_NOTIFICATION_UN_SEEN_COUNT"
export const SET_NOTIFICATION_UN_READ_MSGS = "SET_NOTIFICATION_UN_READ_MSGS"
export const SET_NOTIFICATION_READ_MSGS = "SET_NOTIFICATION_READ_MSGS"
export const SET_NOTIFICATION_MODAL = "SET_NOTIFICATION_MODAL"
export const SET_ACTIVE_NOTIFICATION_REPLY_DATA = "SET_AVTIVE_NOTIFICATION_REPLY_DATA"
export const SET_OPEN_NOTIFICATION_MODAL_AGAIN = "SET_OPEN_NOTIFICATION_MODAL_AGAIN"
export const SET_NEXT_UNREAD_NOTIFICATIONS_URL = "SET_NEXT_UNREAD_NOTIFICATIONS_URL"
export const SET_NEXT_READ_NOTIFICATIONS_URL = "SET_NEXT_READ_NOTIFICATIONS_URL"
export const SET_FETCHED_UNREAD_NOTIFICATIONS_DATA = "SET_FETCHED_UNREAD_NOTIFICATIONS_DATA"
export const SET_FETCHED_READ_NOTIFICATIONS_DATA = "SET_FETCHED_READ_NOTIFICATIONS_DATA"
