import { postRequest } from "../../services/rest_service"
import { ZOOM_AUTHORIZATION_KEY, ZOOM_BR_GROUP_SYNC } from "./constants"

export const setZoomAuth = () => {
  localStorage.setItem(ZOOM_AUTHORIZATION_KEY, true)
}

export const resetZoomAuth = () => {
  localStorage.removeItem(ZOOM_AUTHORIZATION_KEY)
}

export const setZoomBrGroupSync = () => {
  localStorage.setItem(ZOOM_BR_GROUP_SYNC, true)
}

export const resetZoomBrGroupSync = () => {
  localStorage.removeItem(ZOOM_BR_GROUP_SYNC)
}

export const syncZoomBrGroup = async (training_id) => {
  debugger
  if (localStorage.getItem(ZOOM_BR_GROUP_SYNC)) {
    let res = await postRequest("/zoom/zoom_br_group_sync/", { training_id: training_id })
    if (res.success) {
      resetZoomBrGroupSync()
    }
  }
}
