class UtcDateTime {
  utcDateTime = (date_time, type) => {
    let month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let short_month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let day_names = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    let short_day_names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    let result_date_time = date_time === "today" ? new Date() : new Date(date_time)
    let utc = result_date_time.getTime() + result_date_time.getTimezoneOffset() * 60000
    result_date_time = new Date(utc + 3600000 * +5.5)
    if (type === "hour") {
      result_date_time = result_date_time.getHours()
    } else if (type === "minute") {
      result_date_time = result_date_time.getMinutes()
    } else if (type === "time" || type === "time_without_am_pm") {
      let result_hour = result_date_time.getHours(),
        result_minute = result_date_time.getMinutes()
      let time_type = "AM"
      if (result_hour >= 12) {
        time_type = "PM"
      }
      if (result_hour > 12) {
        result_hour = result_hour - 12
      }
      if (result_hour < 10) {
        result_hour = `0${result_hour}`
      }
      if (result_minute < 10) {
        result_minute = `0${result_minute}`
      }
      result_date_time = type === "time" ? `${result_hour}:${result_minute} ${time_type}` : `${result_hour}:${result_minute}`
    } else if (type === "time_in_sec") {
      result_date_time = result_date_time.getTime()
    } else if (type === "date") {
      result_date_time = result_date_time.getDate()
      if (result_date_time < 10) {
        result_date_time = `0${result_date_time}`
      } else {
        result_date_time = `${result_date_time}`
      }
    } else if (type === "date_with_prefix") {
      let int_result_date_time = result_date_time.getDate()
      result_date_time = result_date_time.getDate()
      if (result_date_time < 10) {
        result_date_time = `0${result_date_time}`
      }
      if (int_result_date_time === 1 || int_result_date_time === 21 || int_result_date_time === 31) {
        result_date_time = `${result_date_time}st`
      } else if (int_result_date_time === 2 || int_result_date_time === 22) {
        result_date_time = `${result_date_time}nd`
      } else if (int_result_date_time === 3 || int_result_date_time === 23) {
        result_date_time = `${result_date_time}rd`
      } else {
        result_date_time = `${result_date_time}th`
      }
    } else if (type === "day") {
      result_date_time = result_date_time.getDay()
    } else if (type === "day_name") {
      result_date_time = day_names[result_date_time.getDay()]
    } else if (type === "short_day_name") {
      result_date_time = short_day_names[result_date_time.getDay()]
    } else if (type === "month") {
      result_date_time = result_date_time.getMonth()
    } else if (type === "month_name") {
      result_date_time = month_names[result_date_time.getMonth()]
    } else if (type === "short_month_name") {
      result_date_time = short_month_names[result_date_time.getMonth()]
    } else if (type === "year") {
      result_date_time = result_date_time.getFullYear()
    } else if (type === "two_digit_year") {
      result_date_time = result_date_time.getFullYear()
      result_date_time = result_date_time.toString().substr(2)
    } else if (type === "display_dt") {
      result_date_time = result_date_time.toLocaleString()
    }
    return result_date_time
  }

  displayTrainingDate = (start_date, end_date, type) => {
    let training_start_date_full_year = utcDateTime.utcDateTime(start_date, "year")
    let training_end_date_full_year = utcDateTime.utcDateTime(end_date, "year")
    let training_start_date = `${utcDateTime.utcDateTime(start_date, "date_with_prefix")} ${utcDateTime.utcDateTime(start_date, "short_month_name")}`
    let training_date_string = `${utcDateTime.utcDateTime(start_date, "date_with_prefix")} ${utcDateTime.utcDateTime(start_date, "short_month_name")}`
    let training_end_date = `${utcDateTime.utcDateTime(end_date, "date_with_prefix")} ${utcDateTime.utcDateTime(end_date, "short_month_name")}`
    if (training_start_date !== training_end_date) {
      training_date_string = `${training_date_string} - ${training_end_date}`
    }
    let training_date = ""
    if (type === "fixed_training") {
      if (training_start_date_full_year === training_end_date_full_year) {
        training_date = `${utcDateTime.utcDateTime(start_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          start_date,
          "short_month_name"
        )} - ${utcDateTime.utcDateTime(end_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          end_date,
          "short_month_name"
        )}, ${training_start_date_full_year}`
      } else {
        training_date = `${utcDateTime.utcDateTime(start_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          start_date,
          "short_month_name"
        )}, ${training_start_date_full_year} - ${utcDateTime.utcDateTime(end_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          end_date,
          "short_month_name"
        )}, ${training_end_date_full_year}`
      }
    } else {
      if (training_start_date_full_year === training_end_date_full_year) {
        training_date = `${training_date_string}, ${training_start_date_full_year}`
      } else {
        training_date = `${utcDateTime.utcDateTime(start_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          start_date,
          "short_month_name"
        )}, ${training_start_date_full_year} - ${utcDateTime.utcDateTime(end_date, "date_with_prefix")} ${utcDateTime.utcDateTime(
          end_date,
          "short_month_name"
        )}, ${training_end_date_full_year}`
      }
    }
    return training_date
  }

  displayTimeDetail = (start_date, end_date) => {
    let training_time = `${utcDateTime.utcDateTime(start_date, "time")} - ${utcDateTime.utcDateTime(end_date, "time")} (IST)`
    return training_time
  }
}

const utcDateTime = new UtcDateTime()

export default utcDateTime
