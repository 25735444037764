import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"
import Image from "../Image"
import CheckCircle from "../../../images/svgs/check-circle-notify.svg"
import ExclamationTriangle from "../../../images/svgs/exclamation-triangle.svg"

// !definition of component
/**
 *
 * @param props --> data, setdata
 * @description -->  data => {type: 'error/success', title: 'Notification title', description: 'Notification description'}, setData => update State function
 * @returns Delete Modal
 */
// ! component

const Notification = (props) => {
  const iconType = {
    error: { icon: ExclamationTriangle, color: "#AA5746" },
    success: { icon: CheckCircle, color: "#1699A2" },
    info: { icon: CheckCircle, color: "#1699A2" },
  }
  const titleClass = { error: Style.title_error, success: Style.title_success, info: Style.title_info }
  const border = { error: Style.border_error, success: Style.border_success, info: Style.border_info }

  //Notification Handler
  useEffect(() => {
    if (props.data !== null) {
      const timerID = setTimeout(
        () => {
          props.setData(null)
        },
        props.data?.timeoutSeconds ? props.data?.timeoutSeconds : 4000
      )

      return () => {
        timerID && clearTimeout(timerID)
      }
    }
  }, [props])

  if (props?.data && props?.data?.title) {
    return (
      <div style={props?.style} className={`d-flex flex-column ${Style.box} ${props?.data?.type ? border[props?.data?.type] : Style.border}`}>
        <div className={"d-flex align-items-center w-100"}>
          {props?.data?.type && (
            <div>
              <Image className={`${Style.img_success_failure}`} src={iconType[props?.data?.type]?.icon} />
            </div>
          )}
          <div className={`${props?.data?.type ? titleClass[props?.data?.type] : Style.title}`}>
            <div>{props?.data?.title}</div>
          </div>
        </div>
        {props?.data?.description && (
          <div className={"d-flex align-items-center w-100"}>
            {props?.data?.type && (
              <div>
                <Image className={`${Style.img_success_failure} ${Style.visibility_hidden}`} src={iconType[props?.data?.type]?.icon} />
              </div>
            )}
            <div className={`${Style.text}`}>{props?.data?.description}</div>
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

Notification.defaultProps = {
  data: null,
}

Notification.propTypes = {
  data: PropTypes.object, //Main Data of the Notification, its format is {type: ..., title: ..., }
  setData: PropTypes.func.isRequired, //funtion of alter the data
}

export default Notification
