import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../stores/sidebar_store/SidebarActions"
import Btn from "../custom/Button"

import Styles from "./desktop-left-navigation.module.scss"

/**
 * Component for showing subitems in sidebar content.
 * @param {object} props  --> link_url, link_name, type
 * @returns {React.ReactElement} SidebarContentSubItem component.
 */
const SidebarContentSubItem = (props) => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  let url = window.location.pathname
  let hash = window.location.hash
  // These links are disabled for now.
  let disable_link = ["My Notifications", "My Calendar", "My Profile", "My Rewards"].includes(props.link_name)
  return (
    <div
      className="m-2"
      onClick={() => {
        dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
      }}
    >
      {props.type === "button" ? (
        <Link to={props.link_url}>
          <Btn className={`${Styles.link_button} border_on_hover mt-2`}>{props.link_name}</Btn>
        </Link>
      ) : (
        <Link
          to={props.link_url}
          className={`${url + hash === props.link_url ? Styles.link_border_bottom : ""} ${disable_link ? "disabled-div" : ""} borderbottom_on_hover`}
        >
          {props.link_name}
        </Link>
      )}
    </div>
  )
}

SidebarContentSubItem.propTypes = {
  //Link url.
  link_url: PropTypes.string.isRequired,

  //Link name to show.
  link_name: PropTypes.string.isRequired,

  //To show as a link or button. Default is link.
  type: PropTypes.string,
}

export default SidebarContentSubItem
