import React, { useContext } from "react"

import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import { SET_NOTIFICATION_MODAL } from "../../../stores/global_store/GlobalActions"

import QuestionStore from "../../../stores/question_store/QuestionStore"
import NotificationBell from "./notification_bell"
import NotificationModal from "../../notification/modal"

export default function NotificatioBellAndModal(props) {
  const [globalState, setGlobalStore] = useContext(GlobalContext)

  const showNotification = globalState.showNotificationModal
  const setShowNotification = (val) => {
    setGlobalStore({ type: SET_NOTIFICATION_MODAL, payload: val })
  }

  if (props.notficationType === "0") {
    return (
      <>
        <NotificationBell circle={props.circle} notficationType={props.notficationType} trainingId={props.trainingId} />
        {showNotification && (
          <NotificationModal
            show={showNotification}
            hide={() => setShowNotification(false)}
            notficationType={props.notficationType}
            trainingId={props.trainingId}
          />
        )}
      </>
    )
  } else {
    return (
      <React.Fragment>
        <NotificationBell circle={props.circle} notficationType={props.notficationType} trainingId={props.trainingId} />
        {showNotification && (
          <NotificationModal
            show={showNotification}
            hide={() => setShowNotification(false)}
            notficationType={props.notficationType}
            trainingId={props.trainingId}
          />
        )}
      </React.Fragment>
    )
  }
}
