import authClient from "./auth"
import axios from "axios"

const axiosClient = axios.create()

const BASE_URL = process.env.GATSBY_REACT_APP_API_HOST

const HTTP_RE = /^((http|https|ftp):\/\/)/

const success_response = (response) => {
  return {
    data: response.data ? response.data : {},
    status: response.status,
    success: true,
  }
}

const error_response = (error) => {
  if (error?.response?.data?.detail === "Invalid token.") {
    authClient.clearLocalStorageItems()
    return {}
  } else {
    console.log(error)
    return {
      data: error?.response?.data ? error.response.data : {},
      status: error.response.status,
      success: false,
      msg: error.response.data.msg
        ? error.response.data.msg
        : error.response.data.error
        ? error.response.data.error
        : error.response.data.detail
        ? error.response.data.detail
        : "Something went wrong",
    }
  }
}

const set_authorization_header = (authrized) => {
  if (authrized) {
    const token = localStorage.getItem("student_authenticity_token")
    if (token) {
      return {
        Authorization: "Token " + token,
      }
    } else {
      authClient.signOut()
    }
  }
  return {}
}

const set_valid_url = (URL) => {
  if (!HTTP_RE.test(URL)) {
    return `${BASE_URL}${URL}`
  } else {
    return URL.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL)
  }
}

export const getRequest = async (url, authrized = true) => {
  const header = set_authorization_header(authrized)
  try {
    const response = await axiosClient
      .get(set_valid_url(url), {
        headers: header,
      })
      .then((response) => response)
    return success_response(response)
  } catch (error) {
    return error_response(error)
  }
}

export const postRequest = async (url, payload, authrized = true) => {
  const header = set_authorization_header(authrized)
  try {
    const response = await axiosClient
      .post(set_valid_url(url), payload, {
        headers: header,
      })
      .then((response) => response)
    return success_response(response)
  } catch (error) {
    return error_response(error)
  }
}

export const putRequest = async (url, payload, authrized = true) => {
  const header = set_authorization_header(authrized)
  try {
    const response = await axiosClient
      .put(set_valid_url(url), payload, {
        headers: header,
      })
      .then((response) => response)
    return success_response(response)
  } catch (error) {
    return error_response(error)
  }
}

export const deleteRequest = async (url, body, authrized = true) => {
  const header = set_authorization_header(authrized)
  try {
    const response = await axiosClient
      .delete(set_valid_url(url), {
        headers: header,
        body: body,
      })
      .then((response) => response)
    return success_response(response)
  } catch (error) {
    return error_response(error)
  }
}
