import { SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE, SET_OPEN_UPLOAD_MODAL, SET_REFETCH_RESOURCES, SET_COMPANY_DETAILS } from "./SidebarActions"

const SidebarReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_NAV_TAB:
      return {
        ...state,
        currentNavTab: action.payload,
      }
    case SET_SELECTED_RESOURCE_TYPE:
      return {
        ...state,
        currentSelectedResourcesType: action.payload,
      }
    case SET_OPEN_UPLOAD_MODAL:
      return {
        ...state,
        openUploadModal: action.payload,
      }
    case SET_REFETCH_RESOURCES:
      return {
        ...state,
        refetchResources: action.payload,
      }
    case SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload,
      }
    default:
      return state
  }
}

export default SidebarReducer
