import video from "../../images/svgs/video.svg"
import link from "../../images/svgs/link.svg"
import turientimage from "../../images/svgs/turientimage.svg"
import blog from "../../images/svgs/blog.svg"
import quizImg from "../../images/svgs/quiz.svg"
import doc from "../../images/svgs/doc.svg"
import excel from "../../images/svgs/excel.svg"
import ppt from "../../images/svgs/ppt.svg"
import pdf from "../../images/svgs/pdf.svg"
import poll from "../../images/svgs/poll.svg"
import popQuiz from "../../images/svgs/popquiz.svg"
import checklist from "../../images/svgs/checklist.svg"
import casestudy from "../../images/svgs/casestudy.svg"
import mtf from "../../images/svgs/whiteMatchingIcon.svg"

export const getResourceIcon = (resource_type, resource) => {
  let logo = video
  if (resource_type === "Blogs") {
    logo = blog
  } else if (resource_type === "Links") {
    logo = link
  } else if (resource_type === "Quizzes") {
    logo = quizImg
  } else if (resource_type === "Images") {
    // if image is available use it as icon otherwise default icon for images.
    logo = resource?.avatar || (resource?.resource_data ? resource?.resource_data?.avatar : turientimage)
  } else if (resource_type === "Polls") {
    logo = poll
  } else if (resource_type === "Pop Quizzes") {
    logo = popQuiz
  } else if (resource_type === "Checklists") {
    logo = checklist
  } else if (resource_type === "Case Studies") {
    logo = casestudy
  } else if (resource_type === "MTFs") {
    logo = mtf
  } else if (resource_type === "Documents") {
    let fileType = resource?.resource_data?.file_type || resource?.file_type
    if (fileType == 2 || fileType == 5) {
      logo = doc
    } else if (fileType == 3 || fileType == 7) {
      logo = excel
    } else if (fileType == 4 || fileType == 6) {
      logo = ppt
    } else if (fileType == 1) {
      logo = pdf
    }
  }
  return logo
}

export const getAddResourceButtonText = (resource_type) => {
  let button_text = "Upload Content"
  if (resource_type === "Polls") {
    button_text = "Create Poll"
  } else if (resource_type === "Pop Quizzes") {
    button_text = "Create Pop Quiz"
  } else if (resource_type === "Videos") {
    button_text = "Upload Video"
  } else if (resource_type === "Links") {
    button_text = "Upload Link"
  } else if (resource_type === "Quizzes") {
    button_text = "Create Quiz"
  } else if (resource_type === "Images") {
    button_text = "Upload File"
  } else if (resource_type === "Blogs") {
    button_text = "Create Blog"
  } else if (resource_type === "Documents") {
    button_text = "Upload Document"
  } else if (resource_type === "Checklists") {
    button_text = "Create Checklist"
  } else if (resource_type === "Case Studies") {
    button_text = "Create Case Study"
  } else if (resource_type === "MTFs") {
    button_text = "Create MTF"
  }
  return button_text
}

export const getFileTypeForDoc = (doc_type) => {
  let fileType = "all"
  switch (doc_type) {
    case "PDF":
      fileType = "1"
      break
    case "DOC":
      fileType = "doc"
      break
    case "EXCEL":
      fileType = "xls"
      break
    case "PPT":
      fileType = "ppt"
      break
    default:
      break
  }
  return fileType
}
