import React, { useEffect, createContext } from "react"

//default value passed to create context
export const LogsContext = createContext()

// Make sure to put LogsProvider higher up in the component tree than any consumers.
const LogsProvider = ({ children, link }) => {
  useEffect(() => {
    // Capture Error's and Keep logs here
    window.onerror = function (message, url, lineNumber) {
      console.trace(`*** ${process.env.GATSBY_REACT_APP_API_HOST} ***`, `\t *** ${message} ***`, `\t *** ${lineNumber} ***`, `\t *** ${url} ***`)
      return true
    }
  }, [])

  return <LogsContext.Provider value={null}>{children}</LogsContext.Provider>
}

LogsContext.propTypes = {}

export default LogsProvider
