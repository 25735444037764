import {
  SET_TRAINING_ID,
  SET_TEMPLATE_ID,
  SET_ACTIVE_SESSION,
  SET_TRIGGERED_RESOURCE_WINDOW_STATE,
  SET_ACTIVE_ACTIVITY_DATA,
  SHOW_TRIGGERED_RESOURCE,
  SHOW_ACTIVE_ACTIVITY,
  SET_OPENED_OVERLAY_SECTION,
  VIEW_MODERATION_RESOURCE,
  SET_COMMON_RESOURCE_DRAG_ENDED,
  SET_SHARED_RESOURCE_DRAG_ENDED,
  SET_BREAKOUT_RESOURCE_DRAG_ENDED,
  SET_TRAINING_BREAK,
  SET_DISCUSSION_BOARD,
  SET_ACTIVE_SECTION,
  SET_ACTIVE_BREAKOUT_SESSION,
  SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL,
  SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL,
  SET_BREAKOUT_GROUP_IDS,
  SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION,
  SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION,
  SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL,
} from "./ClassroomActions"

const ClassroomReducer = (state, action) => {
  switch (action.type) {
    case SET_TRAINING_ID:
      return { ...state, training_id: action.payload }
    case SET_TEMPLATE_ID:
      return { ...state, template_id: action.payload }
    case SET_TRIGGERED_RESOURCE_WINDOW_STATE:
      return { ...state, triggeredResourceWindowState: action.payload }
    case SET_ACTIVE_ACTIVITY_DATA:
      return { ...state, activeActivityData: action.payload }
    case SHOW_TRIGGERED_RESOURCE:
      return { ...state, showTriggerResource: action.payload }
    case SHOW_ACTIVE_ACTIVITY:
      return { ...state, showActiveActivity: action.payload }
    case SET_ACTIVE_SESSION:
      return { ...state, activeSession: action.payload }
    case SET_OPENED_OVERLAY_SECTION:
      return { ...state, openedOverlaySection: action.payload }
    case VIEW_MODERATION_RESOURCE:
      return { ...state, viewModerationResource: action.payload }
    case SET_COMMON_RESOURCE_DRAG_ENDED:
      return { ...state, commonResourceDragEnded: action.payload }
    case SET_SHARED_RESOURCE_DRAG_ENDED:
      return { ...state, sharedResourceDragEnded: action.payload }
    case SET_BREAKOUT_RESOURCE_DRAG_ENDED:
      return { ...state, breakoutResourceDragEnded: action.payload }
    case SET_TRAINING_BREAK:
      return { ...state, trainingBreak: action.payload }
    case SET_ACTIVE_SECTION:
      return { ...state, activeSection: action.payload }
    case SET_DISCUSSION_BOARD:
      return { ...state, discussionBoard: action.payload }
    case SET_ACTIVE_BREAKOUT_SESSION:
      return { ...state, activeBreakoutSession: action.payload }
    case SET_SHOW_BREAKOUT_SESSION_EDIT_DURATION_MODAL:
      return { ...state, showBreakoutSessionEditDurationModal: action.payload }
    case SET_SHOW_BREAKOUT_SESSION_EXTEND_DURATION_MODAL:
      return { ...state, showBreakoutSessionExtendDurationModal: action.payload }
    case SET_BREAKOUT_GROUP_IDS:
      return { ...state, breakoutGroupsIds: action.payload }
    case SET_SHOW_EXTEND_TIME_MODAL_FROM_NOTIFICATION:
      return { ...state, showExtendTimeModalFormNotifcation: action.payload }
    case SET_SHOW_SEEK_REPLY_MODAL_FROM_NOTIFICATION:
      return { ...state, showSeekReplyModalFormNotification: action.payload }
    case SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL:
      return { ...state, priorityClassroomNotifcationModal: action.payload }
    default:
      return state
  }
}

export default ClassroomReducer
