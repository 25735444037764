import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"

import Helmet from "react-helmet"

import { isBrowser } from "../services/developer_mode"
import authClient, { Auth } from "../services/auth"
import { GlobalContext } from "../stores/global_store/GlobalStore"
import QuestionStore from "../stores/question_store/QuestionStore"
import { SET_NOTIFICATION_DATA } from "../stores/global_store/GlobalActions"
import Sidebar from "./Sidebar/sidebar"
import TechnicalNetworkError from "./network-technical-error"
import Notification from "./custom/Notification"
import LayoutHeader from "./layoutHeader"
import GtmScript from "./gtmScript"

import UserSocketConsumer from "../stores/socket/user_socket/userSocketConsumer"

import "./layout.scss"

const PAGES_WITH_DIFFERENT_SIDEBAR = [
  "mural",
  "courses/course-certification",
  "case-study/edit",
  "course-content",
  "training-content",
  "template-content",
  "signupsucess",
  "class-room",
  "classroom-dashboard",
  "live-class",
  "signup-success",
  "app/reset-password-otp",
  "app/zoom-test",
]

const HEADER_NOT_ALLOEWD_PAGES = [
  "mural",
  "courses/course-certification",
  "case-study/edit",
  "course-content",
  "training-content",
  "template-content",
  "signupsucess",
  "class-room",
  "classroom-dashboard",
  "signup-success",
  "main/live-class/",
  "main/class-dashboard/",
  "app/password-reset",
  "app/zoom-test",
]

const Layout = ({ location, children }) => {
  const [globalState, dispatch] = useContext(GlobalContext)
  const IS_SIDEBAR_ALLOWED = isSidebarAllowed()
  const IS_LAYOUT_HEADER_ALLOWED = isLayoutHeader()

  const USER_ID = isBrowser() && window.localStorage.getItem("student_id")

  const auth = new Auth()
  // If current url is in PAGES_WITH_DIFFERENT_SIDEBAR then default sidebar is not required.
  function isSidebarAllowed() {
    let allow_sidebar = false
    if (isBrowser() && !location?.pathname?.includes("/app/password-reset")) {
      console.log("1")
      let url_index = PAGES_WITH_DIFFERENT_SIDEBAR.findIndex((url) => {
        if (location?.pathname?.includes(url)) {
          console.log("2")
          return true
        }
      })
      allow_sidebar = url_index > -1 ? false : true
      if (location?.pathname?.includes("zoom-test")) {
        allow_sidebar = false
      }
    }
    return allow_sidebar
  }

  function isLayoutHeader() {
    let allow_header = false
    if (isBrowser()) {
      let url_index = HEADER_NOT_ALLOEWD_PAGES.findIndex((url) => {
        if (location?.pathname?.includes(url)) {
          return true
        }
      })
      allow_header = url_index > -1 ? false : true
      if (location?.pathname?.includes("zoom-test")) {
        allow_header = false
      }
    }
    return allow_header
  }

  const showSidebar = () => {
    if (auth.isAuthenticated() && IS_SIDEBAR_ALLOWED) {
      return <Sidebar location={location}></Sidebar>
    }
  }

  const removeError = () => {
    if (document.getElementById("fixed-error-div")) {
      document.getElementById("fixed-error-div").style.display = "none"
    }
  }

  return (
    <UserSocketConsumer link={USER_ID ? `/ws/user-websocket/${USER_ID}/` : ``}>
      {process.env.GATSBY_REACT_APP_ENVIRONMENT === "PRODUCTION" ? <GtmScript /> : null}
      <Helmet>
        <script
          type="text/javascript"
          src="https://leanpitch.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7ebe1243"
        ></script>
        <script type="text/javascript">
          {`
            window.ATL_JQ_PAGE_PROPS = {
              triggerFunction: function (showCollectorDialog) {
                // Find the feedback button by its id
                const feedbackButton = document.getElementById("reportAnIssue")

                if (feedbackButton) {
                  // Attach a click event listener to the feedback button
                  feedbackButton.addEventListener("click", function (e) {
                    e.preventDefault()
                    showCollectorDialog()
                  })
                }
              },
              fieldValues: {
                recordWebInfo: "1", // field Name
                recordWebInfoConsent: ["1"], // field Id
              },
            }`}
        </script>
      </Helmet>
      <div>
        {/* Below error div will be removed later once dependency for this is removed from all pages */}
        <div className="position-relative">
          <div className={`fixed-alert-box text-dark d-none align-items-center position-fixed layout-error-div`} id="fixed-error-div">
            <div className="pr-2 position-relative float-left top-15px" id="fixed-error-img"></div>
            <div>
              <div id="fixed-error-head"></div>
              <button id="fixed-error-remove" onClick={removeError} className="d-none float-right circle close-btn-style">
                <span className="text-black position-relative">X</span>
              </button>
              <div id="fixed-error-content"></div>
            </div>
          </div>
        </div>
        <TechnicalNetworkError />
        {showSidebar()}
        {globalState.notificationData && globalState.notificationData?.title && (
          <Notification
            data={globalState.notificationData}
            setData={() =>
              dispatch({
                type: SET_NOTIFICATION_DATA,
                payload: null,
              })
            }
          />
        )}
        <main className={`layout-width-with-sidebar layout-main-div`}>
          <div id="reportAnIssue" className="position-fixed bg-F5F5F5 fs-12px text-444 px-2 py-1 br-2000px bottom-0 right-0 zIndex-10000 d-none">
            Report Issue
          </div>
          {IS_LAYOUT_HEADER_ALLOWED && authClient.isAuthenticated() && (
            <QuestionStore>
              <LayoutHeader location={location} />
            </QuestionStore>
          )}
          {children}
        </main>
      </div>
    </UserSocketConsumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
