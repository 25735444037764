import React from "react"
import PropTypes from "prop-types"
import Style from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> id, type , disabled, className, style and onClick function
 * @description -->  type (small, medium, round), disabled => boolean value to disable the click on button, style=> inline css
 * @description -->  Note : Dont give inline css unless needed
 * @returns Button component
 */
// ! component
const Btn = (props) => {
  const typeClass = {
    small: Style.small,
    medium: Style.medium,
    round: Style.round,
  }

  return (
    <div
      onClick={(e) => {
        if (!props?.disabled) {
          props?.onClick && props.onClick(e)
        }
      }}
      id={props.id ? props.id : ""}
      className={`${Style.btn} ${typeClass[props.type ? props.type : "small"]} ${props.disabled ? Style.disable : ""} ${
        props.className ? props.className : ""
      }`}
      style={props?.style}
    >
      {props?.children}
    </div>
  )
}
Btn.propTypes = {
  id: PropTypes.number, //ID attribute of the component
  type: PropTypes.string, //Type of Button (small, medium, round)
  disabled: PropTypes.bool, //Disable look & effect of the button
  style: PropTypes.object, //inline css
  className: PropTypes.string, //Classes for the button
  children: PropTypes.any, //Elements this component encapsulates
}
export default Btn
