import React, { useEffect, useRef, useContext } from "react"
import { navigate } from "gatsby"

import { DragDropContext } from "react-beautiful-dnd"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { SET_COMPANY_DETAILS, SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE } from "../../stores/sidebar_store/SidebarActions"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import SidebarContent from "./sidebarContent"
import { LeftNavItems } from "./constants"
import Image from "../custom/Image"

import PollIcon from "../../images/svgs/Pollicon.svg"
import HomeIcon from "../../images/svgs/Homeicon.svg"
import ClassIcon from "../../images/svgs/Classicon.svg"
import CoursesIcon from "../../images/svgs/Courseicon.svg"
import ResourcesIcon from "../../images/svgs/Resourcesicon.svg"
import SettingsIcon from "../../images/svgs/Settingsicon.svg"
import LeadboardIcon from "../../images/svgs/Leadboardicon.svg"
import StatsIcon from "../../images/svgs/statistics.svg"
import TurientLogo from "../../images/logo.png"
import ReportIssueIcon from "../../images/report_an_issue.svg"

import StylesA from "../template-resources/classroom-left-nav.module.scss"
import Styles from "./desktop-left-navigation.module.scss"

const LeftNavTooltips = ["Home", "Classes", "Courses", "Resources", "Assessment Toolkit", "Company", "Insights", "Settings", "Report An Issue"]
const LeftNavIcons = [HomeIcon, ClassIcon, CoursesIcon, ResourcesIcon, PollIcon, LeadboardIcon, StatsIcon, SettingsIcon, ReportIssueIcon]

/**
 * Component for the left navigation menu.
 * @returns {React.ReactElement} Sidebar component.
 */
const Sidebar = () => {
  const [sidebarState, dispatch] = useContext(SidebarContext)
  const myRef = useRef()

  useEffect(() => {
    if (localStorage.getItem("company_logo") || localStorage.getItem("company_name")) {
      dispatch({
        type: SET_COMPANY_DETAILS,
        payload: {
          logo: localStorage.getItem("company_logo"),
          name: localStorage.getItem("company_name"),
        },
      })
    }
    // To close sidebar on outside click.
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    }
  }

  const handleNavClick = (item, index) => {
    if (item === "Turient") {
      dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
      navigate("/app/dashboard")
    } else {
      if (sidebarState.currentNavTab === item) {
        dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
        if (item === "Resource" || item === "Poll") {
          dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "" })
        }
      } else {
        dispatch({ type: SET_CURRENT_NAV_TAB, payload: item })
        if (item === "Resource") {
          dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Videos" })
        } else if (item === "Poll") {
          dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Case Studies" })
        } else if (item === "Company") {
          localStorage.removeItem("activeTab")
          dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Company" })
          navigate("/app/school-setup")
        } else if (item === "Insights") {
          dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: "Insights" })
          dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
          navigate("/app/insights")
        }
      }
    }
  }

  const displayHomeIcon = () => {
    return (
      <React.Fragment key={"turient-home-icon"}>
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip placement="right" className="classroom-tooltip" id={`"tooltip-turient"`}>
              {sidebarState?.companyDetails?.name ? sidebarState?.companyDetails?.name : "Turient"}
            </Tooltip>
          }
        >
          <div
            id={"nav-turient"}
            onClick={() => {
              handleNavClick("Turient", 0)
            }}
            className={`${Styles.turient_logo_div} py-2 pointer text-center  d-flex-centered mx-auto my-2 mt-2`}
          >
            <Image
              src={sidebarState?.companyDetails?.logo && sidebarState?.companyDetails?.logo !== "null" ? sidebarState?.companyDetails?.logo : TurientLogo}
              alt={sidebarState?.companyDetails?.name ? sidebarState?.companyDetails?.name : "Turient"}
              className={`${Styles.turient_logo_div} object-fit-contain`}
            />
          </div>
        </OverlayTrigger>
      </React.Fragment>
    )
  }

  const displayNavTabs = () => {
    return LeftNavItems.map((item, index) => {
      let tab_icon = LeftNavIcons[index]
      return (
        <>
          {index === 0 && displayHomeIcon()}
          <React.Fragment key={`menu-${index.toString()}`}>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip placement="right" className="classroom-tooltip" id={`"tooltip-${item.name}"`}>
                  {LeftNavTooltips[index]}
                </Tooltip>
              }
            >
              <div
                id={"nav" + index}
                onClick={() => {
                  if (item.name !== "Report An Issue") {
                    handleNavClick(item.name, index)
                  } else {
                    document.getElementById("reportAnIssue").click()
                  }
                }}
                className={`${
                  item.name === "Turient" ? Styles.turient_logo_div : item.name === sidebarState.currentNavTab ? Styles.active_nav_tab : Styles.inactive_nav_tab
                } py-2 pointer text-center my-2 ${item.class ?? ""}`}
              >
                <Image src={tab_icon} alt={item.name} className={`${item.name == "Turient" ? Styles.turient_logo : "my-auto"} object-fit-contain h-26px`} />
              </div>
            </OverlayTrigger>
          </React.Fragment>
        </>
      )
    })
  }

  const displaySidebar = () => {
    return (
      <div ref={myRef} className={`${Styles.sidebar_main_div}`}>
        <div className={`${Styles.nav_icons_section} d-flex`}>{displayNavTabs()}</div>
        <div
          className={`${sidebarState.currentNavTab ? `${StylesA.left_nav_opened}` : `${StylesA.left_nav_collapsed}`} ${Styles.sidebar_content_section} mb-3`}
          id={`mySidepanel`}
        >
          <SidebarContent />
        </div>
      </div>
    )
  }

  return <DragDropContext>{displaySidebar()}</DragDropContext>
}

export default Sidebar
