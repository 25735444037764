import { PERMISSION } from "../constants/LocalStorageConstant"
import LocalStorage from "./LocalStorageAccess"

export const HasCreatePermission = (module) => {
  let permission_data = LocalStorage.getData(PERMISSION)
  if (permission_data?.permission?.[module]?.create) {
    return true
  }
  return false
}

export const HasEditPermission = (module) => {
  let permission_data = LocalStorage.getData(PERMISSION)
  if (permission_data?.permission?.[module]?.write) {
    return true
  }
  return false
}

export const HasDeletePermission = (module) => {
  let permission_data = LocalStorage.getData(PERMISSION)
  if (permission_data?.permission?.[module]?.delete) {
    return true
  }
  return false
}

export const HasReadPermission = (module) => {
  let permission_data = LocalStorage.getData(PERMISSION)
  if (permission_data?.permission?.[module]?.read) {
    return true
  }
  return false
}
