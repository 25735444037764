import React from "react"
import AppIntegration from "./app-integration"

// !definition of component
/**
 * @returns Setting Menu Sidebar
 */
// ! component

const SettingsMenu = () => {
  return (
    <div className={`d-flex flex-column px-2 py-3`}>
      <AppIntegration />
    </div>
  )
}

export default SettingsMenu
