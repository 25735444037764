import {
  ClassroomComponent,
  QuestionStoreComponent,
  SessionBoard,
  TriggeredResource,
  VideoCardControl,
  GoogleDocsTriggered,
  CaseStudyModeration,
  LiveDiscussionBoard,
  LessonBoardComponent,
  ClassroomParticipantsComponent,
  ClassroomPollComponent,
  CheckListModeration,
  ActiveBreakoutSession,
  QuizModerationComponent,
  ParticipantListComponent,
  CaseStudyComponent,
  NotificationModal,
  MuralChatWindow,
  SyncThirdPartyApp,
  SyncMuralApp,
  BreakoutGroupsComponent,
} from "../../constants/componentConstant"

/*
In this file only Component and Websocket mapping data is expected. Key will be websocket event_type and
value will be where we are going to utilize message recieved from Web Socket
*/

const ComponentMapping = {
  question_created: [QuestionStoreComponent],
  question_deleted: [QuestionStoreComponent],
  question_vote_changed: [QuestionStoreComponent],
  question_reply_created: [QuestionStoreComponent],
  question_reply_deleted: [QuestionStoreComponent],
  question_reply_changed: [QuestionStoreComponent],
  reply_vote_changed: [QuestionStoreComponent],
  question_topic_updated: [QuestionStoreComponent],
  request_more_time: [ClassroomComponent, ActiveBreakoutSession],
  topic_feedback_updated: [LessonBoardComponent],
  lesson_board: [SessionBoard, LessonBoardComponent],
  video_control: [VideoCardControl],
  close_triggered_resource: [TriggeredResource, SessionBoard],
  document_presentation_slide_to: [GoogleDocsTriggered],
  document_disable_edit: [GoogleDocsTriggered],
  document_enable_edit: [GoogleDocsTriggered],
  fetch_leader_board: [CaseStudyModeration, CheckListModeration, QuizModerationComponent],
  live_discussion_board: [LiveDiscussionBoard, SessionBoard],
  user_badges: [ClassroomParticipantsComponent],
  fetch_poll: [ClassroomPollComponent],
  match_the_following: [ActiveBreakoutSession],
  seek: [ActiveBreakoutSession],
  end_breakout_rooms: [ActiveBreakoutSession],
  breakout_session_status: [ActiveBreakoutSession, LessonBoardComponent],
  start_breakout_rooms: [ActiveBreakoutSession],
  show_online: [ParticipantListComponent],
  case_study_report_card: [CaseStudyComponent],
  quiz_report_card_created: [ActiveBreakoutSession],
  class_ended: [ClassroomComponent],
  notification: [NotificationModal],
  quiz_answer_updated: [ActiveBreakoutSession],
  update_checklist_answer: [ActiveBreakoutSession],
  "mural-assessment": [MuralChatWindow],
  third_party_app_sync: [SyncThirdPartyApp],
  mural_app_sync: [SyncMuralApp],
  "topic-moved": [LessonBoardComponent],
  training_break: [ClassroomComponent],
  extend_time: [ActiveBreakoutSession],
  end_active_poll: [ClassroomPollComponent],
  show_poll_results: [ClassroomPollComponent],
  breakout_group_activity: [BreakoutGroupsComponent],
  user_password_reset: [],
}

export default ComponentMapping
