import React, { useEffect, useState, useRef, createContext, useContext } from "react"

import PropTypes from "prop-types"

import { WsConnect } from "../../services/socket"
import { NetworkContext } from "../network/networkProvider.js"
import SocketEventFormat from "./socketEventFormat"

//default value passed to create context :- ready, value, send
export const WebsocketContext = createContext(false, null, () => {})

// Make sure to put WebsocketProvider higher up in the component tree than any consumers.
const WebsocketProvider = ({ children, link }) => {
  const [isOffline, setIsOffline] = useContext(NetworkContext)

  const [isReady, setIsReady] = useState(false)
  const [val, setVal] = useState({})

  const ws = useRef(null)

  const wsConnection = (wsLink) => {
    // Web Socket connection initialization
    ws.current = WsConnect(wsLink)

    // Set status true after successfull of  web socket connection
    ws.current.onopen = () => {
      setIsReady(true)
      setIsOffline(false)
    }

    // Set status false on close of web socket connection
    ws.current.onclose = (e) => {
      if (!e.wasClean) {
        setIsOffline(true)
        setTimeout(wsConnection(wsLink), 5000)
      }
      setIsReady(false)
    }
  }

  useEffect(() => {
    if (link) {
      wsConnection(link)
      // Set on recieve of web soscket data
      ws.current.onmessage = (event) => {
        setVal(SocketEventFormat.formatMessage(event.data, link))
      }

      return () => {
        setIsReady(false)
        ws.current.close()
      }
    }
  }, [])

  const ret = [isReady, val, ws.current?.send.bind(ws.current)]

  return <WebsocketContext.Provider value={ret}>{children}</WebsocketContext.Provider>
}

WebsocketProvider.propTypes = {
  // Childeren Node Need to pass to Provider Component
  children: PropTypes.node.isRequired,
  // Link need to pass to Provider Component
  link: PropTypes.string.isRequired,
}

export default WebsocketProvider
