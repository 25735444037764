import axios from "axios"
import { navigate } from "gatsby"
import queryString from "query-string"
import { postRequest, getRequest, putRequest } from "./rest_service"
import { isBrowser } from "./developer_mode"
import Json from "../components/utilities/json"
import LocalStorage from "../utils/LocalStorageAccess"
import { PERMISSION } from "../constants/LocalStorageConstant"

const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : {}

export class Auth {
  constructor() {
    this.token = isBrowser() ? localStorage.getItem("student_authenticity_token") : ""
    this.email = isBrowser() ? localStorage.getItem("username") : ""
    this.redirection_url = isBrowser() ? localStorage.getItem("redirection_url") : ""
    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.clearLocalStorageItems = this.clearLocalStorageItems.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getProfile(env) {
    axios
      .get(process.env.REACT_APP_STUDENT_API + "32", { headers: DefaultHeader })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        return {}
      })
  }
  isAuthenticated() {
    return this.token !== undefined && this.token !== "undefined" && this.token !== null
  }

  async signIn(user) {
    let userData = user
    const response = await postRequest("/rest-auth/login/", JSON.stringify(userData), false)
    if (response.success) {
      localStorage.setItem("student_authenticity_token", response.data["key"])
      localStorage.setItem("username", userData.username)
      localStorage.setItem("login_modal", false)
      localStorage.removeItem("error")
      window.location.reload(true)
    } else {
      let error_messages = ""
      for (var key in response.data) {
        error_messages += response.data[key]
      }
      return { type: "error", title: error_messages }
    }
  }

  GetEmail() {
    return this.email
  }

  async GetKey(user) {
    let location = user.location
    let { redirection_url } = queryString.parse(location.search)
    let userData = user
    const response = await postRequest("/login/", userData, false)
    if (response.success) {
      localStorage.removeItem("successMsg")
      localStorage.setItem("student_authenticity_token", response.data["token"])
      localStorage.setItem("show_interest", true)
      localStorage.setItem("username", userData.username)
      localStorage.removeItem("error")
      localStorage.setItem("login_modal", false)
      localStorage.setItem("school_exists", response.data.school_exists)
      localStorage.setItem("organisation", Json.toString(response.data.organisation))
      localStorage.setItem("reset_first_time_password", response.data.reset_first_time_password)
      LocalStorage.setData(PERMISSION, response.data.permissions)
      if (response.data?.reset_first_time_password === true) {
        navigate(`/app/password-reset?uuid=${response.data.reset_password_confirmation_id}`)
      } else if (!response.data?.school_exists) {
        navigate("/app/signup-success")
      } else {
        this.fetchUser(redirection_url)
      }
    } else {
      let error_messages = ""
      for (var key in response.data) {
        error_messages += response.data[key]
      }
      if (error_messages === "Unable to log in with provided credentials.") {
        let notification = {
          type: "error",
          title:
            user.location.pathname === `/app/forgot-password` ? "Invalid OTP! Please try again with correct OTP." : "Please try again with correct credentials",
        }
        return notification
      } else {
        return { type: "error", title: error_messages }
      }
    }
  }

  async fetchUser(redirection_url) {
    const res = await getRequest(process.env.GATSBY_REACT_APP_FETCH_LEARNER_API)
    if (res.success) {
      if (res.data["non_field_errors"] !== undefined) {
        return ""
      } else {
        if (res.data?.mural_auth_details?.refresh_token) {
          localStorage.setItem("mural.refresh_token", res.data?.mural_auth_details?.refresh_token)
          localStorage.setItem("mural.access_token", res.data?.mural_auth_details?.access_token)
          var t = new Date()
          t.setSeconds(t.getSeconds() + 10)
          localStorage.setItem("mural.expires_in", t)
        }
        localStorage.setItem("student_id", res.data["id"])
        localStorage.setItem("student_first_name", res.data["first_name"])
        localStorage.setItem("login_value", true)

        if (res.data["has_license"] === true) {
          localStorage.setItem("license_title", res.data["license"]["title"])
        } else {
          localStorage.setItem("license_title", "Basic")
        }
        if ([false, "false"].includes(localStorage.getItem("school_exists"))) {
          window.location.href = "/app/signup-success"
        } else {
          if (res.data?.default_school?.id) {
            localStorage.setItem("company_id", res.data.default_school?.id)
            localStorage.setItem("company_name", res.data.default_school?.name)
            localStorage.setItem("company_logo", res.data.default_school?.avatar)
            if (window.innerWidth > 980) {
              window.location.href = "/app/dashboard"
            } else {
              localStorage.setItem("firstname", res.data["first_name"])
              localStorage.setItem("domain", res.data["subdomain"])
              window.location.href = "/app/signupsucess"
            }
          } else {
            let res = await getRequest(`/basic-company-data/?organisation=${Json.toJson(localStorage.getItem("organisation")).id}`)
            if (res.success) {
              if (res.data.results.length > 1) {
                navigate("/app/school-setup")
              } else {
                let response = await putRequest(`/user/user-profile/update_default_school/`, { school: res.data.results[0]?.id })
                if (response.success) {
                  localStorage.setItem("company_id", res.data.results[0]?.id)
                  localStorage.setItem("company_name", res.data.results[0]?.name)
                  localStorage.setItem("company_logo", res.data.results[0]?.avatar)
                  navigate("/app/dashboard")
                }
              }
            } else {
              console.error(res.data?.msg)
            }
          }
        }
      }
    }
  }

  async loginUsingOTP(user) {
    let location = user.location
    let { redirection_url } = queryString.parse(location.search)
    let userData = user
    const res = await putRequest("/students/otp/", userData, false)
    if (res.success) {
      if (res.data["error"] !== undefined) {
        return { type: "error", title: res.data["error"] }
      } else {
        localStorage.setItem("student_authenticity_token", res.data["token"])
        localStorage.setItem("username", userData.email)
        localStorage.setItem("login_modal", false)
        localStorage.setItem("school_exists", res.data?.school_exists)
        localStorage.setItem("organisation", Json.toString(res.data?.organisation))
        localStorage.removeItem("error")
        if (!res.data?.school_exists) {
          navigate("/app/signup-success")
        } else {
          this.fetchUser(redirection_url)
        }
      }
    } else {
      if (res) {
        let error_messages = ""
        for (var key in res.data) {
          error_messages += res.data[key]
        }
        return { type: "error", title: error_messages }
      }
    }
  }

  async generateOTP(user) {
    let location = user.location
    let { redirection_url } = queryString.parse(location.search)
    let userData = user
    const res = await postRequest("/learners/otp/", userData, false)
    if (res.success) {
      if (res.data["error"] !== undefined) {
        localStorage.setItem("errorMsg", res.data["error"])
      } else {
        localStorage.setItem("username", user.email)
        setTimeout(function () {
          navigate(`/app/login?redirection_url=${redirection_url}`)
        }, 100)
        return { type: "success", title: "We have sent an OTP to your registered email" }
      }
    } else {
      localStorage.removeItem("errorMsg")
      if (res) {
        let error_messages = ""
        for (var key in res.data) {
          error_messages += res.data[key]
        }
        return { type: "error", title: error_messages }
      }
    }
  }

  async forgotPassword(user) {
    let userData = user
    const res = await postRequest("/rest-auth/password/reset/", userData, false)
    if (res.success) {
      if (res.data["non_field_errors"] !== undefined) {
        return { type: "error", title: res.data["non_field_errors"] }
      } else {
        return { type: "success", title: "Please check your email for the password reset link." }
      }
    } else {
      if (res) {
        let error_messages = ""
        for (var key in res.data) {
          error_messages += res.data[key]
        }
        return { type: "error", title: error_messages }
      }
    }
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000
        resolve()
      })
    })
  }

  clearLocalStorageItems() {
    localStorage.removeItem("student_authenticity_token")
    localStorage.removeItem("username")
    localStorage.removeItem("student_first_name")
    localStorage.setItem("license_title", "Basic")
    localStorage.setItem("logout_value", true)
    localStorage.removeItem("company_id")
    window.location.reload(true)
  }

  async signOut() {
    // clear id token, profile, and expiration
    if (localStorage.getItem("student_authenticity_token") === undefined) {
      window.location.reload(true)
    } else {
      const res = await postRequest("/rest-auth/logout/")
      if (res.success) {
        this.idToken = null
        this.profile = null
        this.expiresAt = null
        localStorage.clear()
        localStorage.setItem("license_title", "Basic")
        localStorage.setItem("logout_value", true)
        window.location.href = "/app/login"
      } else {
        let error_messages = ""
        for (var key in res.data) {
          error_messages += res.data[key]
        }
        if (error_messages === "") {
          this.idToken = null
          this.profile = null
          this.expiresAt = null
          this.clearLocalStorageItems()
        }
        return { type: "error", title: error_messages }
      }
    }
  }
}

const authClient = new Auth()
export default authClient
