import React, { Component, useEffect, useState } from "react"

import axios from "axios"
import Loadable from "@loadable/component"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"

import SucessTick from "./../images/svgs/gray-tick.svg"

export default function Notification(props) {
  const [show, setShow] = useState(true)

  useEffect(() => {
    //console.log('in notifica')
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }, [1])

  return (
    <div className={`${show ? `d-inline-flex` : `d-none`} align-items-center w-100 position-absolute`} style={{ zIndex: "1000", top: "30%", left: "50%" }}>
      <div className={`px-3 py-2 d-inline-flex align-items-center`} style={{ backgroundColor: "#0D131F", borderRadius: "14px" }}>
        <img src={SucessTick} alt={`sucess`} className={`my-auto mr-1 pointer`} style={{ objectFit: `contain`, height: `40px` }} />
        <p className={`mb-0`} style={{ fontSize: "16px", color: "#E0E2E568" }}>
          {props.msg}
        </p>
      </div>
    </div>
  )
}
