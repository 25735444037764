import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

import Style from "./style.module.scss"

// !definition of component
/**
 *
 * @param props --> id, type , disabled, className, style and onClick function
 * @description -->  type (small, medium, round), disabled => boolean value to disable the click on button, style=> inline css
 * @description -->  Note : Dont give inline css unless needed
 * @returns Button component
 */
// ! component
const HorizontalScroll = (props) => {
  const [hoveredResourcesSection, setHoveredResourcesSection] = useState(false)
  const [showScrollRight, setShowScrollRight] = useState(false)
  const [showScrollLeft, setShowScrollLeft] = useState(false)

  function scrollRight(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({ top: 0, left: left_pos + 3 * flex_scroll_amount, behavior: "smooth" })
    }
  }

  function scrollLeft(id) {
    let ele = document.getElementById(id)
    if (ele !== null) {
      let flex_scroll_amount = ele.scrollWidth / ele.childElementCount
      let left_pos = ele.scrollLeft
      ele.scrollTo({ top: 0, left: left_pos - 3 * flex_scroll_amount, behavior: "smooth" })
    }
  }

  function displayScrollButtons(id) {
    if (id == `br-session-scrollable-${props.scrollId}`) {
      setHoveredResourcesSection(true)
    }
    let ele = document.getElementById(id)
    if (ele) {
      if (ele.scrollLeft == 0) {
        setShowScrollLeft(false)
      } else {
        setShowScrollLeft(true)
      }
      if (ele.scrollLeft + ele.offsetWidth + 2 >= ele.scrollWidth) {
        setShowScrollRight(false)
      } else {
        setShowScrollRight(true)
      }
    }
  }

  return (
    <div
      className="position-relative w-100"
      onMouseOver={() => {
        displayScrollButtons(`br-session-scrollable-${props.scrollId}`)
      }}
      onMouseOut={() => {
        setHoveredResourcesSection(false)
      }}
    >
      <div
        className={`pl-1 pr-0 pointer h-100 ${hoveredResourcesSection && showScrollRight ? `d-flex flex-column` : `d-none`}`}
        onClick={() => {
          scrollRight(`br-session-scrollable-${props.scrollId}`)
        }}
        style={{
          paddingTop: 2,
          width: "fit-content",
          borderRadius: 4,
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 5,
          backgroundImage: "linear-gradient(to right, transparent, #414f6b)",
        }}
      >
        <FontAwesomeIcon className="my-auto" icon={faChevronRight} color="#fff" />
      </div>
      <div
        className={`pr-1 pl-0 pointer h-100 ${hoveredResourcesSection && showScrollLeft ? `d-flex flex-column` : `d-none`}`}
        onClick={() => {
          scrollLeft(`br-session-scrollable-${props.scrollId}`)
        }}
        style={{
          paddingTop: 2,
          width: "fit-content",
          borderRadius: 4,
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 5,
          backgroundImage: "linear-gradient(to left, transparent,#414f6b)",
        }}
      >
        <FontAwesomeIcon className="my-auto" icon={faChevronLeft} color="#fff" />
      </div>
      <div className={`${props.className}`} id={`br-session-scrollable-${props.scrollId}`} style={{ overflowX: "scroll" }}>
        {props?.children}
      </div>
    </div>
  )
}

export default HorizontalScroll
