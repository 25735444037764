import React, { createContext, useEffect, useReducer } from "react"
import SidebarReducer from "./SidebarReducer"
import { useContext } from "react"
import { GlobalContext } from "../global_store/GlobalStore"
import { SET_COMPANY_DETAILS } from "./SidebarActions"

const initalState = {
  currentNavTab: "",
  currentSelectedResourcesType: "",
  openUploadModal: false,
  refetchResources: false,
  companyDetails: {},
}

const SidebarStore = ({ children }) => {
  const [state, dispatch] = useReducer(SidebarReducer, initalState)
  const [globalState, dispatchGlobalState] = useContext(GlobalContext)

  useEffect(() => {
    if (globalState?.appData?.data?.company) {
      dispatch({
        type: SET_COMPANY_DETAILS,
        payload: {
          logo: globalState?.appData?.data?.company?.avatar,
          name: globalState?.appData?.data?.company?.name,
        },
      })
    }
  }, [globalState?.appData?.data?.company])

  return <SidebarContext.Provider value={[state, dispatch]}>{children}</SidebarContext.Provider>
}

export const SidebarContext = createContext()
export default SidebarStore
