// In this file only Component constant value is expected.
export const ClassroomComponent = "ClassroomComponent"
export const QuestionStoreComponent = "QuestionStoreComponent"
export const SessionBoard = "SessionBoard"
export const VideoCardControl = "VideoCardControl"
export const TriggeredResource = "TriggeredResource"
export const GoogleDocsTriggered = "GoogleDocsTriggered"
export const CaseStudyModeration = "CaseStudyModeration"
export const LiveDiscussionBoard = "LiveDiscussionBoard"
export const LessonBoardComponent = "LessonBoardComponent"
export const ClassroomParticipantsComponent = "ClassroomParticipantsComponent"
export const ClassroomPollComponent = "ClassroomPollComponent"
export const CheckListModeration = "CheckListModeration"
export const ActiveBreakoutSession = "ActiveBreakoutSession"
export const QuizModerationComponent = "QuizModerationComponent"
export const ParticipantListComponent = "ParticipantListComponent"
export const CaseStudyComponent = "CaseStudyComponent"
export const NotificationModal = "NotificationModal"
export const MuralChatWindow = "MuralChatWindow"
export const SyncThirdPartyApp = "SyncThirdPartyApp"
export const SyncMuralApp = "SyncMuralApp"
export const BreakoutGroupsComponent = "BreakoutGroupsComponent"
