import {
  SET_SHOW_QUESTION_MODAL,
  SET_QUESTION_DATA,
  SET_QUESTION_NEXT_DATA,
  SET_REPLY_DATA,
  SET_REPLY_NEXT_DATA,
  SET_DRAFT_REPLY,
  SET_ALL_TOPICS,
  SET_ALL_SESSIONS,
  SET_REPLY_RESOURCES,
  SET_REPLY_NEXT_RESOURCES,
} from "./QuestionActions"

const QuestionReducer = (state, action) => {
  switch (action.type) {
    case SET_SHOW_QUESTION_MODAL:
      return {
        ...state,
        show_question_modal: action.payload,
      }
    case SET_QUESTION_DATA:
      return {
        ...state,
        question_data: action.payload,
      }
    case SET_QUESTION_NEXT_DATA:
      return {
        ...state,
        question_next_data: action.payload,
      }
    case SET_REPLY_DATA:
      return {
        ...state,
        reply_data: action.payload,
      }
    case SET_REPLY_NEXT_DATA:
      return {
        ...state,
        reply_next_data: action.payload,
      }
    case SET_DRAFT_REPLY:
      return {
        ...state,
        draft_reply: action.payload,
      }
    case SET_ALL_TOPICS:
      return {
        ...state,
        all_topics: action.payload,
      }
    case SET_ALL_SESSIONS:
      return {
        ...state,
        all_sessions: action.payload,
      }
    case SET_REPLY_RESOURCES:
      return {
        ...state,
        reply_resources: action.payload,
      }
    case SET_REPLY_NEXT_RESOURCES:
      return {
        ...state,
        reply_next_resources: action.payload,
      }
    default:
      return state
  }
}

export default QuestionReducer
