import React, { createContext, useReducer } from "react"
import ClassroomReducer from "./ClassroomReducer"

const initalState = {
  training_id: null,
  template_id: null,
  activeSession: null,
  showTriggerResource: false,
  activeActivityData: null,
  showActiveActivity: false,
  viewModerationResource: null,
  openedOverlaySection: null,
  commonResourceDragEnded: false,
  sharedResourceDragEnded: false,
  breakoutResourceDragEnded: false,
  trainingBreak: null,
  activeSection: "LessonBoard",
  discussionBoard: { active: null, modal: false, count: 0 },
  activeBreakoutSession: null,
  showBreakoutSessionEditDurationModal: false,
  showBreakoutSessionExtendDurationModal: false,
  showExtendTimeModalFormNotifcation: false,
  showSeekReplyModalFormNotification: false,
  priorityClassroomNotifcationModal: false,
  breakoutGroupsIds: [],
}

const ClassroomStore = ({ children }) => {
  const [state, dispatch] = useReducer(ClassroomReducer, initalState)
  return <ClassroomContext.Provider value={[state, dispatch]}>{children}</ClassroomContext.Provider>
}

export const ClassroomContext = createContext()
export default ClassroomStore
