import React, { useEffect, createContext, useState } from "react"

//default value passed to create context
export const NetworkContext = createContext()

// Make sure to put LogsProvider higher up in the component tree than any consumers.
const NetworkProvider = ({ children, link }) => {
  const [isOffline, setIsOffline] = useState(false)

  useEffect(() => {
    var isOnline = window.navigator.onLine
    if (!isOnline) {
      setIsOffline(true)
    }

    window.addEventListener("offline", function (e) {
      setIsOffline(true)
    })

    // Add event listener online to detect network recovery.
    window.addEventListener("online", function (e) {
      window.location.reload()
    })
  }, [])

  const ret = [isOffline, setIsOffline]

  return <NetworkContext.Provider value={ret}>{children}</NetworkContext.Provider>
}

export default NetworkProvider
