import React from "react"
import WebsocketProvider from "../socketProvider"
import UserSocketProvider from "./userSocketProvider"

// This consumer is only to wrap the component with the base websocket provider.

const UserSocketConsumer = ({ children, link }) => {
  return (
    <WebsocketProvider link={link}>
      <UserSocketProvider>{children}</UserSocketProvider>
    </WebsocketProvider>
  )
}

export default UserSocketConsumer
