import {
  SET_APP_DATA,
  SET_APP_DATA_LOADER,
  SET_NOTIFICATION_DATA,
  SET_NOTIFICATION_READ_MSGS,
  SET_NOTIFICATION_UN_READ_MSGS,
  SET_NOTIFICATION_UN_READ_COUNT,
  SET_NOTIFICATION_UN_SEEN_COUNT,
  SET_NOTIFICATION_MODAL,
  SET_ACTIVE_NOTIFICATION_REPLY_DATA,
  SET_OPEN_NOTIFICATION_MODAL_AGAIN,
  SET_NEXT_UNREAD_NOTIFICATIONS_URL,
  SET_NEXT_READ_NOTIFICATIONS_URL,
  SET_FETCHED_UNREAD_NOTIFICATIONS_DATA,
  SET_FETCHED_READ_NOTIFICATIONS_DATA,
} from "./GlobalActions"

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.payload,
      }
    case SET_APP_DATA:
      return {
        ...state,
        appData: { ...state.appData, ...action.payload },
      }
    case SET_NOTIFICATION_READ_MSGS:
      return {
        ...state,
        notifications: { ...state.notifications, readMessages: action.payload },
      }
    case SET_NOTIFICATION_UN_READ_MSGS:
      return {
        ...state,
        notifications: { ...state.notifications, unReadMessages: action.payload },
      }
    case SET_NOTIFICATION_UN_READ_COUNT:
      return {
        ...state,
        notifications: { ...state.notifications, unReadcount: action.payload },
      }
    case SET_NOTIFICATION_UN_SEEN_COUNT:
      return {
        ...state,
        notifications: { ...state.notifications, unSeencount: action.payload },
      }
    case SET_NOTIFICATION_MODAL:
      return {
        ...state,
        showNotificationModal: action.payload,
      }
    case SET_ACTIVE_NOTIFICATION_REPLY_DATA:
      return {
        ...state,
        activeNotificationReplyingData: action.payload,
      }
    case SET_OPEN_NOTIFICATION_MODAL_AGAIN:
      return {
        ...state,
        notifications: { ...state.notifications, openNotificationModalAgain: action.payload },
      }
    case SET_NEXT_UNREAD_NOTIFICATIONS_URL:
      return {
        ...state,
        notifications: { ...state.notifications, nextUnReadNotificationsUrl: action.payload },
      }
    case SET_NEXT_READ_NOTIFICATIONS_URL:
      return {
        ...state,
        notifications: { ...state.notifications, nextReadNotificationsUrl: action.payload },
      }
    case SET_FETCHED_UNREAD_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: { ...state.notifications, fetchedUnReadNotificationsData: action.payload },
      }
    case SET_FETCHED_READ_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: { ...state.notifications, fetchedReadNotificationsData: action.payload },
      }
    default:
      return state
  }
}

export default GlobalReducer
