export const LeftNavItems = [
  { name: "Home", class: "" },
  { name: "Classes", class: "" },
  { name: "Courses", class: "" },
  { name: "Resource", class: "" },
  { name: "Poll", class: "" },
  { name: "Company", class: "" },
  { name: "Insights", class: "" },
  { name: "Settings", class: "mt-auto" },
  { name: "Report An Issue", class: "" },
]

export const ResourceNavTabs = {
  Resource: ["Videos", "Blogs", "Documents", "Images", "Links"],
  Poll: ["Case Studies", "Checklists", "MTFs", "Polls", "Pop Quizzes", "Quizzes"],
}

export const ResourcesFetchUrl = {
  Videos: "basic-videos-list",
  Blogs: "basic-blogs-list",
  Documents: "basic-images-list",
  Images: "basic-images-list",
  Links: "basic-links-list",
  Quizzes: "basic-quizzes-list",
  MTFs: "match-the-followings",
  Polls: "basic-quizzes-list",
  "Pop Quizzes": "basic-quizzes-list",
  Checklists: "checklist",
  "Case Studies": "case-study",
}

export const PublishResourcesUrl = {
  Quizzes: "/app/quizzes/{id}/publish",
  MTFs: "/app/match-the-following/edit/{id}/",
  Polls: "/app/polls/{id}/edit/",
  "Pop Quizzes": "/app/pop-quizzes/{id}/edit/",
  Checklists: "/app/checklist/edit/{id}/",
  "Case Studies": "/app/case-study/edit/{id}/",
}

export const ViewAllResourcesUrl = {
  Videos: "/app/resource-dashboard/",
  Blogs: "/app/resource-dashboard/",
  Documents: "/app/resource-dashboard/",
  Images: "/app/resource-dashboard/",
  Links: "/app/resource-dashboard/",
  Quizzes: "/app/quizzes/",
  MTFs: "/app/match-the-following/",
  Polls: "/app/polls/",
  "Pop Quizzes": "/app/pop-quizzes/",
  Checklists: "/app/checklists/",
  "Case Studies": "/app/case-study/",
}

export const ResourceNameForUrl = {
  Videos: "videos",
  Blogs: "blogs",
  Documents: "files",
  Images: "files",
  Links: "links",
  Quizzes: "quizzes",
  MTFs: "match-the-following",
  Polls: "polls",
  "Pop Quizzes": "pop-quizzes",
  Checklists: "checklist",
  "Case Studies": "case-study",
}

export const DocumentTypes = ["All Documents", "PDF", "DOCS", "EXCEL", "PPT"]

export const schoolDetails = {
  schools: [
    {
      schoolName: "John Hopkins School of Design",
      buttonText: "Setup your School",
      logo: "",
    },
    {
      schoolName: "John Hopkins School of Architecture",
      buttonText: "Setup your School",
      logo: "",
    },
    {
      schoolName: "John Hopkins School of Architecture",
      buttonText: "Setup your School",
      logo: "",
    },
    {
      schoolName: "John Hopkins School of Design",
      buttonText: "Setup your School",
      logo: "",
    },
    {
      schoolName: "John Hopkins School of Architecture",
      buttonText: "Setup your School",
      logo: "",
    },
    {
      schoolName: "John Hopkins School of Architecture",
      buttonText: "Setup your School",
      logo: "",
    },
    null,
  ],
}

export const courseDetails = [
  {
    id: 0,
    description: "Course description1",
    name: "Course Name",
    tagline: "Course tagline",
    length: "26",
    status: "published",
  },
  {
    id: 1,
    description: "Course description2",
    name: "Course Name",
    tagline: "Course tagline",
    length: "26",
    status: "draft",
  },
  {
    id: 2,
    description: "Course description3",
    name: "Course Name",
    tagline: "Course tagline",
    length: "26",
    status: "published",
  },
  {
    id: 3,
    description: "Course description4",
    name: "Course Name",
    tagline: "Course tagline",
    length: "26",
    status: "draft",
  },
]

export const classesDetails = [
  {
    id: 0,
    name: "Certified Scrum Master Certification",
    trainer_name: "trainer1",
    start_date: "3rd Mar 2023",
    trainer_avatar: "https://cdn-icons-png.flaticon.com/512/3048/3048127.png",
    end_date: "7th Mar 2023",
    status: "upcoming_classes",
  },
  {
    id: 1,
    name: "Certified Scrum Master Certification",
    trainer_name: "trainer2",
    trainer_avatar: "https://cdn-icons-png.flaticon.com/512/3048/3048127.png",
    start_date: "15th Mar 2023",
    end_date: "18th Mar 2023",
    status: "past_classes",
  },
  {
    id: 2,
    name: "Certified Scrum Master ",
    trainer_name: "trainer2",
    trainer_avatar: "https://cdn-icons-png.flaticon.com/512/3048/3048127.png",
    start_date: "14th Mar 2023",
    end_date: "16th Mar 2023",
    status: "past_classes",
  },
  {
    id: 3,
    name: "Certified Scrum Master ",
    trainer_name: "trainer1",
    trainer_avatar: "https://cdn-icons-png.flaticon.com/512/3048/3048127.png",
    start_date: "19th Mar 2023",
    end_date: "22nd Mar 2023",
    status: "ongoing_classes",
  },
]

export const educatorDetails = [
  null,
  {
    id: 0,
    avatar: null,
    full_name: "ankit",
    email_id: "asdvb@fg.cokv",
    phone: "8679766786",
    designation: {
      value: 1,
      label: "Engineer",
    },
    coursePermissions: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: false,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
    classesPermission: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: true,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
  },
  {
    id: 1,
    avatar: null,
    full_name: "ankit",
    email_id: "asdvb@fg.cokv",
    phone: "8679766786",
    designation: {
      value: 1,
      label: "Engineer",
    },
    coursePermissions: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: false,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
    classesPermission: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: true,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
  },
  {
    id: 2,
    avatar: null,
    full_name: "ankit",
    email_id: "asdvb@fg.cokv",
    phone: "8679766786",
    designation: {
      value: 1,
      label: "Engineer",
    },
    coursePermissions: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: false,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
    classesPermission: [
      {
        id: 0,
        permissionFlag: true,
      },
      {
        id: 1,
        permissionFlag: true,
      },
      {
        id: 2,
        permissionFlag: true,
      },
      {
        id: 3,
        permissionFlag: true,
      },
    ],
  },
]
export const accessControlAndPermissions = [
  {
    courses: {
      create: {
        full_access: true,
      },
      edit: {
        full_access: true,
      },
      view: {
        full_access: true,
      },
      delete: {
        full_access: true,
      },
    },
    classes: {
      create: {
        full_access: true,
      },
      edit: {
        full_access: true,
      },
      view: {
        full_access: true,
      },
      delete: {
        full_access: true,
      },
    },
    students: {
      create: {
        full_access: true,
      },
      edit: {
        full_access: true,
      },
      view: {
        full_access: true,
      },
      delete: {
        full_access: true,
      },
    },
  },
]

export const resourceTypeList = {
  Videos: "video",
  Blogs: "blog",
  Documents: "turientimage",
  Images: "turientimage",
  Links: "link",
  Quizzes: "quiz",
  Polls: "quiz",
  "Pop Quizzes": "quiz",
  MTFs: "matchthefollowing",
  Checklists: "Checklist",
  "Case Studies": "casestudy",
}

export const statusList = [
  { label: "Passed", textcolor: "#2FC2CC", id: "2" },
  { label: "Failed", textcolor: "#FD7458", id: "3" },
  { label: "In Review", textcolor: "#5DADE2", id: "5" },
]
