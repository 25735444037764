import React, { useContext, useEffect, useRef } from "react"
import Image from "../../custom/Image"
import { ClassroomContext } from "../../../stores/classroom_store/ClassroomStore"
import { GlobalContext } from "../../../stores/global_store/GlobalStore"
import {
  SET_NOTIFICATION_UN_READ_COUNT,
  SET_NOTIFICATION_UN_SEEN_COUNT,
  SET_NOTIFICATION_UN_READ_MSGS,
  SET_NOTIFICATION_MODAL,
  SET_APP_DATA,
} from "../../../stores/global_store/GlobalActions"
import { SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL } from "../../../stores/classroom_store/ClassroomActions"
import { UserSocketContext } from "../../../stores/socket/user_socket/userSocketProvider"
import { PRIOIRY_NOTIFICATIONS } from "../../notification/constants"
import { getRequest } from "../../../services/rest_service"
import Btn from "../../custom/Button"
import bell from "../../../images/svgs/bell-white-icon.svg"
import bellUnRead from "../../../images/svgs/bell-yellow-unread.svg"
import bellUnSeen from "../../../images/svgs/bell-yellow-unseen.svg"
import Style from "./header.module.scss"

const NotificationBell = (props) => {
  const [classroomState, dispatch] = useContext(ClassroomContext)
  const [globalState, setGlobalStore] = useContext(GlobalContext)
  const [userWSready, userWSval, userWSsend] = useContext(UserSocketContext)

  let oldHeaderTitle = useRef(document.title)
  const training_id = classroomState.training_id

  const showNotification = globalState.showNotificationModal
  const setShowNotification = (val) => {
    setGlobalStore({ type: SET_NOTIFICATION_MODAL, payload: val })
  }

  async function fetchAppData() {
    try {
      let company_id = localStorage.getItem("company_id")

      let response = await getRequest(`/company/integrations/${company_id}/all_apps/`)
      if (response.success) {
        setGlobalStore({ type: SET_APP_DATA, payload: { data: response.data, loader: false } })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleVisibilityChange = () => {
    if (!document.hidden && document.title !== oldHeaderTitle?.current && document.title.includes("Notification") && oldHeaderTitle?.current != null) {
      document.title = oldHeaderTitle?.current
    }
  }

  useEffect(() => {
    if (!document.title.includes("Notification")) {
      oldHeaderTitle.current = document.title
    }
  }, [document.title])

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (document.hidden && globalState.notifications.unSeencount > 0) {
      document.title = `(${globalState.notifications.unSeencount}) ${globalState.notifications.unSeencount > 1 ? `Notifications` : `Notification`}`
    }
  }, [globalState.notifications.unSeencount, document.hidden])

  useEffect(() => {
    if (
      userWSval &&
      userWSval.event_type === "notification" &&
      userWSval.action === "New notification" &&
      (parseInt(training_id) === parseInt(userWSval?.data?.training) || props.notficationType === "0")
    ) {
      if (Object.keys(userWSval.data).length > 0) {
        if (props.notficationType === "0" && userWSval.data?.notification_type === 0) {
          setGlobalStore({ type: SET_NOTIFICATION_UN_READ_COUNT, payload: globalState?.notifications?.unReadcount + 1 })
          setGlobalStore({ type: SET_NOTIFICATION_UN_SEEN_COUNT, payload: globalState?.notifications?.unSeencount + 1 })
        } else if (props.notficationType === "1" && userWSval.data?.notification_type === 1) {
          if (!PRIOIRY_NOTIFICATIONS.includes(userWSval.data?.notification_data?.actions[0])) {
            setGlobalStore({ type: SET_NOTIFICATION_UN_READ_COUNT, payload: globalState?.notifications?.unReadcount + 1 })
            setGlobalStore({ type: SET_NOTIFICATION_UN_SEEN_COUNT, payload: globalState?.notifications?.unSeencount + 1 })
          }
        }

        let copy_unread = [...globalState.notifications.unReadMessages]
        copy_unread.unshift(userWSval.data)
        setGlobalStore({ type: SET_NOTIFICATION_UN_READ_MSGS, payload: copy_unread })

        //This condition is for opening priority notification modal if the noraml notification modal is not opened
        if (!showNotification && PRIOIRY_NOTIFICATIONS.includes(userWSval.data?.notification_data?.actions[0])) {
          dispatch({ type: SET_PRIORITY_CLASSROOM_NOTIFICATION_MODAL, payload: true })
        }
      }
    }
    if (userWSval?.action === "mural_app_sync") {
      fetchAppData()
    }
  }, [userWSval])

  //Fetching new notifiactions count to show next to bell icon
  useEffect(() => {
    getUnseenMessagesCount()
    getUnreadMessagesCount()
  }, [])

  async function getUnseenMessagesCount() {
    const training_param = training_id ? `training=${training_id}&` : ``
    const res = await getRequest(`/turient-notifications/unseen_count/?${training_param}notification_type=${props.notficationType}`)
    if (res.success) {
      setGlobalStore({ type: SET_NOTIFICATION_UN_SEEN_COUNT, payload: res.data.count })
    }
  }

  async function getUnreadMessagesCount() {
    const training_param = training_id ? `training=${training_id}&` : ``
    const res = await getRequest(`/turient-notifications/unread_count/?${training_param}notification_type=${props.notficationType}`)
    if (res.success) {
      setGlobalStore({ type: SET_NOTIFICATION_UN_READ_COUNT, payload: res.data.count })
    }
  }

  return (
    <Btn
      onClick={() => setShowNotification(true)}
      variant="none"
      className={`border_on_hover  text-center align-items-center justify-content-center d-inline-flex  ${
        props.circle ? `${globalState.notifications.unReadcount > 0 ? `br-36px w-auto px-2` : `circle`} mr-1 ${Style.dashbord_bell}` : `mr-3 px-2`
      } text-white ${Style.button}`}
    >
      <Image
        src={globalState.notifications.unSeencount > 0 ? bellUnSeen : globalState.notifications.unReadcount > 0 ? bellUnRead : bell}
        alt={`Notification`}
        className={`my-auto ${props.circle ? Style.circular_btn_img : Style.btn_img}`}
      />
      {globalState.notifications.unReadcount > 0 && <p className={`ml-1 fs-14px text-FFEB00 bold-500 mb-0`}>+{globalState.notifications.unReadcount}</p>}
    </Btn>
  )
}

export default NotificationBell
