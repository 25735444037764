var config = {
  authUrl: "https://app.mural.co/api/public/v1/authorization/oauth2",
  callBackPath: `${process.env.GATSBY_REACT_APP_FRONTEND}/app/mural`,
  clientId: process.env.GATSBY_REACT_APP_MURAL_CLIENT_ID,
  clientSecret: process.env.GATSBY_REACT_APP_MURAL_CLIENT_SECRET,
  scope: "murals:read murals:write rooms:read workspaces:read identity:read templates:read templates:write rooms:write",
  usePkce: true,
  refreshTokenKey: "mural.refresh_token",
  accessTokenKey: "mural.access_token",
  expireInKey: "mural.expires_in",
  baseURL: "https://app.mural.co",
}

export function setMuralData(access_token, expires_in) {
  localStorage.setItem(config.accessTokenKey, access_token)
  localStorage.setItem(config.expireInKey, expires_in)
}

export function clearMuralData() {
  localStorage.removeItem(config.accessTokenKey)
  localStorage.removeItem(config.expireInKey)
}

export default config
