import { SET_TOPIC_DATA } from "../../stores/classroom_store/lessonboard_store/LessonboardActions"
import { SET_QUESTION_DATA, SET_SHOW_QUESTION_MODAL, SET_REPLY_DATA } from "../../stores/question_store/QuestionActions"

// 1. eventData.object_id can be training_id or topic_id.
// 2. eventData.topic or eventData.topic.id can be topic id.
// 3. eventData.module is topic status (todo, doing or done)
// 4. content_type_name, content_object_name and object_id contains
// topic or training type, name and id respectively where we are attaching our question
// 5. eventData.workshop_module contains session id and type

export function question_created(eventData, data, dispatch) {
  let newQues = {
    id: eventData.id,
    title: eventData.title,
    reply_count: 0,
    updated_at: eventData.created,
    content_object_name: eventData.content_object_name,
    object_id: eventData.object_id,
    votes: { count: 0 },
    owner: eventData.owner,
    content_type_name: eventData.content_type_name,
    workshop_module: eventData.workshop_module,
  }

  let quesData = { ...data.question_data }

  function appendQuestion(key) {
    if (quesData[key]) {
      quesData[key] = [newQues, ...quesData[key]]
    }
  }

  let testStage = eventData.workshop_module === null || (eventData.workshop_module && eventData.workshop_module?.module_type === "3")

  if (testStage) {
    appendQuestion("topic_all_questions")
    appendQuestion("topic_modal_all_questions")
    appendQuestion(`topic_${eventData.object_id}`)
  }

  dispatch({ type: SET_QUESTION_DATA, payload: quesData })
}

export function question_deleted(eventData, data, dispatch) {
  let quesData = { ...data.question_data }

  function sliceQuestion(key) {
    if (quesData[key]) {
      let allIndex = quesData[key].findIndex((ques) => ques.id === eventData.id)
      if (quesData[key][allIndex]) {
        quesData[key].splice(allIndex, 1)
      }
    }
  }

  sliceQuestion("topic_all_questions")
  sliceQuestion("topic_modal_all_questions")
  sliceQuestion(`topic_${eventData.topic}`)

  dispatch({ type: SET_QUESTION_DATA, payload: quesData })

  if (data?.show_question_modal?.data?.id === eventData.id) {
    // setQuestionReply(null)
    dispatch({ type: SET_SHOW_QUESTION_MODAL, payload: null })
  }
}

export function question_vote_changed(eventData, data, dispatch) {
  let quesData = { ...data.question_data }

  function changeQuestion(key) {
    if (quesData[key]) {
      let allIndex = quesData[key].findIndex((ques) => ques.id === eventData.question)
      if (quesData[key][allIndex]) {
        quesData[key][allIndex].votes.count = eventData.likes
      }
    }
  }

  changeQuestion("topic_all_questions")
  changeQuestion("topic_modal_all_questions")
  changeQuestion(`topic_${eventData.topic}`)

  dispatch({ type: SET_QUESTION_DATA, payload: quesData })
}

//topic_feedback pending

export function question_reply_created(eventData, data, dispatch) {
  let quesData = { ...data.question_data }
  let replyData = { ...data.reply_data }
  let newReply = {
    id: eventData.id,
    title: eventData.title,
    published: true,
    owner: eventData.owner,
    updated_at: eventData.updated_at,
    votes: { count: 0 },
    resources: eventData?.resources,
  }
  if (replyData[`ques_${eventData.question.id}`]) {
    replyData[`ques_${eventData.question.id}`] = [newReply, ...replyData[`ques_${eventData.question.id}`]]
    dispatch({ type: SET_REPLY_DATA, payload: replyData })
  }

  function increaseReplyCount(key) {
    if (quesData[key]) {
      let allQuesIndex = quesData[key].findIndex((q) => q.id == eventData.question.id)
      if (quesData[key][allQuesIndex]) {
        quesData[key][allQuesIndex].reply_count += 1
      }
    }
  }

  if (eventData.module !== null) {
    increaseReplyCount(`topic_${eventData.topic.id}`)
  }

  increaseReplyCount("topic_all_questions")
  increaseReplyCount("topic_modal_all_questions")

  dispatch({ type: SET_QUESTION_DATA, payload: quesData })

  // question count in topic pending
}

export function question_reply_deleted(eventData, data, dispatch) {
  let quesData = { ...data.question_data }
  let replyData = { ...data.reply_data }

  function decreaseReplyCount(key) {
    if (quesData[key]) {
      let quesIndex = quesData[key].findIndex((q) => q.id === eventData.question)
      if (quesData[key][quesIndex]) {
        quesData[key][quesIndex].reply_count -= 1
      }
    }
  }

  if (eventData.module !== null) {
    decreaseReplyCount(`topic_${eventData.topic}`)
  }

  decreaseReplyCount("topic_all_questions")
  decreaseReplyCount("topic_modal_all_questions")

  if (replyData[`ques_${eventData.question}`]) {
    let index = replyData[`ques_${eventData.question}`].findIndex((reply) => reply.id == eventData.id)
    if (replyData[`ques_${eventData.question}`][index]) {
      replyData[`ques_${eventData.question}`].splice(index, 1)
      dispatch({ type: SET_REPLY_DATA, payload: replyData })
    }
  }
  dispatch({ type: SET_QUESTION_DATA, payload: quesData })
}

export function question_reply_changed(eventData, data, dispatch) {
  let replyData = { ...data.reply_data }
  let key = `ques_${eventData.question.id}`
  if (replyData[key]) {
    let replyIndex = replyData[key].findIndex((q) => q.id == eventData.id)
    if (replyData[key][replyIndex]) {
      replyData[key][replyIndex] = {
        ...replyData[key][replyIndex],
        title: eventData.title,
        resources: eventData?.resources,
      }
      dispatch({ type: SET_REPLY_DATA, payload: replyData })
    }
  }
  // testing pending
}

export function reply_vote_changed(eventData, data, dispatch) {
  let replyData = { ...data.reply_data }
  let key = `ques_${eventData.question}`
  if (replyData[key]) {
    let replyIndex = replyData[key].findIndex((reply) => reply.id === eventData.reply)
    if (replyData[key][replyIndex]) {
      replyData[key][replyIndex] = {
        ...replyData[key][replyIndex],
        votes: {
          ...replyData[key][replyIndex].votes,
          count: eventData.likes,
        },
      }
      dispatch({ type: SET_REPLY_DATA, payload: replyData })
    }
  }
}

export function question_topic_updated(eventData, data, dispatch) {
  if (eventData.stage_name === null || eventData.stage_name?.name === "Workshop") {
    let quesData = { ...data.question_data }
    function updateTopic(key) {
      if (quesData[key]) {
        let index = quesData[key].findIndex((ques) => ques.id == eventData.id)
        if (quesData[key][index]) {
          if (eventData.resource_type == "topic") {
            quesData[key][index].content_object_name = eventData.content_object_name
          } else {
            quesData[key][index].content_object_name = "Unassigned"
          }
          quesData[key][index].object_id = eventData.object_id
        }
      }
    }

    updateTopic(`topic_all_questions`)
    updateTopic(`topic_modal_all_questions`)
    if (eventData.prev_resource_type == "topic") {
      if (quesData[`topic_${eventData.prev_object_id}`]) {
        delete quesData[`topic_${eventData.prev_object_id}`]
      }
    }
    if (eventData.resource_type == "topic") {
      if (quesData[`topic_${eventData.object_id}`]) {
        delete quesData[`topic_${eventData.object_id}`]
      }
    }
    dispatch({ type: SET_QUESTION_DATA, payload: quesData })
  }
}

// Used in lesson board
// Question count includes total questions and unanswered questions. So these events may change the question count data.
export function question_count_update(eventData, data, dispatch) {
  if ("question_created question_deleted question_reply_created question_reply_deleted".includes(eventData.event_type)) {
    let update = eventData.module ? true : false
    let topicId = null
    if (update) {
      if (eventData?.content_type_name === "topic") {
        topicId = eventData.object_id
      } else if (eventData.event_type === "question_reply_created") {
        topicId = eventData.topic.id
      } else {
        topicId = eventData.topic
      }
    }
    if (update && topicId) {
      let topics = { ...data }
      let key = "module_" + eventData.module.replace(" ", "_")
      let topicsData = topics[key].topics
      let topicIndex = topicsData.findIndex((topic) => topic.id === topicId)
      if (topicsData[topicIndex]) {
        topicsData[topicIndex] = {
          ...topicsData[topicIndex],
          questions: eventData.questions,
        }
        let payload = { key, value: { topics: topicsData, topicNext: topics[key].topicNext } }
        dispatch({ type: SET_TOPIC_DATA, payload })
      }
    }
  }
  if (eventData.event_type === "question_topic_updated") {
    let topics = { ...data }
    function updateTopicList(isPrev = false) {
      if (eventData[`${isPrev ? "prev_" : ""}resource_type`] == "topic") {
        if (eventData[`${isPrev ? "previous_" : ""}module_name`] !== "" && eventData[`${isPrev ? "previous_" : ""}module_name`] !== null) {
          let key = "module_" + eventData[`${isPrev ? "previous_" : ""}module_name`].replace(" ", "_")
          let topicsData = topics[key].topics
          let topicIndex = topicsData.findIndex((topic) => topic.id == eventData[`${isPrev ? "prev_" : ""}object_id`])
          if (topicsData[topicIndex]) {
            topicsData[topicIndex] = {
              ...topicsData[topicIndex],
              questions: eventData[`${isPrev ? "previous_topic_" : ""}questions`],
            }
            let payload = { key, value: { topics: topicsData, topicNext: topics[key].topicNext } }
            dispatch({ type: SET_TOPIC_DATA, payload })
          }
        }
      }
    }
    updateTopicList(true)
    updateTopicList()
  }
}
