import notification_time from "../../images/svgs/notification_time.svg"
import notification_message from "../../images/svgs/notification_message.svg"

export const notificationType = {
  br_extend_time_request: {
    btn_name: "Extend Time",
    icon: notification_time,
    onclickType: "br_extend_time_request",
  },
  br_seek_support_reply: {
    btn_name: "Reply",
    icon: notification_message,
    onclickType: "br_seek_support_reply",
  },
  reply_to_question: {
    btn_name: "Reply",
    icon: notification_message,
    onclickType: "reply_to_question",
  },
  view_qna: {
    btn_name: "View",
    icon: notification_message,
    onclickType: "reply_to_question",
  },
  view_suggested_topic: {
    btn_name: "View",
    icon: notification_message,
    onclickType: "view_suggested_topic",
  },
  educator_assigned_to_training: {
    btn_name: "View Training",
    icon: notification_message,
    onclickType: "educator_assigned_to_training",
  },
  open_mural_assessment: {
    btn_name: "View Mural Assessment",
    icon: notification_message,
    onclickType: "open_mural_assessment",
  },
  mural_assessment_status_changed: {
    btn_name: "View Mural Assessment",
    icon: notification_message,
    onclickType: "mural_assessment_status_changed",
  },
}

export const PRIOIRY_NOTIFICATIONS = ["br_extend_time_request", "br_seek_support_reply"]
