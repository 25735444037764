import React from "react"
import PropTypes from "prop-types"

// !definition of component
/**
 *
 * @param props --> no props
 * @description -->  No desrcription
 * @returns Loader component
 */
// ! component
const Loader = (props) => {
  return (
    <svg
      className={`animate-spin my-auto ${props?.class}`}
      style={{ height: props?.size, width: props?.size, margin: props.margin || "0 auto" }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={`0 0 24 24`}
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  )
}
Loader.defaultProps = {
  size: 16 //Default size if size is not passed
}
Loader.propTypes = {
  class: PropTypes.string, //Class Names
  size: PropTypes.number //Size of the Loader
}

export default Loader
