import React, { useState, useEffect, useRef, useContext } from "react"
import { navigate } from "gatsby"

import Col from "react-bootstrap/Col"
import Dropdown from "react-bootstrap/Dropdown"

import { getRequest } from "../../services/rest_service"
import { isBrowser } from "../../services/developer_mode"
import Common from "../../services/common"
import { getAddResourceButtonText, getFileTypeForDoc } from "./utils"
import { ResourceNavTabs, ResourcesFetchUrl, DocumentTypes, ViewAllResourcesUrl, ResourceNameForUrl } from "./constants"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { SET_CURRENT_NAV_TAB, SET_SELECTED_RESOURCE_TYPE, SET_OPEN_UPLOAD_MODAL, SET_REFETCH_RESOURCES } from "../../stores/sidebar_store/SidebarActions"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import Loader from "../custom/Loader"
import Image from "../custom/Image"
import Btn from "../custom/Button"
import ClassRoomPoll from "../class-room-training/classroom-poll"
import ClassroomPopquiz from "../class-room-training/classroom-popquiz"
import HorizontalScroll from "../custom/HorizontalScroll"
import UploadResource from "../upload-resource"
import ResourceItem from "./resourceItem"
import ResourceSearchBar from "./resourceSearchBar"
import SidebarContentSubItem from "./sidebarContentSubItem"

import UploadIcon from "../../images/svgs/feather-upload-white.svg"
import RightArrow from "../../images/svgs/arrowdropright.svg"
import AngleDown from "../../images/svgs/down_arrow_white.svg"
import Upload from "../../images/svgs/upload.svg"

import StylesB from "../template-resources/classroom-resource-section.module.scss"
import StylesC from "../../modularscss/styles.module.scss"
import SettingsMenu from "./settings-menu"
import TurientMenu from "./TurientMenu"
import { HasCreatePermission } from "../../utils/PermissionDataAccess"
import { CLASSES, COURSES } from "../../constants/permissionsConstant"

const getItemStyle = (isDragging, draggableStyle) => ({
  borderRadius: "5px",
  color: "rgb(224, 226, 229)",
  border: "1px solid transparent",
  background: "rgb(48, 60, 84)",

  // styles we need to apply on draggables
  ...draggableStyle,
})
const ITEM_HEIGHT = 50

/**
 * Component for displaying content once left nav is opened.
 * @returns {React.ReactElement} SidebarContent component.
 */
const SidebarContent = () => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  const [searchValue, setSearchValue] = useState("")
  const [allResources, setAllResources] = useState({})
  const [allResourcesNext, setAllResourcesNext] = useState({})
  const [allResourcesFetched, setAllResourcesFetched] = useState(false)
  const [fetchingNextResources, setFetchingNextResources] = useState(false)

  const [showPollModal, setShowPollModal] = useState(false)
  const [createNewPoll, setCreateNewPoll] = useState(false)
  const [showPopquizModal, setShowPopquizModal] = useState(false)
  const [createNewPopquiz, setCreateNewPopquiz] = useState(false)
  const [showResourceModal, setShowResourceModal] = useState(false)
  const [docType, setDocType] = useState(0)
  const [uploadFileModalSection, setUploadFileModalSection] = useState("upload_files")

  const PageSize = useRef(10)

  useEffect(() => {
    if (window?.screen?.height > 0) {
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [isBrowser() && window?.screen?.height])

  // This is for opening resource upload modal from outside the sidebar.
  useEffect(() => {
    if (sidebarState.openUploadModal) {
      setShowResourceModal(true)
      dispatch({ type: SET_OPEN_UPLOAD_MODAL, payload: false })
    }
  }, [sidebarState.openUploadModal])

  useEffect(() => {
    if (sidebarState.refetchResources) {
      //setTimeout because some resources takes time in the backend to save
      setTimeout(() => {
        fetchAllResources(true)
        dispatch({ type: SET_REFETCH_RESOURCES, payload: false })
      }, 500)
    }
  }, [sidebarState.refetchResources])

  useEffect(() => {
    if (sidebarState.currentSelectedResourcesType) {
      let refetch = searchValue ? true : false
      fetchAllResources(refetch)
      // For quizzes and mtf need to scroll to the right to see the tab headings.
      if (sidebarState.currentSelectedResourcesType === "Quizzes" || sidebarState.currentSelectedResourcesType === "Pop Quizzes") {
        scrollResourcesNavTab("right")
      } else {
        scrollResourcesNavTab("left")
      }
    }
  }, [sidebarState.currentSelectedResourcesType])

  useEffect(() => {
    if (sidebarState.currentSelectedResourcesType && docType) {
      fetchAllResources(true)
    }
  }, [docType])

  async function fetchAllResources(refetch = false, search_value = "", section = null) {
    let resource_type = section ? section : sidebarState.currentSelectedResourcesType
    if (refetch || allResources[resource_type] === undefined) {
      setAllResourcesFetched(false)
      let data_type = getDataTypeForResourceType(resource_type)
      let type = ResourcesFetchUrl[resource_type]
      let search = search_value || searchValue ? `&search=${search_value ? search_value : searchValue}` : ""
      if (sidebarState.currentNavTab !== "Turient" && type) {
        let response = await getRequest(`/${type}/?paginate_by=${PageSize.current}${search}&adminsite=true${data_type}`)
        if (response.success) {
          let all_resources = { ...allResources }
          all_resources[resource_type] = response.data.results
          setAllResources(all_resources)
          Common.SetValForID(setAllResourcesNext, resource_type, response.data.next === null ? "" : response.data.next)
          setAllResourcesFetched(true)
        }
      }
    } else {
      setAllResourcesFetched(true)
    }
  }

  async function fetchNextAllResources(next_url) {
    if (next_url) {
      setFetchingNextResources(true)
      let response = await getRequest(next_url)
      if (response.success) {
        let all_resources = [...allResources[sidebarState.currentSelectedResourcesType]]
        if (response.data.results.length > 0) {
          all_resources = [...all_resources, ...response.data.results]
          Common.SetValForID(setAllResources, sidebarState.currentSelectedResourcesType, all_resources)
        }
        Common.SetValForID(setAllResourcesNext, sidebarState.currentSelectedResourcesType, response.data.next === null ? "" : response.data.next)
        setFetchingNextResources(false)
      }
    }
  }

  const getDataTypeForResourceType = (resource_type) => {
    let data_type = ""
    if (resource_type === "Images") {
      data_type = "&file_type=0"
    } else if (resource_type === "Quizzes") {
      data_type = "&data_type=0"
    } else if (resource_type === "Polls") {
      data_type = "&data_type=1"
    } else if (resource_type === "Pop Quizzes") {
      data_type = "&data_type=2"
    } else if (resource_type === "Documents") {
      data_type = `&file_type=` + getFileTypeForDoc(docType)
    } else if (resource_type === "Checklists") {
      data_type = `&limit=${PageSize.current}`
    } else if (resource_type === "Case Studies") {
      data_type = `&limit=${PageSize.current}`
    } else if (resource_type === "MTFs") {
      data_type = `&limit=${PageSize.current}&ordering=-created_at`
    }
    return data_type
  }

  const handleResize = () => {
    let height = window?.screen?.height ?? 480
    PageSize.current = Math.ceil(height / ITEM_HEIGHT)
  }

  function handleViewAllResources() {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    navigate(ViewAllResourcesUrl[sidebarState.currentSelectedResourcesType])
  }

  function showAddContentButton() {
    let button_text = getAddResourceButtonText(sidebarState.currentSelectedResourcesType)

    return (
      <div className={`d-flex ${StylesB.upload_resource_section}`}>
        <Btn variant="none" onClick={() => handleAddContent()} className={`${StylesB.resource_upload_button} px-1`}>
          <Image src={UploadIcon} alt={`resource upload button icon`} className={`mr-1 ${StylesB.upload_resource_icon}`} />
          {button_text}
        </Btn>
        <Btn variant="none" onClick={() => handleViewAllResources()} className={`${StylesB.resource_upload_button} px-1`}>
          View All resources
          <Image src={RightArrow} alt={`view resources button icon`} className={`ml-1 ${StylesB.view_resource_icon}`} />
        </Btn>
      </div>
    )
  }

  function handleAddContent() {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    let resource_type = sidebarState.currentSelectedResourcesType
    if (resource_type === "Polls") {
      setCreateNewPoll(true)
      setShowPollModal(true)
    } else if (resource_type === "Pop Quizzes") {
      setShowPopquizModal(true)
      setCreateNewPopquiz(true)
    } else if (resource_type === "Links") {
      setShowResourceModal(true)
      setUploadFileModalSection("upload_links")
    } else if (resource_type === "Videos") {
      setShowResourceModal(true)
      setUploadFileModalSection("upload_files")
    } else if (resource_type === "Documents" || resource_type === "Images") {
      setShowResourceModal(true)
    } else {
      navigate(`/app/${ResourceNameForUrl[resource_type]}/create`)
    }
  }

  const showDocumentTypeDropdown = () => {
    return (
      <Dropdown className={`${StylesB.document_type_dropdown} breakout-groups pr-3 pb-2`}>
        <Dropdown.Toggle className={`${StylesB.document_type_toggle} border-none d-inline-flex align-items-center p-0 shadow-none`} id="dropdown-basic">
          <div className={`mr-1`}>{docType ? docType : "All Documents"}</div>
          <Image src={AngleDown} className={`${StylesB.toggle_icon} w-10px`} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${StylesB.document_type_dropdown_menu} br-9px breakout-groups`}>
          {DocumentTypes.map((doc_type) => {
            return (
              <Dropdown.Item
                key={doc_type}
                className={`${StylesB.document_type_dropdown_item}`}
                onClick={() => {
                  setDocType(doc_type)
                }}
              >
                {doc_type}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const showSeeMore = () => {
    let next_resource_url = allResourcesNext[sidebarState.currentSelectedResourcesType]
    if (next_resource_url) {
      return (
        <div className="text-center">
          {fetchingNextResources ? (
            <Loader></Loader>
          ) : (
            <div onClick={() => fetchNextAllResources(next_resource_url)} className={`${StylesC.font_12} ${StylesB.resources_see_more} pointer pb-2`}>
              See More
            </div>
          )}
        </div>
      )
    } else {
      return null
    }
  }

  function showResources(resources, next_url) {
    if (resources.length > 0) {
      let is_published_resource = ["Quizzes", "MTFs", "Polls", "Pop Quizzes", "Checklists", "Case Studies"].includes(sidebarState.currentSelectedResourcesType)

      return (
        <React.Fragment>
          {sidebarState.currentSelectedResourcesType === "Documents" ? showDocumentTypeDropdown() : ""}
          {resources.map((item, index) => {
            let not_published = is_published_resource && item?.is_published === false ? true : false
            return (
              <Draggable
                key={item.id + `_resource_name_` + index}
                draggableId={item.id.toString() + `-resource` + "_" + index.toString()}
                isDragDisabled={true}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    className={`${not_published ? StylesB.not_published_highlight : ""} ${
                      StylesB.border_on_hover
                    } pointer mb-2 d-flex align-items-center position-relative`}
                  >
                    <ResourceItem item={item} />
                  </div>
                )}
              </Draggable>
            )
          })}
          {showSeeMore()}
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  function showResourcesSection(type = "resource") {
    let all_resources = allResources[sidebarState.currentSelectedResourcesType] ? allResources[sidebarState.currentSelectedResourcesType] : []
    let button_text = getAddResourceButtonText(sidebarState.currentSelectedResourcesType)
    if (allResourcesFetched) {
      if (all_resources.length === 0) {
        let no_content_message = searchValue ? "No resources found!" : "No content has been uploaded yet!"
        return (
          <Col lg="12" md="12" sm="12" xs="12" className={`${StylesB.no_content_message_section} text-center mt-4`}>
            <p className={`${StylesB.no_content_message}`}>{no_content_message}</p>
            <p>Everything you upload can be accessed from here. Drag and drop to add it your courses.</p>
            <Btn
              className={`${StylesB.upload_button} px-4`}
              onClick={() => {
                handleAddContent()
              }}
            >
              <Image className="mr-2 w-16px h-16px mb-1" src={Upload} />
              {button_text}
            </Btn>
            {["Videos", "Blogs", "Images", "Documents"].includes(sidebarState.currentSelectedResourcesType) ? (
              <p className={`${StylesB.upload_message} pt-2`}>Upload videos. Images, Documents, blogs, etc</p>
            ) : null}
          </Col>
        )
      } else {
        return (
          <React.Fragment>
            <Droppable droppableId={type + `_` + sidebarState.currentSelectedResourcesType} type={`resourceDraggable`}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {showResources(all_resources)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </React.Fragment>
        )
      }
    } else {
      return (
        <Col lg="12" md="12" sm="12" xs="12" className="text-center py-2">
          <Loader></Loader>
        </Col>
      )
    }
  }

  const scrollResourcesNavTab = (type) => {
    let element = document.getElementById("br-session-scrollable-resources-nav-tabs")
    if (element !== null) {
      let scroll_amount = 0
      if (type === "right") {
        scroll_amount = element.scrollWidth
      }
      element.scrollTo({ top: 0, left: scroll_amount, behavior: "smooth" })
    }
  }

  const showResourceTopNav = () => {
    return ResourceNavTabs[sidebarState.currentNavTab].map((tab_name, index) => {
      return (
        <div
          key={index.toString()}
          className={`pointer ${sidebarState.currentSelectedResourcesType === tab_name ? StylesB.resource_nav_item_selected : StylesB.resource_nav_item}`}
          onClick={() => {
            if (sidebarState.currentSelectedResourcesType !== tab_name) {
              dispatch({ type: SET_SELECTED_RESOURCE_TYPE, payload: tab_name })
              setAllResourcesFetched(false)
            }
          }}
        >
          <p>{tab_name}</p>
        </div>
      )
    })
  }

  const showSidebarContent = () => {
    let SibarSubItems = {
      Home: [
        { name: "My dashboard", url: "/app/dashboard" },
        { name: "My Notifications", url: "/app/dashboard#notifications" },
        { name: "My Calendar", url: "/app/dashboard#calendar" },
        { name: "My Profile", url: "/app/dashboard#profile" },
        { name: "My Rewards", url: "/app/dashboard#rewars" },
      ],
      Classes: [
        { name: "All Classes", url: "/app/dashboard#all_classes" },
        { name: "Upcoming", url: "/app/dashboard#upcoming_classes" },
        { name: "Completed", url: "/app/dashboard#completed_classes" },
        HasCreatePermission(CLASSES) && {
          name: "Create New Class",
          url: `/app/companies/${localStorage.getItem("company_id")}/courses/new/trainings/create`,
          type: "button",
        },
        { name: "View Trainers", url: `/app/companies/${localStorage.getItem("company_id")}/trainers/list`, type: "button" },
      ],
      Courses: [
        { name: "All Courses", url: "/app/dashboard#all_courses" },
        { name: "Published", url: "/app/dashboard#published_courses" },
        { name: "Draft", url: "/app/dashboard#draft_courses" },
        HasCreatePermission(COURSES) && {
          name: "Create New Course",
          url: `/app/companies/${localStorage.getItem("company_id")}/courses/course-information`,
          type: "button",
        },
      ],
    }

    if (["Home", "Classes", "Courses"].includes(sidebarState.currentNavTab)) {
      return (
        <div className="text-center mt-5">
          {SibarSubItems[sidebarState.currentNavTab].map((item) => {
            return <SidebarContentSubItem key={item.name} link_name={item.name} link_url={item.url} type={item.type}></SidebarContentSubItem>
          })}
        </div>
      )
    } else if (["Resource", "Poll"].includes(sidebarState.currentNavTab)) {
      return (
        <React.Fragment>
          <Col lg="12" md="12" sm="12" xs="12" className="px-0 pb-5">
            <div className="p-3">
              <ResourceSearchBar
                fetchAllResources={fetchAllResources}
                setAllResourcesFetched={setAllResourcesFetched}
                setSearchValueInParent={(value) => setSearchValue(value)}
              />
            </div>
            <HorizontalScroll scrollId="resources-nav-tabs" className={`${StylesB.resource_nav_section}`}>
              {showResourceTopNav()}
            </HorizontalScroll>
            <div className={`${StylesB.resource_item_section}`}>{showResourcesSection()}</div>
          </Col>
          {showAddContentButton()}
        </React.Fragment>
      )
    } else if (["Settings"].includes(sidebarState.currentNavTab)) {
      return <SettingsMenu />
    } else if (sidebarState.currentNavTab === "Company") {
      return <TurientMenu />
    } else {
      return null
    }
  }

  // To refetch resources after user uploads and closes the modal.
  const handleUploadResourceModalClose = () => {
    if (sidebarState.currentSelectedResourcesType) {
      fetchAllResources(true)
    }
    setShowResourceModal(false)
  }

  return (
    <React.Fragment>
      {showPollModal ? (
        <ClassRoomPoll
          path="dashboard"
          create_new_poll={createNewPoll}
          reset_create_poll={() => setCreateNewPoll(false)}
          live_classroom={false}
          poll={""}
          showPollResult={false}
          show={showPollModal}
          closeModal={() => setShowPollModal(false)}
          fetchAllResources={(arg) => {
            fetchAllResources(arg)
          }}
        />
      ) : null}
      {showPopquizModal ? (
        <ClassroomPopquiz
          path="dashboard"
          create_new_popquiz={createNewPopquiz}
          reset_create_popquiz={() => setCreateNewPopquiz(false)}
          live_classroom={false}
          popquiz={""}
          showPopquizResult={false}
          show={showPopquizModal}
          closeModal={() => setShowPopquizModal(false)}
          fetchAllResources={(arg) => {
            fetchAllResources(arg)
          }}
        />
      ) : null}
      {showResourceModal ? (
        <UploadResource show={showResourceModal} closeModal={() => handleUploadResourceModalClose()} showSection={uploadFileModalSection} />
      ) : null}
      {showSidebarContent()}
    </React.Fragment>
  )
}

export default SidebarContent
