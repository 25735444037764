import Json from "../../components/utilities/json"
import ComponentMapping from "./socketComponentMapping"

/*
Descrption: This file have socket events format function.
This function will format ALL incoming values from respective Web Socket.
*/

class SocketEventFormat {
  /*
  formatMessage -> data :- In this paramter value expected in stringified.
                  WsLink :- In this parameter Websocket URL is expected.
  */
  formatMessage(data, WsLink) {
    try {
      let parseData = Json.toJson(data)
      if (parseData.hasOwnProperty("value")) {
        let parseValue = Json.toJson(parseData.value)
        let component_mapping = ComponentMapping[parseValue["event_type"]]
        if (!component_mapping) {
          throw `Error In WebSocket, Function not defined for WS Link :- ${WsLink} and data :- ${data}`
        }
        parseValue.component = component_mapping ? component_mapping : []
        return parseValue
      }
      return {}
    } catch (error) {
      throw `Error In WebSocket Data formation for data :- ${data} and WS Link :- ${WsLink}, ${error}`
    }
  }
}

const SocketEventFormatObject = new SocketEventFormat()

export default SocketEventFormatObject
