import React, { useContext, useEffect, useState } from "react"
import CustomDropdown from "../../custom/CustomDropdown"
import "react-dropdown/style.css"
import DefaultSchoolLogo from "../../../images/default_school.png"
import PlusLogo from "../../../images/svgs/plus.svg"
import { navigate } from "gatsby"
import { getRequest, putRequest } from "../../../services/rest_service"
import Json from "../../utilities/json"
import Loader from "../../custom/Loader"
import Image from "../../custom/Image"
import { SET_CURRENT_NAV_TAB } from "../../../stores/classroom_store/classroom_sidebar/classroomSidebarActions"
import { SidebarContext } from "../../../stores/sidebar_store/SidebarStore"
import { SET_COMPANY_DETAILS } from "../../../stores/sidebar_store/SidebarActions"
import { HasCreatePermission } from "../../../utils/PermissionDataAccess"
import { SCHOOL } from "../../../constants/permissionsConstant"
import LocalStorage from "../../../utils/LocalStorageAccess"
import { PERMISSION } from "../../../constants/LocalStorageConstant"
const TurientMenu = () => {
  const [sidebarState, dispatch] = useContext(SidebarContext)
  const [loader, setLoader] = useState(true)
  const [organisationSchool, setOrganisationSchool] = useState([])
  const [selectedOption, setSelectedOption] = useState({
    value: Math.random(),
    label: "Select School",
    logo: DefaultSchoolLogo,
    owner_email: "",
    progress_percentage: 0,
  })

  const handleClickOutside = () => {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
  }
  useEffect(() => {
    getOrganisationSchoolList(Json.toJson(localStorage.getItem("organisation")))
  }, [])

  const getOrganisationSchoolList = async (organisation) => {
    setLoader(true)
    let res = await getRequest(`/basic-company-data/?organisation=${organisation.id}`)
    if (res.success) {
      setOrganisationSchool(res.data.results)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  const options = organisationSchool?.map((school) => {
    if (school) {
      return {
        value: school.id,
        label: school.name,
        logo: school.avatar || DefaultSchoolLogo,
        owner_email: school.owner_email,
        progress_percentage: school?.progress_percentage,
      }
    } else {
      return { value: Math.random(), label: "Select School", logo: DefaultSchoolLogo, owner_email: "", progress_percentage: 0 }
    }
  })

  useEffect(() => {
    if (options.length && selectedOption.label == "Select School") {
      setSelectedOption(
        options.filter((option) => {
          if (option.value === parseInt(localStorage.getItem("company_id"))) {
            return option
          }
        })[0]
      )
    }
  }, [options, organisationSchool])

  const isValidURL = (string) => {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
    return res !== null
  }

  const handleSelectOption = async (option) => {
    let res = await putRequest(`/user/user-profile/update_default_school/`, { school: option.value })
    if (res.success) {
      localStorage.setItem("company_id", option.value)
      localStorage.setItem("company_name", option.label)
      LocalStorage.setData(PERMISSION, res.data.permission)
      let logo = option.logo
      if (isValidURL(logo)) {
        localStorage.setItem("company_logo", logo)
      } else {
        localStorage.removeItem("company_logo")
        logo = null
      }
      dispatch({ type: SET_COMPANY_DETAILS, payload: { name: option.label, logo: logo } })
    }
    setSelectedOption(option)
  }

  const handleAddNewSchool = () => {
    navigate(`/app/school-setup/school-profile-setup/new`)
    handleClickOutside()
  }
  return (
    <>
      {loader ? (
        <Loader class="ml-3 mr-1" />
      ) : (
        <div className="position-relative pl-12px h-100 pt-39px">
          <p className="text-left fs-18px turient-header-text-color opacity-60 poppins_font mb-1 ml-1">Your Schools</p>
          {selectedOption && <CustomDropdown options={options} defaultOption={selectedOption} onSelect={handleSelectOption} logoFlag={true} />}

          {HasCreatePermission(SCHOOL) && (
            <div className="position-absolute bottom-0 left-0 w-100 h-4rem" onClick={handleAddNewSchool}>
              <button className="h-100 d-flex flex-row justify-content-center align-items-center bg-5D687F w-100 border-0 text-center turient-header-text-color fs-18px poppins_font ">
                <Image src={PlusLogo} alt="add school logo" className="h-1rem" />
                <p className="mb-0 ml-2">Add a new school</p>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
export default TurientMenu
