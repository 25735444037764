import React from "react"
import Modal from "react-bootstrap/Modal"
import Style from "./style.module.scss"
import Btn from "../Button"

import TrashCan from "../../../images/svgs/trash-can-transparent.svg"
import CancelWhite from "../../../images/svgs/close.svg"

// !definition of component
/**
 *
 * @param props --> show, itemName , onHide and action function
 * @description -->  show=> boolean value to show or hide the delete modal, itemName => name of item to be deleted
 * @returns Delete Modal
 */
// ! component

const DeleteModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} centered className={`${Style.modal}`} contentClassName={`${Style.content}`}>
      <div className={`${Style.header}`}>
        <img onClick={props.onHide} src={CancelWhite} alt={`Cancel`} className={`${Style.close_icon}`} />
        <div>
          <img src={TrashCan} alt={`delete icon`} className={`my-auto mr-2 ${Style.trashcan}`} />
        </div>
        <div>
          <p className="mb-0">Delete</p>
        </div>
      </div>
      <Modal.Body className={Style.body}>
        <p>
          {" "}
          Are you sure you want to delete <span className={`font-weight-bold`}>{props?.itemName}</span> ?{" "}
        </p>
        <div className="d-flex justify-content-center">
          <Btn type="small" className={`px-4 mr-3 ${Style.btn}`} onClick={props?.action}>
            Yes
          </Btn>
          <Btn type="small" className={`px-4 ${Style.btn}`} onClick={props?.onHide}>
            No
          </Btn>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteModal
