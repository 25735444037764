import React, { useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { getResourceIcon } from "./utils"
import { getResourceDisplayName } from "../utilities/resourceDisplayName"
import { SidebarContext } from "../../stores/sidebar_store/SidebarStore"
import { SET_CURRENT_NAV_TAB } from "../../stores/sidebar_store/SidebarActions"
import { ResourceNameForUrl, PublishResourcesUrl } from "./constants"
import Image from "../custom/Image"

import StylesB from "../template-resources/classroom-resource-section.module.scss"

/**
 * Component for a single resource in sidebar content.
 * @param {object} props
 * @param {object} props.item - The resource to display
 * @param {string} props.item.name - Resource file name
 * @param {string} props.item.display_name - Resource display name
 * @param {object} props.item.resource_data - Resource details
 * @param {string} props.item.is_published - Whether this resource published or not
 * @returns {React.ReactElement} ResourceItem component.
 */
const ResourceItem = (props) => {
  const [sidebarState, dispatch] = useContext(SidebarContext)

  function gotoSingleParticularSection(id, currentSec) {
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    if (currentSec === "Polls" || currentSec === "Pop Quizzes") {
      navigate(`/app/${ResourceNameForUrl[currentSec]}/${id}/edit/`)
    } else {
      navigate(`/app/${ResourceNameForUrl[currentSec]}/edit/${id}/`)
    }
  }

  function gotoPublishResource(e, id) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    dispatch({ type: SET_CURRENT_NAV_TAB, payload: "" })
    let publishUrl = PublishResourcesUrl[sidebarState.currentSelectedResourcesType]
    publishUrl = publishUrl.replace("{id}", id)
    navigate(publishUrl)
  }

  const showTooltip = (character_length) => {
    let resource_name = getResourceDisplayName(props.item)
    return resource_name?.length > character_length
  }

  let imgObj = getResourceIcon(sidebarState.currentSelectedResourcesType, props.item)
  let is_published_resource = ["Quizzes", "MTFs", "Polls", "Pop Quizzes", "Checklists", "Case Studies"].includes(sidebarState.currentSelectedResourcesType)
  let width = is_published_resource && props.item?.is_published === false ? `w-63` : `w-100`
  let displayName = getResourceDisplayName(props.item)

  return (
    <div
      onClick={() => {
        gotoSingleParticularSection(props.item.id, sidebarState.currentSelectedResourcesType)
      }}
      className={`w-100 d-flex p-2`}
    >
      <div className={`d-flex align-content-center justify-content-start mr-auto ${width}`}>
        <Image
          src={imgObj}
          alt={`${sidebarState.currentSelectedResourcesType}_${displayName}_${props.item.id}`}
          className={`${StylesB.resource_avatar} mb-0`}
        />
        {showTooltip(width === `w-100` ? 35 : 12) ? (
          <OverlayTrigger
            placement={"top"}
            overlay={
              <Tooltip className="event-tooltip z-10000" id={`tooltip-${displayName}`}>
                {displayName}
              </Tooltip>
            }
          >
            <p className={`text-truncate px-2 fs-12px pt-1 m-0 font-weight-bold`}>{displayName}</p>
          </OverlayTrigger>
        ) : (
          <p className={`text-truncate px-2 fs-12px pt-1 m-0 font-weight-bold`}>{displayName}</p>
        )}
      </div>
      <div className={`d-flex align-content-center pt-1 mr-1 justify-content-end`}>
        {props.item?.is_published === false && (
          <div className={`fs-10px ${StylesB.not_published}`} onClick={(e) => gotoPublishResource(e, props.item.id)}>
            Publish Changes
          </div>
        )}
      </div>
    </div>
  )
}

ResourceItem.propTypes = {
  //Data of a single resource
  item: PropTypes.shape({
    display_name: PropTypes.string,
    name: PropTypes.string,
    resource_data: PropTypes.object,
    is_published: PropTypes.bool,
  }).isRequired,
}

export default ResourceItem
