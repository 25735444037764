import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"

import Style from "./styles.module.scss"
import NotificationList from "./list"

const NotificationModal = (props) => {
  return (
    <Modal
      size={"lg"}
      show={props.show}
      onHide={() => {
        props.hide()
      }}
      centered={true}
      dialogClassName={`modal-dialog-centered modal-wh ${Style.disp_modal}`}
    >
      <Modal.Body className={`modal-content px-2 py-2 bg-12182c ${Style.modal_body}`}>
        <NotificationList notficationType={props.notficationType} show={props.show} />
      </Modal.Body>
    </Modal>
  )
}
NotificationModal.propTypes = {
  show: PropTypes.bool.isRequired, //controlling whether to show this component or not
  hide: PropTypes.func.isRequired, //function that takes no arguments to hide this component
}

export default NotificationModal
