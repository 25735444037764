import { navigate } from "gatsby"
import { postRequest } from "../../services/rest_service"
import config, { setMuralData } from "./config"

const refreshToken = async (mural_auth_id) => {
  let payload = {
    company: localStorage.getItem("company_id"),
    mural_auth_id: mural_auth_id,
  }
  try {
    let response = await postRequest(`/murals/refresh_access_token/`, payload)
    if (response.success) {
      setMuralData(response.data.access_token, response.data.expires_at)
      return response.data
    } else {
      window.location.href = `/app/mural?redirect_url=${window.location.href}`
    }
  } catch (error) {
    console.error(error)
  }
}

export default refreshToken
