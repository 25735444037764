// This file contains the reducer for the AssessmentMuralReducer.

import {
  SET_SHOW_MURAL_CANVAS,
  SET_DRAGGABLE_HASH,
  SET_SCREEN_SIZE_BUTTONS,
  SET_CANVAS_LINK,
  SET_WINDOW_STATE,
  SET_SHOW_MURAL_AUTH_WARNING,
  SET_SHOW_LOADER,
  SET_PARTICIPANT_ID,
  SET_MURAL_ASSESSMENT_ID,
  SET_MURAL_ASSESSMENT,
  SET_MURAL_CHAT_WINDOW_EXPAND,
  SET_NEW_MESSAGE_FLAG,
  SET_PARTICIPANT_DETAILS_MODAL,
  SET_ACTIVE_FILTER,
} from "./AssessmentMuralActions"

const initialState = {
  showMuralCanvas: false,
  draggableHash: {
    x: 0,
    y: 0,
    width: "92vw",
    height: "98vh",
  },
  screenSizeButtons: {
    fullscreen: false,
    fitToScreen: true,
    minimizeScreen: true,
  },
  canvasLink: null,
  windowState: null,
  showMuralAuthWarning: false,
  showLoader: true,
  activeAssessmentData: {
    participant_id: null,
    mural_assessment_id: null,
  },
  muralAssessment: null,
  muralChatWindowExpand: false,
  newMessageFlag: {},
  showParticipantDetailsModal: false,
  activeFilter: "profile",
}

const AssessmentMuralReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_MURAL_CANVAS:
      return {
        ...state,
        showMuralCanvas: action.payload,
      }
    case SET_MURAL_ASSESSMENT:
      return {
        ...state,
        muralAssessment: action.payload,
      }
    case SET_DRAGGABLE_HASH:
      return {
        ...state,
        draggableHash: action.payload,
      }
    case SET_SCREEN_SIZE_BUTTONS:
      return {
        ...state,
        screenSizeButtons: action.payload,
      }
    case SET_CANVAS_LINK:
      return {
        ...state,
        canvasLink: action.payload,
      }
    case SET_WINDOW_STATE:
      return {
        ...state,
        windowState: action.payload,
      }
    case SET_SHOW_MURAL_AUTH_WARNING:
      return {
        ...state,
        showMuralAuthWarning: action.payload,
      }
    case SET_SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      }
    case SET_PARTICIPANT_ID:
      return {
        ...state,
        activeAssessmentData: { ...state.activeAssessmentData, participant_id: action.payload },
      }
    case SET_MURAL_ASSESSMENT_ID:
      return {
        ...state,
        activeAssessmentData: { ...state.activeAssessmentData, mural_assessment_id: action.payload },
      }
    case SET_NEW_MESSAGE_FLAG:
      return {
        ...state,
        newMessageFlag: action.payload,
      }
    case SET_MURAL_CHAT_WINDOW_EXPAND:
      return {
        ...state,
        muralChatWindowExpand: action.payload,
      }
    case SET_PARTICIPANT_DETAILS_MODAL:
      return {
        ...state,
        showParticipantDetailsModal: action.payload,
      }
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.payload,
      }
    default:
      return state
  }
}

export default AssessmentMuralReducer
