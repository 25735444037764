class Common {
  SetValForID(func, id, val) {
    func((prev) => {
      let v = { ...prev }
      v[id] = val
      return v
    })
  }
  isValidURL(url) {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator
    return !!pattern.test(url)
  }
}
let common = new Common()

export function copyToClipboard(e, text, callback = null) {
  e.stopPropagation()
  if ("clipboard" in navigator) {
    navigator.clipboard.writeText(text)
  } else {
    document.execCommand("copy", true, text)
  }
  if (callback) {
    callback()
  }
}

export default common

export function puralWord(count, text, show = false) {
  if (!show) {
    if (count === 0) {
      return
    }
  }
  let result = count > 1 ? `${count} ${text}s` : `${count} ${text}`
  return result
}
